import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import CoursesComponent from "../components/CoursesComponent";
import Coursecontentandquestion from "../components/CoursesComponents/Coursecontentandquestion";
import CreateNewCourseComponent from "../components/CoursesComponents/CreateNewCourseComponent";
import { CourseType, CreateCourseType, UserType } from "../@types/user";
import { getCoursebyId, getCoursesList } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ReactPaginate from "react-paginate";
import HashLoader from "react-spinners/HashLoader";

interface response {
  courses: CourseType[];
  totalSessions: number;
}

const Courses = () => {
  const [indexes, setindexes] = useState(0);
  const [searchkey, setsearchkey] = useState("");
  const [coursesdata, setcoursesdata] = useState<CourseType[]>([]);
  const [totalSessions, setTotalSessions] = useState(10);
  const [selectedCourseId, setselectedCourseId] = useState("");

  const [currentPage, setCurrentpage] = useState(0);
  const itemsPerPage = 10;
  const totalItems = Number(totalSessions || 10);
  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };

  // this is for edit course, based on the selectedCourseid we fetch data and for create new course we get undefined
  const [selectedCourse, setselectedCourse] = useState<CourseType>();

  const [loading, setLoading] = useState(false);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  useEffect(() => {
    // console.log("clicked");
    if (usr && selectedCourseId !== "") {
      const usr2: UserType = usr;
      const fetchData = async () => {
        setLoading(true);
        try {
          const res = (await getCoursebyId(
            usr2!.token,
            selectedCourseId
          )) as CourseType;
          setselectedCourse(res);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      setselectedCourse(undefined);
    }
  }, [selectedCourseId, usr]);

  useEffect(() => {
    if (usr && searchkey.length === 0) {
      try {
        const usr2: UserType = usr;
        const fetchData = async () => {
          setLoading(true);
          const response = (await getCoursesList(
            usr2!.token,
            itemsPerPage,
            currentPage + 1
          )) as response;
          setTotalSessions(response.totalSessions);
          setcoursesdata(response.courses as CourseType[]);
          setLoading(false);
        };
        fetchData();
      } catch (error) {
        console.log(error);
      }
    }
  }, [searchkey.length, usr, currentPage, totalSessions]);

  return (
    <div className="overflow-scrol">
      <DashboardWrapper>
        <Titlebar title={"Courses"} />
        {loading ? (
          <div className="w-[100] h-[80vh] flex justify-center items-center">
            <HashLoader />
          </div>
        ) : (
          <>
            {indexes === 0 && (
              <div className="w-[95%] m-auto h-[100%] px-3 lg:px-10 md:px-6 rounded-2xl shadow-md bg-white">
                <CoursesComponent
                  searchkey={searchkey}
                  setsearchkey={setsearchkey}
                  coursesdata={coursesdata}
                  setcoursesdata={setcoursesdata}
                  setindexes={setindexes}
                  setselectedCourseId={setselectedCourseId}
                  setTotalSessions={setTotalSessions}
                />
                <div className="mt-3 px-2">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={Math.ceil(totalItems / itemsPerPage)}
                    previousLabel="previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                    marginPagesDisplayed={2}
                    forcePage={currentPage}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {indexes === 1 && !loading && (
          <CreateNewCourseComponent
            selectedCourseId={selectedCourseId}
            setselectedCourseId={setselectedCourseId}
            setcoursesdata={setcoursesdata}
            setindexes={setindexes}
            setTotalSessions={setTotalSessions}
            selectedCourse={selectedCourse}
            setselectedCourse={setselectedCourse}
          />
        )}
        {indexes === 2 && selectedCourseId !== "" && (
          <Coursecontentandquestion
            totalSessions={totalSessions}
            selectedCourseId={selectedCourseId}
            setselectedCourseId={setselectedCourseId}
            setindexes={setindexes}
          />
        )}
      </DashboardWrapper>
    </div>
  );
};

export default Courses;
