import {
  Button,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import TicketDetails from "../modal/TicketDetails";
import { MessageType, SupportTicket } from "../../@types/user";
import { IoMdClose } from "react-icons/io";
import { BiSmile } from "react-icons/bi";
import EmojiPicker from "emoji-picker-react";
import { BsSendFill } from "react-icons/bs";
import {
  changeTicketStatus,
  GetTicketMessages,
  sendMessageSupportChat,
} from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CiImageOn } from "react-icons/ci";

const MobileTicketView = ({
  selectedTicket,
  setindexes,
  setSupportTickets,
}: {
  selectedTicket: SupportTicket;
  setindexes: Dispatch<SetStateAction<number>>;
  setSupportTickets: Dispatch<SetStateAction<SupportTicket[]>>;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [emojivisible, setemojivisible] = useState(false);
  const [text, settext] = useState<string>("");

  const chatEndRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [messages, setMessages] = useState<MessageType[]>([]);
  useEffect(() => {
    scrollToBottom();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  // change the status of ticket to open pending close
  const handleChangeStatus = async (status: number, ticketId: string) => {
    const statuscheck: { [key: number]: number } = {
      0: 1,
      1: 2,
      2: 1,
    };
    try {
      if (usr) {
        const res = (await changeTicketStatus(
          usr.token,
          ticketId,
          statuscheck[status]
        )) as SupportTicket;
        console.log(res);
        if (res) {
          setSupportTickets?.((prev: SupportTicket[]) =>
            prev.map((prevTicket: SupportTicket) =>
              prevTicket._id === selectedTicket?._id!
                ? { ...prevTicket, status: res.status }
                : prevTicket
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const TicketMessages = async (ticket_id: string) => {
    try {
      setMessages([]);
      if (usr && ticket_id) {
        const res = (await GetTicketMessages(
          usr.token,
          1,
          15,
          ticket_id
        )) as MessageType[];
        const sortedMsgs = res.sort((b, a) => {
          return (
            new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
          );
        });
        setMessages(sortedMsgs);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedTicket?._id !== undefined) {
      TicketMessages(selectedTicket._id);
    }
  }, [selectedTicket]);

  const handleSendMsg = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && selectedTicket?._id !== undefined) {
        const res = (await sendMessageSupportChat(
          usr.token,
          selectedTicket._id,
          selectedTicket.user,
          text,
          undefined
        )) as MessageType;

        if (res) {
          setMessages((prev: MessageType[]) => [...prev, res]);
          settext("");
        }
      }
    } catch (error) {
      console.log(error);

      // "ticket_id, receiver_userid and chat_text are required"
    }
  };

  return (
    <div className="px-1">
      <div className="flex justify-between items-center">
        <Button
          onClick={() => setindexes(0)}
          color={"#3F1B5B"}
          size={"16"}
          // _hover={{ bgColor: "#3F1B5f" }}
          bg={"transparent"}
        >
          <IoArrowBackCircle color="#3F1B5B" size={"24"} />
          Back
        </Button>
        {/* <p className="font-bold text-lg">Support Chat</p> */}
        <div>
          <Popover placement="bottom-start">
            <PopoverTrigger>
              <img
                className="cursor-pointer"
                src={"/dashboardicons/threedots.png"}
                alt=""
              />
            </PopoverTrigger>
            <PopoverContent width={36}>
              <ul>
                <li onClick={onOpen} className="cursor-pointer my-3">
                  Ticket Details
                </li>
                <>
                  {selectedTicket.status === 0 && (
                    <li
                      onClick={() =>
                        handleChangeStatus(
                          selectedTicket.status,
                          selectedTicket?._id!
                        )
                      }
                      className="cursor-pointer"
                    >
                      Open
                    </li>
                  )}
                </>
                {selectedTicket.status === 1 && (
                  <li
                    onClick={() =>
                      handleChangeStatus(
                        selectedTicket.status,
                        selectedTicket?._id!
                      )
                    }
                    className="cursor-pointer"
                  >
                    Close
                  </li>
                )}
                {selectedTicket.status === 2 && (
                  <li
                    onClick={() =>
                      handleChangeStatus(
                        selectedTicket.status,
                        selectedTicket?._id!
                      )
                    }
                    className="cursor-pointer"
                  >
                    Reopen
                  </li>
                )}
              </ul>
            </PopoverContent>
          </Popover>
          <TicketDetails isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
      {/* chat */}
      <div className="flex flex-col justify-between min-h-[70vh] md:min-h-[65vh]">
        <div
          className="flex flex-col justify-between md:max-h-[64vh] max-h-[64vh] overflow-scroll text-sm"
          style={{ scrollbarWidth: "none" }}
        >
          <p className="w-[100px] mx-auto text-center p-1 rounded-xl mb-0 text-xs bg-[#e3e3e3]">
            Today
          </p>
          <div className="flex flex-col mt-2">
            {messages.map((msg: MessageType) =>
              msg.sender === usr?.user._id ? (
                <div className="flex justify-end mb-2">
                  <p className="bg-[#3F1B5B] p-3 text-white text-xs max-w-[50%] rounded-tl-xl rounded-bl-xl rounded-br-xl">
                    {msg.message}
                  </p>
                </div>
              ) : (
                <div className="flex">
                  <p className="bg-gray-200 max-w-[50%] p-3 text-xs rounded-tr-xl rounded-tl-xl rounded-br-xl">
                    {msg.message}
                  </p>
                </div>
              )
            )}
          </div>
          <div ref={chatEndRef} />
        </div>
        <form onSubmit={handleSendMsg}>
          <div className=" flex">
            {selectedTicket.status === 1 && (
              <>
                <Input
                  placeholder="Type Message"
                  backgroundColor={"rgba(238, 238, 238, 1)"}
                  rounded={"3xl"}
                  value={text}
                  onChange={(e) => {
                    settext(e.target.value);
                  }}
                  className="relative w-80 px-12"
                />
                <div className="absolute right-16 pt-2 cursor-pointer z-40">
                  <div className="flex">
                    {" "}
                    {emojivisible ? (
                      <span
                        onClick={() => {
                          setemojivisible(!emojivisible);
                        }}
                      >
                        <IoMdClose size={20} />
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setemojivisible(!emojivisible);
                        }}
                      >
                        <BiSmile size={20} />
                      </span>
                    )}{" "}
                    <label htmlFor="fileInput" className="">
                      <CiImageOn size={20} />
                    </label>
                    <input
                      id="fileInput"
                      // multiple
                      name="course_pic"
                      accept="image/*"
                      type="file"
                      style={{ display: "none" }}
                      // onChange={handleFileChange}
                    />
                  </div>
                </div>
              </>
            )}
            {emojivisible && (
              <div className="right-4 bottom-20 absolute z-20">
                <EmojiPicker
                  allowExpandReactions={false}
                  searchDisabled={true}
                  height={"290px"}
                  width={"250px"}
                  className="absolute right-0 bottom-0 left-0 z-10"
                  onEmojiClick={(e) => {
                    settext((text: string) => text + e.emoji);
                  }}
                />
              </div>
            )}
            <button className="bg-[#686628] ml-1 p-2 absolute right-6 flex justify-center items-center px-[10px] z-40 rounded-full">
              <BsSendFill
                type="submit"
                size={18}
                color="white"
                onClick={() => {
                  console.log("object");
                }}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobileTicketView;
