import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";

import InfoCard from "./dashBoardComponents/infoCard";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  Course,
  Data,
  User,
  UserType,
  userWithUserandProfile,
} from "../@types/user";
import { image_Base_Url } from "../config";
import { blockAndunblockUsers, deleteCourse, deleteUser } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { dashboardtoUser } from "../utils/ConvertDashboardtoUser";
import DeleteAlert from "./Alert/DeleteAlert";

interface DashboardProps {
  setindexes: Dispatch<SetStateAction<any>>;
  setselectedUser: Dispatch<SetStateAction<userWithUserandProfile | undefined>>;
  selectedCourseId: string;
  setSelectedCourseId: Dispatch<SetStateAction<any>>;
  setselectedCourse: Dispatch<SetStateAction<any>>;
  setsampledata: Dispatch<SetStateAction<Data | undefined>>;
  sampledata: Data | undefined;
  refresh?: () => void;
}

const DashboardComponent: React.FC<DashboardProps> = ({
  setindexes,
  sampledata,
  setselectedUser,
  selectedCourseId,
  setselectedCourse,
  setSelectedCourseId,
  setsampledata,
  refresh,
}) => {
  const router = useNavigate();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const {
    isOpen: isCourseDeleteAlertOpen,
    onClose: onCourseDeleteAlertClose,
    onOpen: onCourseDeleteAlertOpen,
  } = useDisclosure();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [selectUserId, setselectedUserid] = useState("");

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const [subAdmin, setSubAdmin] = useState<string[] | undefined>();
  // console.log(subAdmin);

  const usrRole = selector?.user?.user.role;
  const adminAccess = selector?.subAdmin?.accesses;

  useEffect(() => {
    if (adminAccess) {
      setSubAdmin(adminAccess);
    } else {
      const subAdminJson = localStorage.getItem("access");
      if (subAdminJson) {
        try {
          const parsedSubAdmin = JSON.parse(subAdminJson);
          setSubAdmin(parsedSubAdmin.accesses);
        } catch (error) {
          setSubAdmin(undefined);
        }
      } else {
        setSubAdmin(undefined);
      }
    }
  }, [adminAccess]);

  // console.log(sampledata);
  const handleBlockandUnBlock = async (Userid: string, block: boolean) => {
    if (usr) {
      const usr2: UserType = usr;
      try {
        await blockAndunblockUsers(usr2!.token, Userid, block);

        setsampledata((prev) => {
          if (!prev) return prev; // Handle case when prev is undefined

          const updatedUsers = prev.users.map((user) => {
            if (user._id === Userid) {
              return { ...user, account_status: block ? 2 : 1 };
            }

            return user;
          });
          return { ...prev, users: updatedUsers };
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleUserDelete = async (user_id: string) => {
    try {
      if (usr && user_id) {
        const res = await deleteUser(usr.token, user_id);
        if (res) {
          refresh?.();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const confirmUserdelete = () => {
    handleUserDelete(selectUserId);
    onDeleteAlertClose();
  };

  const handleCourseDelete = async (courseid: string) => {
    try {
      if (usr) {
        const res = await deleteCourse(usr.token, courseid);
        if (res) {
          refresh?.();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmCourseDelete = () => {
    handleCourseDelete(selectedCourseId);
    onCourseDeleteAlertClose();
  };

  return (
    <div className="mt-3">
      {/* info cars 4 cards */}
      <div className="dashboardinfoCards">
        <InfoCard
          icon="/dashboardicons/user.png"
          count={sampledata?.totalUser}
          label="Total User"
        />
        <InfoCard
          icon="/dashboardicons/courseregister.png"
          count={sampledata?.totalCourseRegistration}
          label="Total Course Registration"
        />
        <InfoCard
          icon="/dashboardicons/coursepurchase.png"
          count={sampledata?.totalCoursePurchased}
          label="Courses Completed"
        />
        <InfoCard
          icon="/dashboardicons/Certificate.png"
          count={sampledata?.totalCertificate}
          label="Total Certificates"
        />
      </div>
      {/*users- table */}
      {usrRole === 1 || (usrRole === 3 && subAdmin?.includes("user")) ? (
        <div className="w-[97%] mx-auto mt-7 px-2 lg:px-10 md:px-4 rounded-2xl shadow-md bg-white">
          <div className="px-4">
            <p className="pt-[28px] pb-0 mb-0 text-left text-black flex items-center justify-between">
              <p className="lg:text-[16px] md:text-[16px] text-[13px]  font-bold">
                User List
              </p>
              <p
                onClick={() => router("/user")}
                className="lg:text-[16px] cursor-pointer md:text-[16px] text-[13px] font-bold"
              >
                View All
              </p>
            </p>
          </div>
          <div
            className="overflow-y-auto hide-scrollbar"
            style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
          >
            <TableContainer
              fontFamily={"Montserrat"}
              className=" px-3 dashboard_sertable"
            >
              <Table
                size="sm"
                variant={"simple"}
                background={"white"}
                borderRadius={"25px"}
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Name
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Email
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Status
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Created Date
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody className="text-xs ">
                  {sampledata !== undefined &&
                    sampledata?.users?.map((user: User, index: number) => (
                      <>
                        <Tr className="disabled">
                          <Td className="">
                            <p className="flex mb-0 w-[150px] items-center">
                              <img
                                className="w-[56px] h-[56px] rounded-md"
                                src={
                                  user.user_profile.profile_pic !== null
                                    ? `${image_Base_Url}${user.user_profile.profile_pic}`
                                    : "/dashboardicons/userIcon.png"
                                }
                                alt=""
                              />
                              <p className="pl-2 pt-2 text-wrap text-xs md:text-sm lg:text-sm">
                                {user?.user_profile?.name}
                              </p>
                            </p>
                          </Td>

                          <Td>
                            <p className="max-w-[190px] pl-1 pt-2 text-wrap text-xs md:text-sm lg:text-sm">
                              {user?.email}
                            </p>
                          </Td>

                          <Td>
                            <p className="pt-2 text-xs md:text-sm lg:text-sm">
                              {user.account_status === 1
                                ? " Active"
                                : "No-Active"}
                            </p>
                          </Td>
                          <Td>
                            <p className="pt-2 text-xs md:text-sm lg:text-sm">
                              {formatDate(user.createdAt)}
                            </p>
                          </Td>
                          <Td>
                            <div className="flex m-auto items-start gap-1 min-w-[100px]">
                              <Tooltip label="View" placement={"top"}>
                                <img
                                  onClick={() => {
                                    const transformedUser = dashboardtoUser(
                                      user
                                    ) as userWithUserandProfile;
                                    setselectedUser(transformedUser);
                                    setindexes(1);
                                  }}
                                  src="/dashboardicons/actionicon1.png"
                                  className="cursor-pointer h-[28px] w-[28px]"
                                  alt=""
                                />
                              </Tooltip>

                              {user.account_status !== 1 ? (
                                <Tooltip label="UnBlock" placement={"top"}>
                                  <img
                                    onClick={() => {
                                      handleBlockandUnBlock(user._id, false);
                                    }}
                                    src="/dashboardicons/actionlockedicon.png"
                                    className="cursor-pointer h-[28px] w-[28px]"
                                    alt=""
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip label="Block" placement={"top"}>
                                  <img
                                    onClick={() => {
                                      handleBlockandUnBlock(user._id, true);
                                    }}
                                    src="/dashboardicons/actionunlockedicon.png"
                                    className="cursor-pointer h-[28px] w-[28px]"
                                    alt=""
                                  />
                                </Tooltip>
                              )}

                              <Tooltip label="Delete" placement={"top"}>
                                <img
                                  onClick={() => {
                                    setselectedUserid(user._id);
                                    onDeleteAlertOpen();
                                  }}
                                  src="/dashboardicons/deleteicon.png"
                                  alt=""
                                  className="cursor-pointer h-[28px] w-[28px]"
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      </>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
            <DeleteAlert
              isOpen={isDeleteAlertOpen}
              onClose={onDeleteAlertClose}
              onConfirmDelete={confirmUserdelete}
              label={"Delete User"}
            />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* dashboard Allcourses */}
      {usrRole === 1 || (usrRole === 3 && subAdmin?.includes("course")) ? (
        <div className="w-[97%] mx-auto mt-7 px-2 lg:px-10 md:px-4 rounded-2xl shadow-md bg-white">
          <div className="px-4">
            <p className="pt-[28px] text-left mb-0 pb-0 text-black flex items-center justify-between">
              <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
                All Courses
              </p>
              <p
                onClick={() => router("/course")}
                className="lg:text-[16px] cursor-pointer md:text-[16px] text-[13px] font-bold"
              >
                View All
              </p>
            </p>
          </div>
          <div
            className="overflow-y-auto hide-scrollbar pb-3 mt-3"
            style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
          >
            <TableContainer
              fontFamily={"Montserrat"}
              className=" px-3 pb-3 dashboard_usertable"
            >
              <Table
                size="sm"
                variant={"simple"}
                background={"white"}
                borderRadius={"25px"}
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Course Title
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Category
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Duration
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Author Name
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Paid Or Free
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Created Date
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      {" "}
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {sampledata !== undefined &&
                    sampledata?.courses.map((item: Course, index: number) => (
                      <Tr>
                        <Td className="flex items-center h-[100%]">
                          <img src={item.course_pic} alt="" />
                          <p className="w-[120px] pl-3 pt-2 text-wrap text-xs md:text-sm lg:text-sm">
                            {item.course_title}
                          </p>
                        </Td>
                        <Td>
                          <p className="max-w-[120px] mb-0 text-wrap text-xs md:text-sm lg:text-sm">
                            {item.course_category}
                          </p>
                        </Td>
                        <Td>
                          <p className="max-w-[120px] pl-3 pt-2 text-wrap text-xs md:text-sm lg:text-sm">
                            {item.total_duration}
                          </p>
                        </Td>
                        <Td>
                          <p className=" text-xs md:text-sm lg:text-sm">
                            {item?.course_instructor?.name}
                          </p>
                        </Td>
                        <Td>
                          <p className=" text-xs md:text-sm lg:text-sm">
                            {item?.paid_course ? "Paid" : "Free"}
                          </p>
                        </Td>
                        <Td>
                          <p className=" text-xs md:text-sm lg:text-sm">
                            {formatDate(item.createdAt)}
                          </p>
                        </Td>
                        <Td>
                          <div className="flex m-auto items-start gap-1 min-w-[100px]">
                            <Tooltip label="Edit" placement={"top"}>
                              <img
                                onClick={() => {
                                  setSelectedCourseId(item._id);
                                  setselectedCourse(item);
                                  setindexes(2);
                                }}
                                src="/dashboardicons/editicon.png"
                                className="cursor-pointer h-[28px] w-[28px]"
                                alt=""
                              />
                            </Tooltip>
                            <Tooltip label="Delete" placement={"top"}>
                              <img
                                onClick={() => {
                                  setSelectedCourseId(item._id);
                                  onCourseDeleteAlertOpen();
                                }}
                                src="/dashboardicons/deleteicon.png"
                                alt=""
                                className="cursor-pointer h-[28px] w-[28px]"
                              />
                            </Tooltip>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        ""
      )}

      <DeleteAlert
        isOpen={isCourseDeleteAlertOpen}
        onClose={onCourseDeleteAlertClose}
        onConfirmDelete={confirmCourseDelete}
        label={"Delete Course"}
      />
    </div>
  );
};

export default DashboardComponent;
