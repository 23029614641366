import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { saveNotificationToken } from "../Api";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyDyxNnWYV8plKJg9d2rAHktG0VOfxsY3yw",
  authDomain: "flourish-9846f.firebaseapp.com",
  projectId: "flourish-9846f",
  storageBucket: "flourish-9846f.appspot.com",
  messagingSenderId: "370251750839",
  appId: "1:370251750839:web:79ea1cf83e743e2c969c32",
  measurementId: "G-H55D97LL3H",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const requestForToken = (token: string) => {
  return getToken(messaging, {
    vapidKey:
      "BKxPWcBvJDPY9MfxTITfEVkQMZnGHaRwIiDlo5CKL5YKEJ6pFCLUOr210W_IAPK6bCeUWRnJBHZQ3ICkzvsqwYs",
  })
    .then(async (currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        const res = await saveNotificationToken(token, currentToken);
        // console.log(res);
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      return err;
    });
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });
export const getNotificationPermissionStatus = () => {
  return Notification.permission;
};

export const blockNotification = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "denied") {
      console.log("permission denied.");
    } else if (permission === "default") {
      console.log("permission not granted.");
    }
  });
};

export const allowNotification = () => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    } else {
      console.log("Notification permission denied.");
    }
  });
};
