import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import User from "./pages/User";
import Courses from "./pages/Courses";
import Instructor from "./pages/Instructor";
import Subscription from "./pages/Subscription";
import Resources from "./pages/Resources";
import Annoucement from "./pages/Annoucement";
import Events from "./pages/Events";
import Advertisers from "./pages/Advertisers";
import Supportchart from "./pages/Supportchat";
import PushNotification from "./pages/PushNotification";
import AllfeedBack from "./pages/AllfeedBack";
import AdminProfile from "./pages/AdminProfile";
import Userpermission from "./pages/Userpermission";
import Communitydetail from "./pages/Communitydetail";
import NotificationWrapper from "./context/NotificationWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

const routeComponents: { [key: string]: JSX.Element } = {
  "/dashboard": <Dashboard />,
  "/user": <User />,
  "/course": <Courses />,
  "/instructor": <Instructor />,
  "/subscription": <Subscription />,
  "/resources": <Resources />,
  "/announcement": <Annoucement />,
  "/event": <Events />,
  "/advertiser": <Advertisers />,
  "/support_chat": <Supportchart />,
  "/push_notification": <PushNotification />,
  "/feedback": <AllfeedBack />,
  "/admin": <AdminProfile />,
  "/userpermissons": <Userpermission />,
  "/communitydetail": <Communitydetail />,
};

const getRoutesForRole = (role?: number, allowedRoutes?: string[]) => {
  if (role === 1) {
    return Object.keys(routeComponents).map((path) => (
      <Route key={path} path={path} element={routeComponents[path]} />
    ));
  } else {
    return allowedRoutes?.map((option) => {
      const formattedOption = `/${option.replace(/\s+/g, "")}`;
      return (
        <Route
          key={formattedOption}
          path={formattedOption}
          element={routeComponents[formattedOption]}
        />
      );
    });
  }
};

const App = () => {
  const [subAdmin, setSubAdmin] = useState<string[] | undefined>();
  const selector = useSelector((state: RootState) => state.user);

  const usrRole = selector?.user?.user.role;
  const adminAccess = selector?.subAdmin?.accesses;

  useEffect(() => {
    if (adminAccess) {
      setSubAdmin(adminAccess);
    } else {
      const subAdminJson = localStorage.getItem("access");
      if (subAdminJson) {
        try {
          const parsedSubAdmin = JSON.parse(subAdminJson);
          setSubAdmin(parsedSubAdmin.accesses);
        } catch (error) {
          setSubAdmin(undefined);
        }
      } else {
        setSubAdmin(undefined);
      }
    }
  }, [adminAccess]);

  return (
    <>
      <NotificationWrapper />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        {getRoutesForRole(usrRole, subAdmin)}
      </Routes>
    </>
  );
};

export default App;
