import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import AnnouncementComponent from "../components/AnnouncementComponent";
import CreateAnnouncements from "../components/Annonucements/CreateAnnouncements";
import { announcementTypes } from "../@types/user";
import { getListOfAnnouncements } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const Annoucement = () => {
  const [loading, setloading] = useState(false);
  const [indexes, setindexes] = useState(0);
  const [data, setdata] = useState<announcementTypes[]>([]);
  const [searchkey, setsearchkey] = useState("");

  const [selectedAnnouncement, setSelectedAnnouncement] =
    useState<announcementTypes>();
  const [selectedindex, setselectedindex] = useState(0);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const fetchData = async (token: string) => {
    try {
      setloading(true);
      const annoucementsList = await getListOfAnnouncements(token, 1, 10);
      const announcementsArray = annoucementsList as announcementTypes[];
      const sortedannouncements = announcementsArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setdata(sortedannouncements as announcementTypes[]);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  // Inside useEffect
  useEffect(() => {
    if (usr && searchkey.length === 0) {
      fetchData(usr.token);
    }
  }, [searchkey.length, usr]);

  return (
    <div>
      <DashboardWrapper>
        <Titlebar title={"Announcement"} />
        <>
          {indexes === 0 && (
            <AnnouncementComponent
              searchkey={searchkey}
              setsearchkey={setsearchkey}
              data={data}
              setindexes={setindexes}
              setdata={setdata}
              setselectedindex={setselectedindex}
              setSelectedAnnouncement={setSelectedAnnouncement}
              loading={loading}
            />
          )}
        </>

        {indexes === 1 && (
          <CreateAnnouncements
            setdata={setdata}
            setindexes={setindexes}
            selectedindex={selectedindex}
            selectedAnnouncement={selectedAnnouncement}
            setSelectedAnnouncement={setSelectedAnnouncement}
          />
        )}
      </DashboardWrapper>
    </div>
  );
};

export default Annoucement;
