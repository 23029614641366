import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ResourceDocType, UserType } from "../../@types/user";
import { addNewDocument, editDocument } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";

const NewPdfModal = ({
  selectedDoc,
  setSelectedDoc,
  setDocdata,
  isOpen,
  onClose,
}: {
  selectedDoc: ResourceDocType | undefined;
  setSelectedDoc: React.Dispatch<
    React.SetStateAction<ResourceDocType | undefined>
  >;
  setDocdata: any;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [newData, setnewData] = useState<ResourceDocType>({
    title: "",
    description: "",
    doc: null,
  });

  useEffect(() => {
    if (selectedDoc && selectedDoc._id !== undefined) {
      setnewData({
        title: selectedDoc.title,
        description: selectedDoc.description,
        doc: selectedDoc.doc,
      });
    } else {
      setnewData({
        title: "",
        description: "",
        doc: null,
      });
    }
  }, [selectedDoc]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setnewData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if(!validTypes.includes(file.type)){
        return toast.error("Select valid file")
      }
      setnewData((prev) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };

  const handleSubmit = async () => {
    if (
      newData.doc === null ||
      newData.title.trim().length === 0 ||
      newData.description.trim().length === 0
    ) {
      return toast.error("Fill all Fields");
    } else {
      if (usr) {
        const usr2: UserType = usr;
        try {
          const res = await addNewDocument(usr2!.token, newData);
          setDocdata((prev: ResourceDocType[]) => [res, ...prev]);
        } catch (error) {
          console.error("Error adding new instructor:", error);
        }
      }
      onClose();
    }
  };

  // for hanlde update pdf

  const handleUpdate = async () => {
    if (
      newData.doc === null ||
      newData.title.trim().length === 0 ||
      newData.description.trim().length === 0
    ) {
      toast.error("Fill all Fields");
    }
    if (usr) {
      const usr2: UserType = usr;
      try {
        const res = await editDocument(usr2!.token, newData, selectedDoc?._id!);
        setDocdata((prev: ResourceDocType[]) =>
          prev.map((item) => (item._id === selectedDoc?._id! ? res : item))
        );
      } catch (error) {
        console.error("Error adding new instructor:", error);
      }
    }
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedDoc(undefined);
    }
  }, [isOpen, setSelectedDoc]);
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedDoc?._id !== undefined ? "Update " : "Create New "} PDF{" "}
        </ModalHeader>
        <ModalCloseButton />
        {/* addinstructor make the label and input styled */}
        <ModalBody className="addinstructor">
          <div className="flex justify-between items-center">
            <label className="text-sm font-semibold">Upload Pdf</label>
            <Button
              bgColor={"#3F1B5B"}
              color={"white"}
              _hover={{ bgColor: "#3F1B5m" }}
              className=" flex justify-center text-sm items-center text-[12px]"
              borderRadius={"20px"}
            >
              <img src={"/dashboardicons/plus.png"} className="mr-2" alt="" />
              <label htmlFor="docInput" className="text-sm mt-0 font-semibold">
                {newData.doc !== null ? (
                  <p className="mb-0 text-sm">Change PDF</p>
                ) : (
                  <p className="mb-0 text-sm">Select PDF</p>
                )}
              </label>
            </Button>
            <input
              id="docInput"
              name="doc"
              accept=".pdf/*,.doc, .docx, .xls, .xlsx, .ppt, .pptx"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          <div className="w-[100%] h-[10vh] mt-3 bg-[#e8d8f4] rounded-md flex flex-col justify-center items-center">
            <img
              src={"/dashboardicons/pdficon.png"}
              className="h-[24px] w-[24px]"
              alt=""
            />
            <p className="text-center mb-0">
              {newData.doc && newData.doc?.toString().slice(0, 10) + ".pdf"}
            </p>
          </div>
          <label className="mt-3">PDF Title</label>
          <Input
            value={newData.title}
            required
            onChange={onchange}
            name="title"
            placeholder="Enter here"
          />
          <label>Description</label>
          <Textarea
            required
            value={newData.description}
            name="description"
            onChange={onchange}
            placeholder="Enter here"
          />

          <div className="w-[80%] mx-auto">
            {selectedDoc?._id !== undefined ? (
              <Button
                onClick={handleUpdate}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                Upload Document
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewPdfModal;
