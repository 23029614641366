import {
  Avatar,
  AvatarGroup,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import React, { FormEvent, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import AddPeopleinNotification from "./AddPeopleinNotification";
import {
  PushNotificationType,
  UserType,
  userWithUserandProfile,
} from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  ListOfAllusers,
  createNewNotification,
  getNotificationById,
  searchUsers,
  updateNotification,
} from "../../Api";
import { image_Base_Url } from "../../config";
import toast from "react-hot-toast";
// import { toast, ToastContainer } from "react-toastify";

const Notification = ({
  setdata,
  isOpen1,
  onClose1,
  setSelectedNotification,
  selectednotification,
}: {
  setdata: React.Dispatch<React.SetStateAction<PushNotificationType[]>>;
  isOpen1: boolean;
  onClose1: () => void;
  selectednotification: PushNotificationType | undefined;
  setSelectedNotification: React.Dispatch<
    React.SetStateAction<PushNotificationType | undefined>
  >;
}) => {
  const modules = {
    toolbar: [
      [{ align: [] }],
      ["bold", "italic", "underline"],
      ["image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "image",
    "align",
    "video",
  ];
  const [newData, setNewData] = useState<PushNotificationType>({
    title: "",
    category: "",
    image: null,
    date: "",
    send_now: false,
    description: "",
    all_user: true,
    users: [],
    time: "",
  });

  const [selectedusers, setSelectedusers] = useState("2");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [imageUrl, setimageUrl] = useState<string | undefined>();
  const [page, setPage] = useState(0);
  console.log(page);
  const [loading, setLoading] = useState(false);
  // for all other inputs
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setNewData((prev) => ({ ...prev, [name]: value }));
  };
  // this is only for the quill text editor
  const hanldeChange = (editorcontent: string) => {
    setNewData((prev) => ({ ...prev, description: editorcontent }));
  };
  // for image
  const handleImagechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files !== null && e.target.files[0];

    if (files) {
      setNewData((prev) => ({
        ...prev,
        image: files,
      }));
    }
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const [listOfUsers, setlistOfUser] = useState<userWithUserandProfile[]>([]);
  // console.log(listOfUsers);
  const [searckey, setsearchKey] = useState("");

  // fetch complete notification details by id
  const completeDetailsofNotification = async (id: string) => {
    try {
      if (id !== undefined && usr) {
        const res = (await getNotificationById(
          usr.token,
          id
        )) as PushNotificationType;
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initializeForm = (notification: PushNotificationType | undefined) => {
    if (notification !== undefined) {
      setNewData({
        title: notification.title,
        category: notification.category,
        image: notification.image,
        date: notification?.date?.toString(),
        send_now: notification.send_now,
        description: notification.description,
        all_user: notification.all_user,
        users: notification.users,
        time: notification.time?.toString(),
      });

      if (notification.all_user === true) {
        setSelectedusers("2");
      } else {
        setSelectedusers("1");
      }
      setimageUrl(
        notification.image ? notification.image.toString() : undefined
      );
    } else {
      setNewData({
        title: "",
        category: "",
        image: null,
        date: "",
        description: "",
        send_now: false,
        all_user: true,
        users: [],
        time: "",
      });
      setSelectedusers("2");
    }
  };
  useEffect(() => {
    if (selectednotification) {
      (async () => {
        const data = await completeDetailsofNotification(
          selectednotification._id!
        );
        if (data !== undefined) {
          initializeForm(data);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectednotification]);

  // to fetch the user list for selecteduser

  useEffect(() => {
    if (usr && searckey.trim() === "") {
      // page,limit,token
      setLoading(true);
      try {
        const fetchData = async () => {
          const res = (await ListOfAllusers(
            page + 1,
            10,
            usr!.token
          )) as userWithUserandProfile[];
          setlistOfUser((prev) => [...prev, ...res]);
          // setlistOfUser(res as userWithUserandProfile[]);
        };
        fetchData();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, [usr, newData.all_user, searckey, setsearchKey, page]);

  // search user by name

  const fetchUsers = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (usr && searckey.trim().length > 0) {
      const users = (await searchUsers(
        usr?.token,
        searckey
      )) as userWithUserandProfile[];
      setlistOfUser(users);
    }
  };

  // this is for create newe notification
  const hanldeCreate = async () => {
    if (usr) {
      try {
        const usr2: UserType = usr;
        const res = (await createNewNotification(
          usr2!.token,
          newData
        )) as PushNotificationType;
        setdata((prev: PushNotificationType[]) => [res, ...prev]);
      } catch (error) {
        console.error("Error occurred while creating notification:", error);
      } finally {
        onClose1();
      }
    }
  };

  const handleUpdate = async () => {
    if (selectednotification?.send_now === true) {
      return toast.error("Can't be Edited,Since already Sent", {
        position: "top-center",
      });
    }
    if (usr && selectednotification?._id !== undefined) {
      try {
        const res = (await updateNotification(
          usr!.token,
          newData,
          selectednotification._id!
        )) as PushNotificationType;
        setdata((prev: PushNotificationType[]) =>
          prev.map((item) =>
            item._id === selectednotification._id ? res : item
          )
        );
      } catch (error) {
        console.error("Error occurred while creating notification:", error);
      } finally {
        onClose1();
      }
    }
  };
  useEffect(() => {
    if (!isOpen1) {
      initializeForm(undefined);
      setSelectedNotification(undefined);
      setSelectedusers("2");
      setimageUrl(undefined);
    }
  }, [isOpen1, setSelectedNotification]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen1} onClose={onClose1}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {" "}
            {selectednotification?._id !== undefined
              ? "Edit"
              : "Create New"}{" "}
            Push Notification{" "}
          </ModalHeader>
          <ModalCloseButton />
          {/* addinstructor make the label and input styled */}
          <ModalBody className="addinstructor">
            <div className="flex justify-between items-center">
              <label className="text-sm font-semibold">Upload Your Image</label>
              <Button
                // onClick={onOpen}
                borderRadius={"24px"}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className=" flex justify-center items-center"
              >
                <label htmlFor="fileInput" className="flex m-0 p-0">
                  <img
                    src={"/dashboardicons/plus.png"}
                    className="h-[16px] w-[16px] mr-3"
                    alt=""
                  />
                  <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
                    {newData.image !== null ? "Change image" : "Select image"}
                  </p>
                </label>
              </Button>

              <input
                type="file"
                accept="img/*"
                // multiple
                required
                name="video"
                style={{ display: "none" }}
                id="fileInput"
                onChange={(e) => {
                  handleImagechange(e);
                }}
              />
            </div>
            {newData.image && newData.image instanceof File ? (
              <img
                src={URL.createObjectURL(newData.image)}
                alt="Uploaded preview"
                className="w-[60px] h-[60px] rounded-md ml-2"
              />
            ) : (
              imageUrl && (
                <img
                  src={`${image_Base_Url}${imageUrl}`}
                  alt="Uploaded preview"
                  className="w-[60px] h-[60px] rounded-md ml-2"
                />
              )
            )}

            <label className="mt-3">Notification Title</label>
            <Input
              required
              placeholder="Enter here"
              name="title"
              value={newData.title}
              onChange={onchange}
            />
            <label className="mt-3">Notification Category</label>
            <Input
              required
              placeholder="Enter here"
              name="category"
              onChange={onchange}
              value={newData.category}
            />

            <ReactQuill
              theme="snow"
              style={{ border: "none", marginTop: "10px" }}
              placeholder="Enter here"
              modules={modules}
              formats={formats}
              value={newData.description}
              onChange={hanldeChange}
              className="w-[100%] mt-3 border-[white] rounded-[8px] bg-[#F4F4F4]"
            />
            <div className="px-1 flex justify-between mt-2">
              <label className="mt-3">Send Now</label>
              <Switch
                name="send_now"
                isChecked={newData.send_now}
                onChange={() => {
                  setNewData((prev: PushNotificationType) => ({
                    ...prev,
                    send_now: !prev.send_now,
                  }));
                }}
              />
            </div>
            {newData.send_now === false && (
              <>
                <label className="mt-3">Date of Publising</label>
                <Input
                  required
                  placeholder="Enter here"
                  type="date"
                  name="date"
                  onChange={onchange}
                  value={formatDate(newData.date)}
                />
                <label className="mt-3">Time of Publishing</label>
                <Input
                  required
                  placeholder="Enter here"
                  type="time"
                  name="time"
                  value={newData.time}
                  onChange={onchange}
                />
              </>
            )}

            <div className="flex justify-between px-1 mt-2">
              <label>Send Notifiactaion to </label>
              <RadioGroup
                defaultValue={selectedusers}
                onChange={(value) => {
                  setSelectedusers(value);
                  setNewData((prev) => ({ ...prev, all_user: !prev.all_user }));
                }}
              >
                <Stack direction="row">
                  <Radio value="2">All users</Radio>
                  <Radio value="1">Selected Users</Radio>
                </Stack>
              </RadioGroup>
            </div>
            {selectedusers === "1" && listOfUsers && (
              <div className="flex mt-3 justify-between px-3 items-center">
                <AvatarGroup size="sm" max={2}>
                  {listOfUsers
                    .filter((user) => newData?.users?.includes(user.user._id))
                    .map((user) => (
                      <Avatar
                        key={user.user._id}
                        name={user?.profile?.name}
                        src={user?.profile?.profile_pic || undefined}
                      />
                    ))}
                </AvatarGroup>
                <Button
                  bgColor={"#3F1B5B"}
                  color={"white"}
                  _hover={{ bgColor: "#3F1B5m" }}
                  className=" flex justify-center items-center text-[12px]"
                  borderRadius={"20px"}
                  onClick={onOpen}
                >
                  Add
                </Button>
              </div>
            )}
            <AddPeopleinNotification
              newData={newData}
              setNewData={setNewData}
              isOpen={isOpen}
              onClose={onClose}
              listOfUsers={listOfUsers}
              setsearchKey={setsearchKey}
              fetchUsers={fetchUsers}
              loading={loading}
              setPage={setPage}
            />
            <div className="w-[80%] mx-auto">
              {selectednotification?._id !== undefined ? (
                <Button
                  onClick={handleUpdate}
                  size={"sm"}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={hanldeCreate}
                  size={"sm"}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Save
                </Button>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Notification;
