import { AxiosProgressEvent } from "axios";
import {
  Address,
  AdminPassword,
  AdminProfType,
  CreateBlogType,
  CreateCourseSession,
  CreateCourseType,
  CreatedAd,
  CreatedJobType,
  Data,
  EventType,
  FaqType,
  Instructor,
  PodcastType,
  PushNotificationType,
  ResourceDocType,
  UserData,
  UserProfile,
  VideoType,
  announcementTypes,
  courseQuestionType,
  createAdvertiser,
  sessionQuestionType,
  userViewType,
  userWithUserandProfile,
} from "./@types/user";
import { Axios } from "./Axios";

// All of the APIS will be declared over here within a function that return promise.

const triggerBackgroundAuthentication = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin`, {}, { headers: { Authorization: token } })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//this api is for admin login
const adminLogin = (email: string, password: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/login`, { email, password })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

// api for dashboard data
// Data is type which has users object and counts like total users,courses ... storing the total users wchich is later used in totla count of users
const AdminDashboardData = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/dashboard`, { headers: { Authorization: token } })
      .then((result) => {
        // console.log(result.data.data);
        resolve(result.data.data as Data);
        localStorage.setItem("totalUsers", result.data.data.totalUser);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
};

// notifications for one month (which is for the bell icon)

const getNotifiactionsOfMonth = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/notifications`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.notifications);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editAdminProfile = (token: string, data: AdminProfType) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, values]) => {
      formData.append(key, values);
    });

    Axios.put(`/api/v1/admin/profile/edit`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ChangeAdminPassword = (token: string, data: AdminPassword) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/password/edit`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        // console.log(result);
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// profile info in admin profile
const getProfileInfo = (token: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/profile/get`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        console.log(result);
        resolve(result.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//this is for getting the list of users

const ListOfAllusers = (page: number, limit: number, token: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/user/list?page=${page}&limit=${limit}`,

      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(
          result.data.data.users_with_profile as userWithUserandProfile[]
        );
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// api to fetch complete details of user inclufing profile and address

const fetchCompleteDetailsofUser = (userid: string, token: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/user/view?uid=${userid}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data as userViewType);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// editing the user prolfile
const editUserProfile = (token: string, data: UserProfile) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/profile/edit`,
      {
        uid: data.uid,
        name: data.name,
        bio: data.bio,
        _id: data._id,
        profile_pic: data.profile_pic,
        your_company: data.your_company,
        date_of_birth: data.date_of_birth,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        gender_identity: data.gender_identity,
        racial_identity: data.racial_identity,
        denomination: data?.denomination,
        sexual_orientation: data.sexual_orientation,
        school: data.school,
        who_you_are: data.who_you_are,
        faith: data.faith,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        // console.log(result);
        resolve(result.data.data.updated_user as UserProfile);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// api for editing address of a user
const editUserAddress = (token: string, data: Address) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/address/edit`,
      {
        uid: data.uid,
        address: data.address,
        city: data.city,
        country: data.country,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        state: data.state,
        zip_code: data.zip_code,
        _id: data._id,
      },
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data.data.updated_address as Address);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const searchUsers = (token: string, searchKey: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`api/v1/admin/user/search?query=${searchKey}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.users);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// block and unblock users
const blockAndunblockUsers = (token: string, uid: string, block: boolean) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/user/block-unblock`,
      { uid, block },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.updatedUser);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
};

// delete user
const deleteUser = (token: string, userId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/user/delete?userId=${userId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list of instructors
const listOfInstructors = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/instructor/list?page=${page}&limit=${limit}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve({
          instructors: result.data.data.instructors,
          totalInstructors: result.data.data.totalInstructors,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add instructor api
const addNewInstructor = (token: string, data: Instructor) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object

    Object.entries(data).forEach(([key, value]) => {
      if (key === "email" && value.length <= 0) {
      } else if (key === "mono" && value.length <= 0) {
      } else {
        formData.append(key, value);
      }
    });

    Axios.post(`/api/v1/admin/add-instructor`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        // console.log(result);
        resolve(result.data.data.created_instructor);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// update the instructor
const updateInstructor = (token: string, data: Instructor, _id: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.put(`/api/v1/admin/instructor/edit/?instructorId=${_id}`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.updatedInstructor);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const searchInstructor = (token: string, searchkey: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/instructor/search?query=${searchkey}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.instructors);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteInstructor = (token: string, _id: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/instructor/delete?instructorId=${_id}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// add announcement api
const addNewAnnouncement = (token: string, newData: announcementTypes) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/announcement/add`,
      {
        title: newData?.title,
        description: newData?.description,
        date: newData?.date,
      },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.createdAnnouncement);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// for updating annoncemnts

const updateAnnouncements = (
  token: string,
  data: announcementTypes,
  _id: string
) => {
  return new Promise((resolve, reject) => {
    Axios.put(`/api/v1/admin/announcement/edit/?announcementId=${_id}`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.updatedAnnouncement);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// get list of announcements with pageumber and no of items in a page
const getListOfAnnouncements = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/announcements?page=${page}&limit=${limit}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.announcements as announcementTypes[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// list of announcements based on the search key word
const listOfAnnouncementswithSearch = (token: string, key: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/announcement/search?query=${key}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.announcements as announcementTypes[]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete announcemts
const deleteAnnouncements = (token: string, _id: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/announcement/delete/?announcementId=${_id}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add new event api
const addNewEvent = (token: string, data: EventType) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/event/add`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.createdEvent);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add Update event api
const EditEvent = (token: string, data: EventType, eventId: string) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.put(`/api/v1/admin/event/edit?eventId=${eventId}`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.updatedEvent);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get list of events with pagenumber and noof items in each page
const listOfEvents = (token: string, page: number, items: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/events?limit=${items}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve({
          events: result.data.data.events,
          totalEvents: result.data.data.totalEvents,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// search a event api
const searchEvent = (token: string, searchKey: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/events/search?query=${searchKey}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.events);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete event api
const deleteEvent = (token: string, eventId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/event/delete?eventId=${eventId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// list of attendder for eveent based on event id
const EventAttenders = (
  token: string,
  limit: number,
  page: number,
  event_id: string
) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/events/attenders`, {
      params: { limit, page, event_id },
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.eventAttenders);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list of advertisers (people )
const getListOfAdvertisers = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/ad/advertisers?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.advertisers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// craeteing new advertiser
const createNewAdvertiser = (token: string, data: createAdvertiser) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/ad/advertiser/create`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// craeteing new advertiser
const updateAdvertiser = (
  token: string,
  data: createAdvertiser,
  advertiserId: string
) => {
  return new Promise((resolve, reject) => {
    Axios.put(
      `/api/v1/admin/ad/advertiser/edit?advertiserId=${advertiserId}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data.updatedAdvertiser);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete advertiser

const deleteAdvertiser = (token: string, advertiserUserId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/ad/advertiser/delete?advertiserUserId=${advertiserUserId}`,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// search advertisers
const searchAdvertisers = (token: string, searchKey: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/ad/advertisers/search`, {
      params: { query: searchKey },
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.advertisers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get active ads of an advertisers
const getActiveAdsOfAdvertisers = (
  token: string,
  advertiser_id: string,
  page: number,
  limit: number
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/ad/advertiser/active?limit=${limit}&page=${page}&advertiser_id=${advertiser_id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data.activeAds);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getPendingAdsOfAdvertisers = (
  token: string,
  advertiser_id: string,
  page: number,
  limit: number
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/ad/advertiser/pending?limit=${limit}&page=${page}&advertiser_id=${advertiser_id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
      .then((result) => {
        resolve(result.data.data.pendingAds);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// update ad status
const updateAdStatus = (
  token: string,
  ad_id: string,
  status: string,
  reject_reason?: string
) => {
  return new Promise((resolve, reject) => {
    Axios.put(
      `/api/v1/admin/ad/status`,
      { ad_id, status, reject_reason },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.updatedAd);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// create a new ad
const createNewAd = (token: string, data: CreatedAd) => {
  console.log(data);
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/ad/create`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.createdAd);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete ads
const deleteAd = (token: string, ad_id: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/ad/delete?adId=${ad_id}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list of feedback apis
const getListOfFeedbacks = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/feedbacks?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.feedbacks);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete feedback
const deleteFeedback = (token: string, feedbackId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/feedback/delete?feedbackId=${feedbackId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list of push notifications
const getListOfPushNotifications = (
  token: string,
  page: number,
  limit: number
) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/pushnotifications?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.pushnotifications);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//apis search for push notifications
const searchPushNotification = (token: string, searchKey: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/pushnotifications/search?query=${searchKey}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.pushnotifications);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// for creating a new push notification
const createNewNotification = (token: string, data: PushNotificationType) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "users") {
        if (value.length > 0) {
          value.forEach((userId: string, index: number) => {
            formData.append(`users[${index}]`, userId);
          });
        }
      } else {
        formData.append(key, value);
      }
    });
    Axios.post(`/api/v1/admin/pushnotification/create`, formData, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.createdPushnotification);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete pushh notification
const deletePushNotification = (token: string, pushNotifId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/pushnotification/delete?pushNotifId=${pushNotifId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// update push notification
const updateNotification = (
  token: string,
  data: PushNotificationType,
  pushNotifId: string
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "users") {
        if (value.length > 0) {
          value.forEach((userId: string, index: number) => {
            formData.append(`users[${index}]`, userId);
          });
        }
      } else {
        formData.append(key, value);
      }
    });
    Axios.put(
      `/api/v1/admin/pushnotification/edit?pushNotifId=${pushNotifId}`,
      formData,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        console.log(result);
        resolve(result.data.data.updatedPushNotif);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get notification by  id
const getNotificationById = (token: string, pushNotifId: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/pushnotification/by-id?pushNotifId=${pushNotifId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.pushnotification);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// resources-> vidoes
// for adding new viodes ,vidoes can be selected more than one
const addNewVidoes = (
  token: string,
  videoData: VideoType,
  video: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void | undefined
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(videoData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("video", video);
    Axios.post(`/api/v1/admin/resource/video/add`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
      .then((result) => {
        resolve(result.data.data.createdResourceVideo);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// update the video related
const EditVidoes = (token: string, videoData: VideoType, videoId: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(videoData).forEach(([key, value]) => {
      formData.append(key, value);
    });

    Axios.put(
      `/api/v1/admin/resource/video/edit/?videoId=${videoId}`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((result) => {
        resolve(result.data.data.updatedResourceVideo);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// delete video resource

const deleteVideo = (token: string, videoId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/resource/video/delete?videoId=${videoId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get list of videos
const getListOfVideos = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/videos?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve({
          resourceVidoes: result.data.data.resourceVideos,
          totalVideos: result.data.data.totalVideos,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// add new documents
const addNewDocument = (token: string, data: ResourceDocType) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      // For other properties, append them as usual
      formData.append(key, value);
    });

    Axios.post(`/api/v1/admin/resource/doc/add`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.createdResourceDoc);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};
// update documents
const editDocument = (token: string, data: ResourceDocType, docId: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      // For other properties, append them as usual
      formData.append(key, value);
    });

    Axios.put(`/api/v1/admin/resource/doc/edit?docId=${docId}`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((result) => {
        resolve(result.data.data.updatedDoc);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

// get list of documents
const getListofDocuments = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/docs?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.resourceDocs);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// delete documents
const deleteResourceDoc = (token: string, docId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/resource/doc/delete?docId=${docId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get list of jobs
const getListofJobs = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/jobs?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.jobs);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// adding new job
const addNewJob = (token: string, data: CreatedJobType) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/resource/job/add`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data.createdJob);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const EditJob = (token: string, data: CreatedJobType, jobId: string) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.put(`/api/v1/admin/resource/job/edit?jobId=${jobId}`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data.updatedResourceJob);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete job
const deleteJob = (token: string, jobId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/resource/job/delete?jobId=${jobId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getListOfPodcasts = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/podcasts?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.resourcePodcasts);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// adding new podcast
const addNewPodcast = (token: string, data: PodcastType) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/resource/podcast/add`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data.createdResourcePodcast);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// edit podcast
const editPodcast = (token: string, data: PodcastType, podcastId: string) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.put(
      `/api/v1/admin/resource/podcast/edit?podcastId=${podcastId}`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((result) => {
        resolve(result.data.data.updatedResourcePodcast);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete podcast
const deletePodcast = (token: string, podcastId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/resource/podcast/delete?podcastId=${podcastId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const getListOfBlogs = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/blogs?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.blogs);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// add new blog
const addNewBlog = (token: string, data: CreateBlogType) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    console.log(data);
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/resource/blog/add`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data.createdBlog);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// edit blogs
const editBlog = (token: string, data: CreateBlogType, blogId: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      if (key === "image" && !(value instanceof File)) {
        return;
      }

      formData.append(key, value);
    });
    Axios.put(`/api/v1/admin/resource/blog/edit?blogId=${blogId}`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve(result.data.data.updatedResourceJob);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete blogs
const deleteBlogs = (token: string, blogId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/resource/blog/delete?blogId=${blogId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// search for all resources

const SearchResources = (token: string, type: string, search: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/resource/search?type=${type}&search=${search}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// list of courses
const getCoursesList = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/course/list?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve({
          courses: result.data.data.courses,
          totalSessions: result.data.data.totalCourse,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// search courses
const searchCourses = (token: string, title: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/course/search?query=${title}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.courses);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// adding new Course
const addNewCourse = (token: string, data: CreateCourseType) => {
  return new Promise((resolve, reject) => {
    // Create a new FormData object
    const formData = new FormData();
    // Append all the properties of the data object to the FormData object
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.post(`/api/v1/admin/course/add`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve({
          course: result.data.data.created_course,
          courseId: result.data.data.created_course._id,
          totalSessions: result.data.data.created_course.total_session,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// for updating the course
const UpdateCourse = (token: string, data: CreateCourseType, cid: string) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Axios.put(`/api/v1/admin/course/edit/${cid}`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
    })
      .then((result) => {
        resolve({
          course: result.data.data.updatedCourse,
          courseId: result.data.data.updatedCourse._id,
          totalSessions: result.data.data.updatedCourse.total_session,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete course

const deleteCourse = (token: string, courseId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/course/delete?courseId=${courseId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getCoursebyId = (token: string, parameter: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/course/id`, {
      params: { courseId: parameter },
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.course);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// adding new Course
const addNewSessiontoCourse = (
  token: string,
  data: CreateCourseSession,
  session_video: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("session_video", session_video);
    Axios.post(`/api/v1/admin/course/session/add`, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
      .then((result) => {
        resolve(result.data.data.created_course);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editSessions = (
  token: string,
  data: CreateCourseSession,
  session_video: File,
  sessionId: string,
  courseId: string,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("session_video", session_video);
    Axios.put(
      `/api/v1/admin/session/edit?sessionId=${sessionId}&courseId=${courseId}`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    )
      .then((result) => {
        // console.log(result)
        resolve(result.data.data.updatedSession);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteSession = (token: string, courseId: string, sessionId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/session/delete?sessionId=${sessionId}&courseId=${courseId}`,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addNewSessionQuestions = (token: string, data: sessionQuestionType) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/session/quiz/question/add`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.created_session_quiz_question);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateSessionQuestions = (token: string, data: sessionQuestionType) => {
  return new Promise((resolve, reject) => {
    Axios.put(`/api/v1/admin/session/quiz/question/edit`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.updatedSessionQuizQuestion);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete question in session
const deletesessionQuestion = (token: string, questionId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/session/quiz/question/delete?questionId=${questionId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getListOfQuestionsinSession = (
  token: string,
  cid: string,
  sid: string
) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/session/quiz/questions`, {
      headers: { Authorization: token },
      params: { cid, sid },
    })
      .then((result) => {
        resolve(result.data.data.sessionQuizQuestions);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// for course Questions
const getListOfCourseQuestions = (token: string, cid: string) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/course/quiz/questions?cid=${cid}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.courseQuizQuestions);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
// add question to course
const addQuestionToCourse = (token: string, data: courseQuestionType) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/course/quiz/question/add`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.created_course_quiz_question);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updateQuestionToCourse = (token: string, data: courseQuestionType) => {
  return new Promise((resolve, reject) => {
    Axios.put(`/api/v1/admin/course/quiz/question/edit`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.updatedCourseQuizQuestion);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// delete question in course
const deleteCourseQuestion = (token: string, questionId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/course/quiz/question/delete?questionId=${questionId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// get list of questions
const getFaqs = (token: string, limit: number, page: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/faq?limit=${limit}&page=${page}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.faqs);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const createFAQ = (token: string, data: FaqType) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/faq`, data, { headers: { Authorization: token } })
      .then((result) => {
        resolve(result.data.data.createdFaq);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const editFaq = (token: string, data: FaqType, faqId: string) => {
  return new Promise((resolve, reject) => {
    Axios.put(`/api/v1/admin/faq/edit?faqId=${faqId}`, data, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.updatedFaq);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const deleteFaq = (token: string, faqId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/faq/delete?faqId=${faqId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const AllSupportChatTickets = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/support/tickets?limit=${limit}&page=${page}&type=${4}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.supportTickets);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const SearchSupportTickets = (token: string, query: string, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/support/tickets/search?query=${query}&limit=${limit}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.supportTickets);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetTicketMessages = (
  token: string,
  page: number,
  limit: number,
  ticket_id: string
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/admin/support/messages?page=${page}&limit=${limit}&ticket_id=${ticket_id}`,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data.data.supportChats);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const changeTicketStatus = (
  token: string,
  ticket_id: string,
  status: number
) => {
  return new Promise((resolve, reject) => {
    Axios.patch(
      `/api/v1/admin/support/ticket/change-status`,
      { ticket_id, status },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.updatedTicket);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sendMessageSupportChat = (
  token: string,
  ticket_id: string,
  receiver_userid: string,
  chat_text: string,
  image: File | undefined
) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append("ticket_id", ticket_id);
    formData.append("receiver_userid", receiver_userid);
    formData.append("chat_text", chat_text);
    if (image !== undefined) {
      formData.append("image", image);
    }
    Axios.post(`/api/v1/admin/support/message/send`, formData, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.chat);
      })
      .then((err) => {
        reject(err);
      });
  });
};

const saveNotificationToken = (token: string, notification_token: string) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/notification/add-token`,
      { notification_token },
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data.data.userSetting);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const createNewSubAdmin = (token: string, userData: UserData) => {
  return new Promise((resolve, reject) => {
    Axios.post(`/api/v1/admin/sub-admin/create`, userData, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const EditSubAdmin = (
  token: string,
  userdata: UserData,
  sub_admin_user_id: string
) => {
  return new Promise((resolve, reject) => {
    const { email, ...userdataWithoutEmail } = userdata;
    Axios.put(
      `/api/v1/admin/sub-admin/edit`,
      { sub_admin_user_id, ...userdataWithoutEmail },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.updatedSubAdmin);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getListOfSubAdmins = (token: string, page: number, limit: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/admin/sub-admin/list?page=${page}&limit=${limit}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve({
          totalCount: result.data.data.subAdmins[0].metadata[0].totalCount,
          data: result.data.data.subAdmins[0].data,
        });
        // resolve(result.data.data.subAdmins[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const deleteSubAdmin = (token: string, subAdminUserId: string) => {
  return new Promise((resolve, reject) => {
    Axios.delete(
      `/api/v1/admin/sub-admin/delete?subAdminUserId=${subAdminUserId}`,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.type);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getDropDownList = (
  token: string,
  page: number,
  limit: number,
  type: number
) => {
  return new Promise((resolve, reject) => {
    Axios.get(
      `/api/v1/drop-down-item/list?page=${page}&limit=${limit}&type=${type}`,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        resolve(result.data.data.dropdownItems);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const searchDropDown = (token: string, query: string, type: number) => {
  return new Promise((resolve, reject) => {
    Axios.get(`/api/v1/drop-down-item/search?query=${query}&type=${type}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        resolve(result.data.data.dropdownItems);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const addDropDown = (token: string, type: number, spellings: string[]) => {
  return new Promise((resolve, reject) => {
    Axios.post(
      `/api/v1/admin/drop-down-item/create`,
      { type, spellings },
      { headers: { Authorization: token } }
    )
      .then((result) => {
        console.log(result);
        resolve(result.data.data.dropDownItems);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const deleteDropDown = (token: string, ids: string[]) => {
  return new Promise((resolve, reject) => {
    Axios.delete(`/api/v1/admin/drop-down-item/delete`, {
      headers: { Authorization: token },
      data: { ids },
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const editDropDown = (token: string, id: string, spelling: string) => {
  return new Promise((resolve, reject) => {
    Axios.put(
      `/api/v1/admin/drop-down-item/edit?id=${id}&spelling=${spelling}`,
      {},
      { headers: { Authorization: token } }
    )
      .then((result) => {
        resolve(result.data.data.updatedDropdown);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export {
  triggerBackgroundAuthentication,
  adminLogin,
  AdminDashboardData,
  getNotifiactionsOfMonth,
  ChangeAdminPassword,
  editAdminProfile,
  getProfileInfo,
  ListOfAllusers,
  fetchCompleteDetailsofUser,
  editUserProfile,
  editUserAddress,
  searchUsers,
  blockAndunblockUsers,
  deleteUser,
  listOfInstructors,
  addNewInstructor,
  updateInstructor,
  searchInstructor,
  addNewAnnouncement,
  getListOfAnnouncements,
  updateAnnouncements,
  listOfAnnouncementswithSearch,
  deleteAnnouncements,
  addNewEvent,
  EditEvent,
  listOfEvents,
  searchEvent,
  deleteEvent,
  EventAttenders,
  getListOfAdvertisers,
  createNewAdvertiser,
  updateAdvertiser,
  deleteAdvertiser,
  searchAdvertisers,
  deleteInstructor,
  getActiveAdsOfAdvertisers,
  getPendingAdsOfAdvertisers,
  updateAdStatus,
  createNewAd,
  deleteAd,
  getListOfFeedbacks,
  deleteFeedback,
  getListOfPushNotifications,
  searchPushNotification,
  getNotificationById,
  createNewNotification,
  deletePushNotification,
  updateNotification,
  addNewVidoes,
  EditVidoes,
  deleteVideo,
  getListOfVideos,
  getListofDocuments,
  addNewDocument,
  editDocument,
  deleteResourceDoc,
  getListofJobs,
  addNewJob,
  EditJob,
  deleteJob,
  getListOfPodcasts,
  addNewPodcast,
  editPodcast,
  deletePodcast,
  getListOfBlogs,
  addNewBlog,
  editBlog,
  deleteBlogs,
  SearchResources,
  getCoursesList,
  searchCourses,
  addNewCourse,
  deleteCourse,
  UpdateCourse,
  getCoursebyId,
  addNewSessiontoCourse,
  addNewSessionQuestions,
  updateSessionQuestions,
  deletesessionQuestion,
  deleteSession,
  editSessions,
  getListOfQuestionsinSession,
  getListOfCourseQuestions,
  addQuestionToCourse,
  updateQuestionToCourse,
  deleteCourseQuestion,
  getFaqs,
  createFAQ,
  editFaq,
  deleteFaq,
  AllSupportChatTickets,
  changeTicketStatus,
  SearchSupportTickets,
  GetTicketMessages,
  sendMessageSupportChat,
  saveNotificationToken,
  createNewSubAdmin,
  EditSubAdmin,
  getListOfSubAdmins,
  deleteSubAdmin,
  addDropDown,
  getDropDownList,
  searchDropDown,
  deleteDropDown,
  editDropDown,
};
