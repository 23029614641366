import {
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import { CompletedCourseType, userViewType } from "../../@types/user";
import { image_Base_Url } from "../../config";
interface Props {
  data: userViewType;
}

const UserCoursesView: React.FC<Props> = ({ data }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  const Purchaseddata = [
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Completed",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
    {
      image: "/dashboardicons/courseicon.png",
      name: "Course Name",
      category: "Courses Category",
      status: "Pending",
      Date: "26/02/2005",
      price: "189",
      courseid: "gfasgbv9877909",
    },
  ];
  return (
    <div className="mt-3 mx-auto h-[100%] w-[96%] bg-white  shadow-md rounded-lg">
      <Tabs variant="soft-rounded" fontSize={"small"} flex={"wrap"}>
        <TabList
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          gap="6px"
          marginX={"15px"}
          paddingY={"12px"}
        >
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Purchased Courses
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Completed Courses
          </Tab>
        </TabList>
        <TabPanels className="scrollbar h-[40vh] overflow-scroll">
          {/* purchased */}
          <TabPanel paddingY={"0"}>
            {Purchaseddata.map((item, index) => (
              <div key={index}>
                <Card className="mt-2 py-2">
                  <div className=" flex justify-between items-center mx-3">
                    <div className="courses flex flex-wrap items-center">
                      <img src={item.image} alt="" />
                      <p className="font-bold">{item.name}</p>
                      <p>{item.category}</p>
                      <p>{item.status}</p>
                      <p>{item.Date}</p>
                    </div>
                    <div>
                      <p className="font-semibold">${item.price}</p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </TabPanel>

          {/* Completed courses */}
          <TabPanel paddingY={"0"}>
            {data.completedCourse?.length === 0 ? (
              <div className="w-[100%] h-[100%] flex justify-center items-center min-h-[150px]">
                No courses Completed
              </div>
            ) : (
              data.completedCourse?.map(
                (item: CompletedCourseType, index: number) => (
                  <div key={index}>
                    {item.completed && (
                      <Card className="mt-2 py-2">
                        <div className="courses flex flex-wrap items-center">
                          <img
                            src={
                              `${image_Base_Url}${item.course.course_pic}` ||
                              "default_image_path"
                            }
                            alt="Course"
                            className="min-w-[50px] max-w-[50px] object-cover rounded-md h-[50px]"
                          />
                          <p className="font-bold">
                            {item.course.course_title}
                          </p>
                          <p>{item.course.course_category}</p>
                          <p>
                            {item.completed ? "Completed" : "Not completed"}
                          </p>
                          <p>{item.course_id}</p>
                          <p>{formatDate(item.createdAt)}</p>
                        </div>
                      </Card>
                    )}
                  </div>
                )
              )
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default UserCoursesView;
