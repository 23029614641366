import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Allfeedback from "./modal/Allfeedback";
import { FeedbackType, userViewType } from "../@types/user";
import { image_Base_Url } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { deleteFeedback, fetchCompleteDetailsofUser } from "../Api";
import DeleteAlert from "./Alert/DeleteAlert";

const AllfeedbackComponent = ({
  feedbackList,
  setfeedbackList,
  setloading,
}: {
  feedbackList: FeedbackType[] | undefined;
  setfeedbackList: React.Dispatch<
    React.SetStateAction<FeedbackType[] | undefined>
  >;
  setloading: React.Dispatch<boolean>;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const [selectedFeedback, setSelectedFeedback] = useState<FeedbackType>();

  const status_emojis = {
    1: { emoji: "😢", description: "needs improvement" },
    2: { emoji: "😄", description: "great" },
    3: { emoji: "😊", description: "good" },
    0: { emoji: "🙂", description: "okay" },
    4: { emoji: "😍", description: "Loved this app" },
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const handleSelect = (feedback: FeedbackType) => {
    setSelectedFeedback(feedback);
    onOpen();
  };

  const fetchUserDetails = async (id: string) => {
    try {
      if (usr) {
        const res = await fetchCompleteDetailsofUser(id, usr.token!);
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [userEmails, setUserEmails] = useState<{ [key: string]: string }>({});
  const [userNames, setUserNames] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [userProfile, setUserProfile] = useState<{
    [key: string]: string | undefined;
  }>({});

  useEffect(() => {
    const fetchAll = async () => {
      const emails: { [key: string]: string } = {};
      const names: { [key: string]: string } = {};
      const profilePic: { [key: string]: string | undefined } = {};
      const promises = feedbackList?.map(async (element) => {
        const user = (await fetchUserDetails(element.user)) as userViewType;
        if (user && user.user) {
          emails[element._id] = user.user.email;
          names[element._id] = user.profile?.name || "No-Name";
          profilePic[element._id] = user.profile?.profile_pic || undefined;
        }
      });
      await Promise.all(promises || []);
      setUserEmails(emails);
      setUserNames(names);
      setUserProfile(profilePic);
    };
    fetchAll();
  }, [feedbackList]);

  const handleDelete = async (id: string) => {
    try {
      if (id && usr) {
        const res = await deleteFeedback(usr!.token, id);
        if (res) {
          setfeedbackList?.((prev?: FeedbackType[]) =>
            prev?.filter((item: FeedbackType) => item._id !== id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedFeedback?._id!);
    onDeleteAlertClose();
  };

  return (
    <div className="w-[95%] m-auto max-h-[85vh] h-[80%] px-3 lg:px-10 md:px-6 rounded-2xl shadow-md bg-white">
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black lg:text-[16px] md:text-[16px] text-[13px] font-bold">
          All Feedback list
        </p>
      </div>
      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="font-bold">
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Name
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Email
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Feedback
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Description
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Date
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {feedbackList !== undefined &&
                feedbackList.map((item: FeedbackType) => (
                  <Tr key={item._id}>
                    <Td className="flex items-center">
                      {userProfile[item._id] !== undefined && (
                        <img
                          src={`${image_Base_Url}${item.profile_pic}`}
                          alt=""
                          className="w-[50px] h-[50px] rounded-lg"
                        />
                      )}

                      <p className="min-w-[150px] mb-2 pt-3 max-w-[240px] h-[100%] text-[13px] pl-3 text-wrap">
                        {userNames[item._id]}
                      </p>
                    </Td>
                    <Td>
                      <p className="min-w-[150px] mb-0  pt-2 text-wrap">
                        {userEmails[item._id] || "-"}
                      </p>
                    </Td>
                    <Td>
                      <p className="max-w-[220px] flex justify-center items-center mb-0 pl-1 pt-2 text-wrap">
                        <span className="mr-1 text-2xl">
                          {
                            status_emojis[
                              item.feedback_emoji as keyof typeof status_emojis
                            ]?.emoji
                          }
                        </span>
                        {
                          status_emojis[
                            item.feedback_emoji as keyof typeof status_emojis
                          ]?.description
                        }
                      </p>
                    </Td>

                    <Td className="pl-1">
                      <p className="mb-0">
                        {item.description.length > 30
                          ? item.description.substring(0, 30) + "..."
                          : item.description}
                      </p>
                    </Td>
                    <Td>{formatDate(item.createdAt)}</Td>
                    <Td>
                      <div className="flex m-auto items-start pt-2 gap-1 min-w-[100px]">
                        <Tooltip label="View" placement={"top"}>
                          <img
                            onClick={() => handleSelect(item)}
                            src="/dashboardicons/actionicon1.png"
                            className="h-[28px] w-[28px] cursor-pointer"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip label="Delete" placement={"top"}>
                          <img
                            onClick={() => {
                              setSelectedFeedback(item);
                              onDeleteAlertOpen();
                            }}
                            src="/dashboardicons/deleteicon.png"
                            alt=""
                            className="h-[28px] w-[28px]"
                          />
                        </Tooltip>
                      </div>
                    </Td>
                  </Tr>
                ))}
              <Allfeedback
                userEmail={userEmails[selectedFeedback?._id!]}
                userName={userNames[selectedFeedback?._id!]}
                data={selectedFeedback}
                isOpen={isOpen}
                onClose={onClose}
              />
              <DeleteAlert
                isOpen={isDeleteAlertOpen}
                onClose={onDeleteAlertClose}
                onConfirmDelete={confirmDelete}
                label="Delete Feedback"
              />
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default AllfeedbackComponent;
