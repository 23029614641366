import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { CourseType, UserType } from "../@types/user";
import { image_Base_Url } from "../config";
import { deleteCourse, searchCourses } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "./Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface CoursesComponentProps {
  setindexes: Dispatch<SetStateAction<number>>;
  coursesdata: CourseType[];
  setcoursesdata: React.Dispatch<React.SetStateAction<CourseType[]>>;
  searchkey: string;
  setsearchkey: React.Dispatch<React.SetStateAction<string>>;
  setselectedCourseId: React.Dispatch<React.SetStateAction<string>>;
  setTotalSessions: React.Dispatch<React.SetStateAction<number>>;
}

const CoursesComponent: React.FC<CoursesComponentProps> = ({
  setindexes,
  coursesdata,
  setcoursesdata,
  searchkey,
  setsearchkey,
  setselectedCourseId,
  setTotalSessions,
}) => {
  const [loading, setloading] = useState(false);
  const [selectedCourseId, setselectedCourse] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const {
    isOpen: isCourseDeleteAlertOpen,
    onClose: onCourseDeleteAlertClose,
    onOpen: onCourseDeleteAlertOpen,
  } = useDisclosure();
  const hanldeSearchSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchkey.trim() !== "") {
        setloading(true);
        const usr2: UserType = usr;
        const searchdata = await searchCourses(usr2!.token, searchkey);
        setcoursesdata(searchdata as CourseType[]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const handleDelete = async (courseid: string) => {
    try {
      if (usr) {
        const res = await deleteCourse(usr.token, courseid);
        if (res === 3) {
          setcoursesdata((prev: CourseType[]) =>
            prev.filter((item) => item._id !== courseid)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmCoursedelete = () => {
    handleDelete(selectedCourseId);
    onCourseDeleteAlertClose();
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Courses list
          </p>
          <form onSubmit={hanldeSearchSubmit} className="relative">
            <Input
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
             <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={() => {
              setindexes(1);
              setselectedCourseId("");
            }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addnew.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Create New Course
            </p>
          </Button>
        </p>
      </div>
      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 px-3 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="py-4 "></Thead>
            <Thead>
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                  minWidth={"220px"}
                  maxWidth={"230px"}
                >
                  Course Title
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Category
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Duration
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Author Name
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Paid Or Free
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Created Date
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  {" "}
                  Action
                </Th>
              </Tr>
            </Thead>

            {loading ? (
              <div className="w-[100vw] h-[40vh] flex justify-center items-center">
                <HashLoader />
              </div>
            ) : (
              <>
                {coursesdata.length > 0 && (
                  <Tbody>
                    {coursesdata.map((item: any, index: number) => (
                      <>
                        <Tr key={index}>
                          <Td>
                            <div className="flex items-center h-100 justify-center">
                              <img
                                src={`${image_Base_Url}${item.course_pic}`}
                                alt=""
                                className="w-[50px] h-[50px] rounded-md"
                              />

                              <p className="min-w-[150px] max-w-[170px] pl-3 pt-2 text-wrap">
                                {item.course_title}
                              </p>
                            </div>
                          </Td>
                          <Td>
                            <p className="max-w-[120px] pl-3 pt-2 text-wrap">
                              {item.course_category}
                            </p>
                          </Td>
                          <Td>
                            <p className="max-w-[120px] pl-3 pt-2 text-wrap">
                              {item.total_duration}
                            </p>
                          </Td>

                          <Td>
                            <span className="flex justify-start items-center gap-2">
                              {item.course_instructor?.profile_pic && (
                                <img
                                  src={`${image_Base_Url}${item.course_instructor?.profile_pic}`}
                                  alt=""
                                  className="h-[25px] w-[20px] rounded-full"
                                />
                              )}

                              {item.course_instructor?.name!}
                            </span>
                          </Td>
                          <Td>{item.paid_course ? "Paid" : "Free"}</Td>

                          <Td>{formatDate(item.createdAt)}</Td>
                          <Td>
                            <div className="flex m-auto items-start gap-1 min-w-[100px]">
                              <Tooltip label="Edit" placement={"top"}>
                                <img
                                  src="/dashboardicons/editicon.png"
                                  className="h-[28px] w-[28px] cursor-pointer"
                                  alt=""
                                  onClick={() => {
                                    setselectedCourseId(item._id!);
                                    setTotalSessions(item.total_session);
                                    setindexes(1);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label="Delete" placement={"top"}>
                                <img
                                  src="/dashboardicons/deleteicon.png"
                                  onClick={() => {
                                    setselectedCourse(item._id);
                                    onCourseDeleteAlertOpen();
                                  }}
                                  alt=""
                                  className="h-[28px] w-[28px] cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      </>
                    ))}
                  </Tbody>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <DeleteAlert
          isOpen={isCourseDeleteAlertOpen}
          onClose={onCourseDeleteAlertClose}
          onConfirmDelete={confirmCoursedelete}
          label="Delete Course"
        />
      </div>
    </div>
  );
};

export default CoursesComponent;
