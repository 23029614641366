import React, { useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import SubscriptionComponent from "../components/SubscriptionComponent";
import Addsubscription from "../components/Subscription/Addsubscription";

const Subscription = () => {
  const [indexes, setindexes] = useState(0);
  const sampledata = [
    {
      price: "18.99",
      type: "month",
      planType: "Monthly",
      Date: "26/02/2023",
    },
    {
      price: "25.99",
      type: "6 month",
      planType: "Monthly",
      Date: "26/02/2023",
    },
    {
      price: "189.99",
      type: "Annually",
      planType: "Annully",
      Date: "26/02/2023",
    },
    {
      price: "25.99",
      type: "6 month",
      planType: "Monthly",
      Date: "26/02/2023",
    },
    {
      price: "189.99",
      type: "Annually",
      planType: "Annully",
      Date: "26/02/2023",
    },
  ];
  const [data,setdata]=useState([...sampledata])
  return (
    <DashboardWrapper>
      <Titlebar  title={"Subscription Management"}/>
      {indexes === 0 && <SubscriptionComponent sampledata={data} setindexes={setindexes} />}
      {indexes === 1 && <Addsubscription setsampledata={setdata} setindexes={setindexes} />}
    </DashboardWrapper>
  );
};

export default Subscription;
