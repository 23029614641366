import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BlogType, CreateBlogType, UserType } from "../../@types/user";
import { addNewBlog, editBlog } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
import ReactQuill from "react-quill";

const CreateBlog = ({
  setdata,
  selectedBlog,
  setselectedBlog,
  isOpen,
  onClose,
}: {
  setdata: any;
  selectedBlog: BlogType | undefined;
  setselectedBlog: Dispatch<SetStateAction<BlogType | undefined>>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const modules = {
    toolbar: [
      [{ align: [] }],
      ["bold", "italic", "underline"],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
    "align",
    "video",
  ];
  const [newData, setnewData] = useState<CreateBlogType>({
    title: "",
    image: null,
    description: "",
  });
  useEffect(() => {
    if (selectedBlog?._id !== undefined) {
      setnewData({
        title: selectedBlog.title,
        image: selectedBlog.image,
        description: selectedBlog.description,
      });
    } else {
      setnewData({
        title: "",
        image: null,
        description: "",
      });
    }
  }, [selectedBlog]);
  const [imageurl, setImageurl] = useState("");
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setnewData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageurl(URL.createObjectURL(file));
      setnewData((prev) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };

  const handleTextChange = (value: string) => {
    setnewData((prevData: CreateBlogType) => ({
      ...prevData,
      description: value,
    }));
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleSubmit = async () => {
    if (newData.image === null) {
      alert("image requried");
    } else {
      if (usr) {
        const usr2: UserType = usr;

        try {
          const res = await addNewBlog(usr2!.token, newData);
          setdata((prev: BlogType[]) => [res, ...prev]);
        } catch (error) {
          console.error("Error adding new instructor:", error);
        }
      }
      setnewData({ title: "", image: null, description: "" });

      onClose();
    }
  };

  const handleedit = async () => {
    if (newData.image === null) {
      alert("image requried");
    } else {
      if (usr && selectedBlog) {
        try {
          const res = await editBlog(usr.token, newData, selectedBlog._id!);

          if (res) {
            setdata((prev: BlogType[]) =>
              prev.map((item) => (item._id === selectedBlog._id ? res : item))
            );
          }
        } catch (error) {
          console.error("Error adding new instructor:", error);
        }
      }

      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setselectedBlog(undefined);
    }
  }, [isOpen, setselectedBlog]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Blog</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addinstructor">
            <div className="text-center w-[100%] mx-auto ">
              <div className=" mx-auto  w-[100px]">
                <div className="border-3 p-1 border-[#3F1B5B] rounded-full">
                  <div className="rounded-full relative bg-[#F0F0F0] ">
                    {newData.image instanceof File ? (
                      <img
                        src={imageurl ?? "/dashboardicons/upload.png"}
                        className="mx-auto rounded-full h-[87px] w-[100px]"
                        alt={
                          newData.image === null
                            ? "Upload icon"
                            : "Instructor profile picture"
                        }
                      />
                    ) : (
                      <img
                        src={
                          newData.image !== null
                            ? `${image_Base_Url}${newData.image}`
                            : "/dashboardicons/upload.png"
                        }
                        className="mx-auto rounded-full h-[87px] w-[100px]"
                        alt={newData.title || "Default profile picture"}
                      />
                    )}
                  </div>
                </div>

                <label htmlFor="fileInput" className="">
                  <img
                    src="/dashboardicons/uploadicon.png"
                    alt=""
                    className="absolute  mt-[-42px] cursor-pointer "
                    style={{ width: "38px", height: "38px" }}
                  />
                </label>
              </div>
              <input
                id="fileInput"
                // multiple
                name="image"
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            <div className="mt-3">
              <label className="text-sm font-semibold">Blog Title</label>
              <Input
                name="title"
                onChange={onchange}
                className="text-sm"
                placeholder="Enter here"
                value={newData.title}
              />
            </div>

            <div style={{ height: "100%", scrollbarWidth: "none" }}>
              <ReactQuill
                theme="snow"
                style={{
                  border: "none",
                  marginTop: "10px",
                  scrollbarWidth: "none",
                }}
                placeholder="Enter here"
                value={newData.description}
                onChange={handleTextChange}
                modules={modules}
                formats={formats}
                className="w-full max-h-[250px]  overflow-scroll  mt-3 border-[white] rounded-[8px] bg-[#F4F4F4]"
              />
            </div>
            <div className="w-[87%] m-auto">
              {selectedBlog?._id !== undefined ? (
                <Button
                  onClick={handleedit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Save
                </Button>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreateBlog;
