import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { PodcastType } from "../../@types/user";
import { addNewPodcast, editPodcast } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
import toast from "react-hot-toast";

const CreatePodcasts = ({
  setpodcasts,
  isOpen,
  onClose,
  selectedPodcast,
  setSelectedPodcast,
}: {
  setpodcasts: any;
  isOpen: boolean;
  onClose: () => void;
  selectedPodcast: PodcastType | undefined;
  setSelectedPodcast: any;
}) => {
  const [newData, setNewData] = useState<PodcastType>({
    title: "",
    podcast: null,
    image: null,
    podcastLink: null,
    host_name: "",
    description: "",
  });

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined);
  const [inputKey, setInputKey] = useState<string>(Date.now().toString()); // Unique key for input reset

  useEffect(() => {
    if (selectedPodcast?._id !== undefined) {
      setNewData({
        title: selectedPodcast.title,
        podcast: selectedPodcast.podcast,
        podcastLink: selectedPodcast?.podcastLink,
        image: selectedPodcast.image,
        host_name: selectedPodcast.host_name,
        description: selectedPodcast.description,
      });

      // Set image and audio URLs for display
      if (
        selectedPodcast.podcast &&
        !(selectedPodcast.podcast instanceof File)
      ) {
        setAudioUrl(`${image_Base_Url}${selectedPodcast.podcast}`);
      }
      if (selectedPodcast.image && !(selectedPodcast.image instanceof File)) {
        setImageUrl(`${image_Base_Url}${selectedPodcast.image}`);
      }
    } else {
      setNewData({
        title: "",
        podcast: null,
        podcastLink: null,
        image: null,
        host_name: "",
        description: "",
      });
      setImageUrl(undefined);
      setAudioUrl(undefined);
    }
  }, [selectedPodcast]);

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const { name } = event.target;
      const validImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      const validAudioTypes = ["audio/mpeg", "audio/wav", "audio/ogg"];

      if (name === "image" && !validImageTypes.includes(file.type)) {
        return toast.error("Select Valid image");
      }

      if (name === "podcast" && !validAudioTypes.includes(file.type)) {
        return toast.error("Select Valid audio");
      }

      setNewData((prev) => ({
        ...prev,
        [name]: file,
      }));

      if (name === "podcast") {
        // Revoke old audio URL if it exists
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl);
        }
        const newAudioUrl = URL.createObjectURL(file);
        setAudioUrl(newAudioUrl);
      } else if (name === "image") {
        const imageUrl = URL.createObjectURL(file);
        setImageUrl(imageUrl);
      }

      // Force input re-render by updating key
      setInputKey(Date.now().toString());
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedPodcast(undefined);
      setImageUrl(undefined);
      setAudioUrl(undefined);
      setInputKey(Date.now().toString());
    }
  }, [isOpen, setSelectedPodcast]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const handleSubmit = async () => {
    if (!newData.image || !newData.podcast) {
      alert("Image and podcast need to be selected");
      return;
    }

    if (usr) {
      try {
        const res = await addNewPodcast(usr.token, newData);
        setpodcasts((prev: PodcastType[]) => [res, ...prev]);
        onClose();
      } catch (error) {
        console.error("Error adding new podcast:", error);
      }
    }
  };

  const updatePodcast = async () => {
    if (!newData.image || !newData.podcast) {
      alert("Image and podcast need to be selected");
      return;
    }

    if (usr && selectedPodcast) {
      try {
        const res = await editPodcast(
          usr.token,
          newData,
          selectedPodcast?._id!
        );
        setpodcasts((prev: PodcastType[]) =>
          prev.map((item) => (item._id === selectedPodcast._id ? res : item))
        );
        onClose();
      } catch (error) {
        console.error("Error updating podcast:", error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedPodcast?._id ? "Edit Podcast" : "Create New Podcast"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <div className="text-center w-[100%] mx-auto">
            <div className="mx-auto w-[100px]">
              <div className="border-3 p-1 border-[#3F1B5B] rounded-full">
                <div className="rounded-full relative bg-[#F0F0F0]">
                  <img
                    src={imageUrl ?? "/dashboardicons/upload.png"}
                    className="mx-auto rounded-full h-[87px] w-[100px]"
                    alt={newData.image ? "Podcast image" : "Upload icon"}
                  />
                </div>
              </div>
              <label htmlFor="fileInput" className="cursor-pointer">
                <img
                  src="/dashboardicons/uploadicon.png"
                  alt="Upload"
                  className="absolute mt-[-42px]"
                  style={{ width: "38px", height: "38px" }}
                />
              </label>
              <input
                id="fileInput"
                name="image"
                accept="image/*"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-3">
            <p className="font-bold text-sm">Upload Your Audio</p>
            <Button
              borderRadius="24px"
              bgColor="#3F1B5B"
              color="white"
              _hover={{ bgColor: "#3F1B5b" }}
              className="flex justify-center items-center"
            >
              <img
                src="/dashboardicons/plus.png"
                className="h-[16px] w-[16px] mr-3"
                alt="Plus"
              />
              <label
                htmlFor="audioInput"
                className="text-sm font-semibold cursor-pointer m-0"
              >
                {newData.podcast ? "Change Audio" : "Select Audio"}
              </label>
            </Button>
            <input
              key={inputKey}
              id="audioInput"
              name="podcast"
              accept="audio/*"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>

          {newData.podcast && typeof newData.podcast === "string" ? (
            <audio controls className="w-[100%] mt-3">
              <source
                src={`${image_Base_Url}${newData.podcast}`}
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          ) : newData.podcast instanceof File ? (
            <audio controls className="w-[100%] mt-3">
              <source src={audioUrl} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          ) : null}

          <div className="mt-3">
            <label className="text-sm font-semibold">Podcast Title</label>
            <Input
              name="title"
              required
              onChange={onChange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.title}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Host Name</label>
            <Input
              required
              onChange={onChange}
              name="host_name"
              className="text-sm"
              placeholder="Enter here"
              value={newData.host_name}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Description</label>
            <Textarea
              required
              name="description"
              onChange={onChange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.description}
            />
          </div>
          <div className="w-[47%] m-auto">
            {selectedPodcast?._id !== undefined ? (
              <Button
                onClick={updatePodcast}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3 p-0"
              >
                Update Podcast
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[6px] mb-3"
              >
                Upload Podcast
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreatePodcasts;
