export const dashboardtoUser = (dashboardUser: any) => {
  return {
    user: {
      _id: dashboardUser._id,
      account_status: dashboardUser.account_status,
      account_type: dashboardUser.account_type,
      createdAt: dashboardUser.createdAt,
      email: dashboardUser.email,
      email_verified: dashboardUser.email_verified,
      role: dashboardUser.role,
      updatedAt: dashboardUser.updatedAt,
      user_status: dashboardUser.user_status,
    },
    profile: {
      _id: dashboardUser.user_profile._id,
      bio: dashboardUser.user_profile.bio,
      denomination: dashboardUser.user_profile.denomination,
      faith: dashboardUser.user_profile.faith,
      createdAt: dashboardUser.user_profile.createdAt,
      date_of_birth: dashboardUser.user_profile.date_of_birth,
      gender_identity: dashboardUser.user_profile.gender_identity,
      name: dashboardUser.user_profile.name,
      profile_pic: dashboardUser.user_profile.profile_pic,
      racial_identity: dashboardUser.user_profile.racial_identity,
      school: dashboardUser.user_profile.school,
      sexual_orientation: dashboardUser.user_profile.sexual_orientation,
      uid: dashboardUser.user_profile.uid,
      updatedAt: dashboardUser.user_profile.updatedAt,
      who_you_are: dashboardUser.user_profile.who_you_are,
      your_company: dashboardUser.user_profile.your_company,
    },
  };
};
