import React from 'react'
import DashboardWrapper from '../components/layouts/DashboardWrapper'
import Titlebar from '../components/titlebarComponent/Titlebar'
import ResourcesComponent from '../components/ResourcesComponent'

const Resources = () => {
  return (
    <DashboardWrapper>
        <Titlebar  title={"Resources"}/>
        <ResourcesComponent />
      </DashboardWrapper>
  )
}

export default Resources
