import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  PushNotificationType,
  userWithUserandProfile,
} from "../../@types/user";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

interface addPeopleProps {
  newData: PushNotificationType;
  setNewData: React.Dispatch<React.SetStateAction<PushNotificationType>>;
  isOpen: boolean;
  onClose: () => void;
  listOfUsers: userWithUserandProfile[] | undefined;
  setsearchKey: Dispatch<SetStateAction<string>>;
  fetchUsers: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  loading: boolean;
  setPage: Dispatch<React.SetStateAction<number>>;
}
const AddPeopleinNotification: React.FC<addPeopleProps> = ({
  newData,
  setNewData,
  isOpen,
  onClose,
  listOfUsers,
  setsearchKey,
  fetchUsers,
  loading,
  setPage,
}) => {
  const inputRef = useRef(null);
  const addUser = (userId: string) => {
    setNewData((prev: PushNotificationType) => ({
      ...prev,
      users: [...(prev.users || []), userId],
    }));
  };

  const handleRemove = (userId: string) => {
    setNewData((prev: PushNotificationType) => ({
      ...prev,
      users: prev.users?.filter((id) => id !== userId),
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    toast.success("Added Users");
    onClose();
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      console.log(firstEntry);
      if (firstEntry.isIntersecting) {
        setPage((prev)=>(prev+1))
      }
    });
    const currentBottomBoundaryRef = inputRef.current;
    if (currentBottomBoundaryRef) {
      observer.observe(currentBottomBoundaryRef);
    }
    return () => {
      if (currentBottomBoundaryRef) {
        observer.unobserve(currentBottomBoundaryRef);
      }
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setsearchKey("");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select User</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <div className="relative">
            <form onSubmit={fetchUsers}>
              <Input
                onChange={(e) => {
                  setsearchKey(e.target.value);
                }}
                className="w-[100%] bg-[#F5F5F5]"
                type="text"
                placeholder="Search here"
              />
            </form>

            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <SearchIcon className="text-gray-400" />
            </div>
          </div>
          <div className="max-h-[70vh] pb-4 overflow-scroll">
            {listOfUsers?.map((item: userWithUserandProfile) => (
              <Card bgColor={"#F5F5F5"} className="mt-4 shadow-md">
                <div className="flex justify-between px-4 items-center">
                  <div className="flex items-center">
                    <img
                      src={"/dashboardicons/usericon.png"}
                      className="w-[54px] h-[54px]"
                      alt=""
                    />
                    <div className="flex flex-col justify-center items-start">
                      <p className="mb-0 ml-1 md:ml-5 lg:ml-5 text-sm font-bold">
                        {item.profile?.name}
                      </p>
                    </div>
                  </div>
                  {/* rigt */}
                  <div>
                    {!newData?.users?.includes(item.user._id) ? (
                      <Button
                        size={"sm"}
                        bgColor={"#3F1B5B"}
                        color={"white"}
                        _hover={{ bgColor: "#3F1B5m" }}
                        className=" flex justify-center items-center text-[12px]"
                        borderRadius={"8px"}
                        onClick={() => {
                          addUser(item.user._id);
                        }}
                      >
                        <img
                          src={"/dashboardicons/plus.png"}
                          className="w-[12px] h-[12px]"
                          alt=""
                        />
                      </Button>
                    ) : (
                      <p
                        onClick={() => {
                          handleRemove(item.user._id);
                        }}
                        className="text-sm text-red-300 cursor-pointer"
                      >
                        remove
                      </p>
                    )}
                  </div>
                </div>
              </Card>
            ))}
            <div ref={inputRef} className="w-100 h-[30px]" />
            {loading && (
              <div className="w-100 flex justify-center">
                <ClipLoader />
              </div>
            )}
          </div>

          <div className="w-[80%] mx-auto mt-3">
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
              onClick={handleSubmit}
            >
              ADD
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
      <ToastContainer />
    </Modal>
  );
};

export default AddPeopleinNotification;
