import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  newSubAdminType,
  SubadminAccType,
  SubadminData,
  UserData,
} from "../../@types/user";
import { createNewSubAdmin, EditSubAdmin } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { conversionSubAdmin } from "../../utils/ConversionSubadmin";
import toast, { Toaster } from "react-hot-toast";

const UserAccessModel = ({
  isOpen,
  onClose,
  setdata,
  selectedSubAdmin,
  setselectedSubAdmin,
}: {
  isOpen: boolean;
  onClose: () => void;
  setdata: Dispatch<SetStateAction<SubadminAccType[]>>;
  selectedSubAdmin: SubadminAccType | undefined;
  setselectedSubAdmin: Dispatch<SetStateAction<SubadminAccType | undefined>>;
}) => {
  const [newData, setnewData] = useState<UserData>({
    name: "",
    email: "",
    phone: "",
    accesses: ["Admin"],
  });

  useEffect(() => {
    if (selectedSubAdmin?._id !== undefined) {
      setnewData({
        name: selectedSubAdmin.subadmin_data.name,
        email: selectedSubAdmin.email,
        phone: selectedSubAdmin.subadmin_data.phone,
        accesses: selectedSubAdmin.subadmin_data.accesses,
      });
    }
  }, [selectedSubAdmin]);

  const [accessOptions] = useState([
    { label: "Users", value: "user" },
    { label: "Courses", value: "course" },
    { label: "Instructor", value: "instructor" },
    { label: "Subscription", value: "subscription" },
    { label: "Resource", value: "resources" },
    { label: "Announcement", value: "announcements" },
    { label: "Event", value: "events" },
    { label: "Advertiser", value: "advertiser" },
    { label: "Support Chat", value: "support_chat" },
    { label: "Push Notification", value: "push_notification" },
    { label: "Feedback", value: "feedback" },
  ]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "name" && /^[a-zA-Z\s.,]*$/.test(value)) {
      setnewData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name === "phone" && /^[0-9]*$/.test(value)) {
      setnewData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else if (name !== "name" && name !== "phone") {
      setnewData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (value: string) => {
    setnewData((prevData) => ({
      ...prevData,
      accesses: prevData.accesses.includes(value)
        ? prevData.accesses.filter((item) => item !== value)
        : [...prevData.accesses, value],
    }));
  };
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  // console.log(newData);
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (newData.accesses.length <= 0) {
      toast("Select atleast One in User Access");
      return;
    }
    const res = (await createNewSubAdmin(
      usr?.token!,
      newData
    )) as newSubAdminType;

    if (res) {
      const response = (await conversionSubAdmin(res)) as SubadminAccType;
      toast.success("Sub Admin Created");
      setdata((prev) => [response, ...prev]);
    }

    onClose();
  };
  const handleUpdate = async () => {
    try {
      if (selectedSubAdmin !== undefined) {
        const res = (await EditSubAdmin(
          usr?.token!,
          newData,
          selectedSubAdmin?._id!
        )) as SubadminData;
        if (res) {
          setdata((prev: SubadminAccType[]) =>
            prev.map((item: SubadminAccType) =>
              item._id !== selectedSubAdmin._id
                ? item
                : { ...item, subadmin_data: res }
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setnewData({
        name: "",
        email: "",
        phone: "",
        accesses: [],
      });
      setselectedSubAdmin(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <span>{selectedSubAdmin?._id ? "Edit" : "Create New"}</span> User
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addinstructor">
            <form onSubmit={onSubmit}>
              <label className="mt-3">Name</label>
              <Input
                name="name"
                value={newData.name}
                onChange={handleChange}
                placeholder="Enter here"
                required
              />
              <label className="mt-3">Email</label>
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                value={newData.email}
                placeholder="Enter here"
                required
              />
              <label className="mt-3">Phone</label>
              <Input
                name="phone"
                onChange={handleChange}
                value={newData.phone}
                placeholder="Enter here"
                required
              />

              <label className="mt-3">User Access</label>
              <Stack spacing={5} direction="column">
                {accessOptions.map((option, index) => (
                  <Checkbox
                    key={index}
                    isChecked={newData?.accesses?.includes(option.value)}
                    onChange={() => handleCheckboxChange(option.value)}
                  >
                    {option.label}
                  </Checkbox>
                ))}
              </Stack>
              <div className="w-[80%] mx-auto">
                {selectedSubAdmin?._id ? (
                  <Button
                    onClick={() => handleUpdate()}
                    style={{ backgroundColor: "#686628" }}
                    className="w-[100%] text-white mt-[12px] mb-3"
                  >
                    UPDATE
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#686628" }}
                    className="w-[100%] text-white mt-[12px] mb-3"
                  >
                    CREATE
                  </Button>
                )}
              </div>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Toaster />
    </>
  );
};

export default UserAccessModel;
