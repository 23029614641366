import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Subscription.css";
import CreateBlog from "../modal/CreateBlog";
import { BlogType, UserType } from "../../@types/user";
import { SearchResources, deleteBlogs, getListOfBlogs } from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "../Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface response {
  blogs: BlogType[];
}

const Blogs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState<BlogType[]>([]);
  const [searchKey, setsearchkey] = useState("");
  const [selectedBlog, setselectedBlog] = useState<BlogType>();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchdata = async (usr: UserType) => {
    try {
      // token, limit, page format
      setloading(true);
      const response = await getListOfBlogs(usr!.token, 100, 1);
      const BlogsArray = response as BlogType[];
      const sortedBlogs = BlogsArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setdata(sortedBlogs);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (usr && searchKey.trim().length === 0) {
      fetchdata(usr);
    }
  }, [usr, searchKey]);

  function createMarkup(description: string) {
    return { __html: description };
  }

  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchKey.trim() !== "") {
        const res = (await SearchResources(
          usr.token,
          "blog",
          searchKey
        )) as response;
        setdata(res.blogs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (blogId: string) => {
    if (usr && blogId) {
      const res = await deleteBlogs(usr.token, blogId);
      if (res === 3) {
        setdata((prev: BlogType[]) =>
          prev.filter((item) => item._id !== blogId)
        );
      }
    }
  };
  const confirmDelete = () => {
    if (selectedBlog !== undefined) {
      handleDelete(selectedBlog._id!);
      onDeleteAlertClose();
    }
  };

  return (
    <div className="w-[95%]">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Blogs
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchKey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addjob.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Create New Blogs
            </p>
          </Button>
          <CreateBlog
            setdata={setdata}
            selectedBlog={selectedBlog}
            setselectedBlog={setselectedBlog}
            isOpen={isOpen}
            onClose={onClose}
          />
        </p>
      </div>
      <div className="subscritpionTable ">
        {loading ? (
          <div className="w-[100] h-[50vh] flex justify-center items-center">
            <HashLoader />
          </div>
        ) : (
          <>
            {data.length > 0 &&
              data.map((item: BlogType) => (
                <>
                  <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                    <CardBody className=" pt-2 pb-0">
                      {/* right and left */}
                      <div className="flex items-center justify-between">
                        {/* left */}
                        <div className="flex">
                          {/* img */}
                          <img
                            src={`${image_Base_Url}${item.image}`}
                            className=" w-[60px] my-1 pb-1 h-[60px] m-auto rounded-md"
                            alt=""
                          />

                          {/* next to img */}
                          <div className="">
                            <div className="flex mt-2 pb-0 items-center">
                              <p className="mb-0 pl-2 font-bold text-[13px]">
                                {item.title}
                              </p>
                              <p className="mb-0 pl-2 lg:flex md:flex hidden">
                                <img
                                  src={"/dashboardicons/clock.png"}
                                  className="opacity-70 px-2"
                                  alt=""
                                />
                                {item.createdAt &&
                                  new Date(item.createdAt)
                                    .toISOString()
                                    .split("T")[0]}
                              </p>
                            </div>
                            <div className="w-100">
                              <p className="pl-2 mt-1 lg:block md:block hidden  text-wrap">
                                {item.description.length > 150 ? (
                                  <span
                                    dangerouslySetInnerHTML={createMarkup(
                                      item.description.slice(0, 100) + "....."
                                    )}
                                  />
                                ) : (
                                  <span
                                    dangerouslySetInnerHTML={createMarkup(
                                      item.description
                                    )}
                                  />
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* right */}
                        <div>
                          <Popover>
                            <PopoverTrigger>
                              <img
                                className="cursor-pointer"
                                src={"/dashboardicons/threedots.png"}
                                alt=""
                              />
                            </PopoverTrigger>
                            <PopoverContent
                              mr={10}
                              maxWidth={"150px"}
                              padding={"3px"}
                              borderRadius={"20px"}
                              borderTopRightRadius={"0px"}
                            >
                              <PopoverBody
                                borderRadius={"20px"}
                                borderTopRightRadius={"0px"}
                              >
                                <p
                                  onClick={() => {
                                    setselectedBlog(item);
                                    onOpen();
                                  }}
                                  className="text-black cursor-pointer text-[15px]"
                                >
                                  Edit
                                </p>

                                <p
                                  onClick={() => {
                                    setselectedBlog(item);
                                    onDeleteAlertOpen();
                                  }}
                                  className="text-red-500 cursor-pointer text-[15px]"
                                >
                                  Delete
                                </p>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <DeleteAlert
                    isOpen={isDeleteAlertOpen}
                    onClose={onDeleteAlertClose}
                    onConfirmDelete={confirmDelete}
                    label="Delete Blog"
                  />
                </>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Blogs;
