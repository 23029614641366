import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Subscription.css";
import NewPdfModal from "../modal/NewPdfModal";
import NewDocumentModel from "../modal/NewDocumentModal";
import { ResourceDocType, UserType } from "../../@types/user";
import {
  SearchResources,
  deleteResourceDoc,
  getListofDocuments,
} from "../../Api";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "../Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface docres {
  docs: ResourceDocType[];
}

const Documents = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [indexes, setindexes] = useState(0);
  const [selecteddocId, setselecteddocId] = useState("");
  const [loading, setloading] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState<string | undefined>(undefined);
  const [totalVideos, setTotalVideos] = useState(20);
  const [selectedDoc, setSelectedDoc] = useState<ResourceDocType | undefined>();
  const [searchdocKey, setsearchDocKey] = useState("");

  const [currentPage, setCurrentpage] = useState(0);
  const itemsPerPage = 10;
  const [data, setDocdata] = useState<ResourceDocType[]>([]);
  const sampledata = [
    {
      videoicon: "/dashboardicons/pdficon.png",
      VideoName: "PDF Name",
      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      Date: "26/02/2023",
    },

    {
      videoicon: "/dashboardicons/pdficon.png",
      VideoName: "PDF Name",
      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      Date: "26/02/2023",
    },
  ];

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (usr && searchdocKey.trim().length === 0) {
          setloading(true);
          const usr2: UserType = usr;
          // token, limit, page format
          const response = await getListofDocuments(
            usr2!.token,
            10,
            currentPage + 1
          );
          const docsarray = response as ResourceDocType[];
          const sortedJobs = docsarray.sort((a, b) => {
            return (
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
            );
          });
          setDocdata(sortedJobs);
        }
      } catch (error) {
        console.log("An error occurred while fetching data:", error);
      } finally {
        setloading(false);
      }
    };

    fetchData();
  }, [currentPage, usr, searchdocKey]);

  // this delete is for doc
  const handleDeleteDoc = async (docId: string) => {
    if (usr && docId) {
      const usr2: UserType = usr;
      const res = await deleteResourceDoc(usr2!.token, docId);

      if (res) {
        setDocdata((prev: ResourceDocType[]) =>
          prev.filter((item) => item._id !== docId)
        );
      }
    }
  };

  const confirmDelete = () => {
    handleDeleteDoc(selecteddocId);
    onDeleteAlertClose();
  };

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };
  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setloading(true);
      if (usr && searchdocKey.trim() !== "") {
        const res = (await SearchResources(
          usr.token,
          "document",
          searchdocKey
        )) as docres;
        setDocdata(res.docs);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  return (
    <div className="w-[98%]">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All PDFs and Document
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchdocKey}
              onChange={(e) => {
                setsearchDocKey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          {indexes === 0 ? (
            <>
              <Button
                onClick={onOpen}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className=" flex justify-center items-center"
              >
                <img
                  src={"/dashboardicons/adddoc.png"}
                  className="h-[16px] w-[16px] mr-3"
                  alt=""
                />
                <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
                  Upload new PDf
                </p>
              </Button>
              <NewPdfModal
                selectedDoc={selectedDoc}
                setSelectedDoc={setSelectedDoc}
                setDocdata={setDocdata}
                isOpen={isOpen}
                onClose={onClose}
              />
            </>
          ) : (
            <>
              <Button
                onClick={onOpen}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className=" flex justify-center items-center"
              >
                <img
                  src={"/dashboardicons/addsubscription.png"}
                  className="h-[16px] w-[16px] mr-3"
                  alt=""
                />
                <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
                  Upload new Document
                </p>
              </Button>
              <NewDocumentModel isOpen={isOpen} onClose={onClose} />
            </>
          )}
        </p>
      </div>
      {/* tabs for pdfs and doucments */}
      <div className=" ">
        <Tabs>
          <TabList className="w-[60%] lg:w-[40%] md:w-[45%]">
            <Tab
              fontSize={"small"}
              _selected={{ textColor: "#792CB5", borderColor: "#792CB5" }}
              fontWeight={"bold"}
              className="w-[50%] text-sm"
              onClick={() => setindexes(0)}
            >
              All PDFs
            </Tab>
            <Tab
              fontSize={"small"}
              _selected={{ textColor: "#792CB5", borderColor: "#792CB5" }}
              fontWeight={"bold"}
              className="w-[50%]"
              onClick={() => setindexes(1)}
            >
              All Documents
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel padding={"0"}>
              {loading ? (
                <>
                  <div className="w-[100] h-[40vh] flex justify-center items-center">
                    <HashLoader />
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div>
                    <div className="subscritpionTable min-h-[50vh]">
                      {data.map((item) => (
                        <>
                          <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                            <CardBody className=" pt-2 pb-0">
                              {/* right and left */}
                              <div className="flex items-center justify-between">
                                {/* left */}
                                <div className="flex">
                                  {/* img */}
                                  <img
                                    src={`/dashboardicons/pdficon.png`}
                                    className=" w-[45px] my-1 pb-1 h-[45px] m-auto"
                                    alt=""
                                  />

                                  {/* next to img */}
                                  <div className="">
                                    <div className="flex mt-2 pb-0 items-center">
                                      <p className="mb-0 pl-2 font-bold text-[13px]">
                                        {item.title}
                                      </p>
                                      <p className="mb-0 pl-2 flex">
                                        <img
                                          src={"/dashboardicons/clock.png"}
                                          className="opacity-70 px-2"
                                          alt=""
                                        />

                                        {item.createdAt &&
                                          new Date(item.createdAt)
                                            .toISOString()
                                            .split("T")[0]}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="pl-2 mt-1">
                                        {item.description.length > 80 ? (
                                          <>
                                            {item.description.slice(0, 79)}
                                            {"...."}
                                          </>
                                        ) : (
                                          item.description
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {/* right */}
                                <div>
                                  <Popover
                                    isOpen={
                                      popoverOpen === item?._id &&
                                      item._id !== undefined
                                    }
                                    onClose={() => setPopoverOpen(undefined)}
                                  >
                                    <PopoverTrigger>
                                      <img
                                        className="cursor-pointer"
                                        src={"/dashboardicons/threedots.png"}
                                        alt=""
                                        onClick={() =>
                                          setPopoverOpen(item?._id)
                                        }
                                      />
                                    </PopoverTrigger>
                                    <PopoverContent
                                      mr={10}
                                      maxWidth={"150px"}
                                      padding={"3px"}
                                      borderRadius={"20px"}
                                      borderTopRightRadius={"0px"}
                                    >
                                      <PopoverBody
                                        borderRadius={"20px"}
                                        borderTopRightRadius={"0px"}
                                      >
                                        <p
                                          onClick={() => {
                                            setSelectedDoc(item);
                                            onOpen();
                                          }}
                                          className="text-black cursor-pointer text-[15px]"
                                        >
                                          Edit
                                        </p>

                                        <p
                                          onClick={() => {
                                            setselecteddocId(item._id!);
                                            onDeleteAlertOpen();
                                          }}
                                          className="text-red-500 cursor-pointer text-[15px]"
                                        >
                                          Delete
                                        </p>
                                      </PopoverBody>
                                    </PopoverContent>
                                  </Popover>
                                </div>
                              </div>
                              <DeleteAlert
                                onClose={onDeleteAlertClose}
                                isOpen={isDeleteAlertOpen}
                                onConfirmDelete={confirmDelete}
                                label="Delete document"
                              />
                            </CardBody>
                          </Card>
                        </>
                      ))}
                    </div>
                    <div className="mt-3 px-2">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel="next"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={Math.ceil(totalVideos / itemsPerPage)}
                        previousLabel="prev"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                        marginPagesDisplayed={2}
                        forcePage={currentPage}
                        // className="mt-3"
                      />
                    </div>
                  </div>
                </>
              )}
            </TabPanel>

            {/* documents tab */}
            <TabPanel padding={"0"}>
              <div className="subscritpionTable ">
                {sampledata.map((item) => (
                  <>
                    <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                      <CardBody className=" pt-2 pb-0">
                        {/* right and left */}
                        <div className="flex items-center justify-between">
                          {/* left */}
                          <div className="flex">
                            {/* img */}
                            <img
                              src={item.videoicon}
                              className=" w-[45px] my-1 pb-1 h-[45px] m-auto"
                              alt=""
                            />

                            {/* next to img */}
                            <div className="">
                              <div className="flex mt-2 pb-0 items-center">
                                <p className="mb-0 pl-2 font-bold text-[13px]">
                                  {item.VideoName}
                                </p>
                                <p className="mb-0 pl-2 flex">
                                  <img
                                    src={"/dashboardicons/clock.png"}
                                    className="opacity-70 px-2"
                                    alt=""
                                  />
                                  {item.Date}
                                </p>
                              </div>
                              <div>
                                <p className="pl-2 mt-1">{item.text}</p>
                              </div>
                            </div>
                          </div>

                          {/* right */}
                          <div>
                            <img
                              className="cursor-pointer"
                              src={"/dashboardicons/threedots.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </>
                ))}
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Documents;
