import { Button, useDisclosure } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import "./Users.css";
import EditUserModel from "../modal/EditUserModel";
import { userViewType, userWithUserandProfile } from "../../@types/user";
import { deleteUser } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DeleteAlert from "../Alert/DeleteAlert";

interface UserDetailProfileProps {
  setListOfUser?:
    | Dispatch<SetStateAction<userWithUserandProfile[]>>
    | undefined;
  setindexes: Dispatch<SetStateAction<number>>;
  fromDashboard?: boolean;
  user: userViewType | undefined;
  setCompleteUserdetails:
    | Dispatch<SetStateAction<userViewType | undefined>>
    | undefined;
  refresh?: () => void;
}

const UserDetailProfile: React.FC<UserDetailProfileProps> = ({
  setListOfUser,
  setindexes,
  user,
  refresh,
  fromDashboard,
  setCompleteUserdetails,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [selectedId, setselectedId] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  if (user === undefined) {
    return null;
  }

  const handleDelete = async (user_id: string) => {
    try {
      if (usr && user_id) {
        const res = await deleteUser(usr.token, user_id);
        if (res) {
          setListOfUser?.((prev) =>
            prev.filter((item) => item.user._id !== user_id)
          );
        }
        if (fromDashboard) {
          refresh?.();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setindexes(0);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedId);
    onDeleteAlertClose();
  };
  return (
    <div className="userdetail h-[100%] w-[96%] bg-white m-auto shadow-md rounded-lg">
      {/*title and buttons  */}
      <div className="flex px-3 py-3 justify-between items-center">
        <p className="title">User Detail</p>
        <div className="flex">
          <Button
            size={"sm"}
            bgColor={"#686628"}
            color={"white"}
            _hover={{ backgroundColor: "#686620" }}
            onClick={onOpen}
          >
            <img
              src={"/dashboardicons/edit.png"}
              className="h-[18px] mr-2 w-[18px]"
              alt=""
            />
            Edit
          </Button>
          <EditUserModel
            setListOfUser={setListOfUser}
            isOpen={isOpen}
            onClose={onClose}
            usersdata={user}
            setCompleteUserdetails={setCompleteUserdetails}
            refresh={refresh}
            fromDashboard={fromDashboard}
          />

          <Button
            size={"sm"}
            bgColor={"#BF5529"}
            color={"white"}
            _hover={{ backgroundColor: "#BF5529" }}
            onClick={() => {
              setselectedId(user.user?._id!);
              onDeleteAlertOpen();
            }}
          >
            <img
              src={"/dashboardicons/deleteicon.png"}
              className="h-[28px] w-[28px]"
              alt=""
            />
            Delete
          </Button>
          <Button
            onClick={() => setindexes(0)}
            size={"sm"}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ backgroundColor: "#3F1B5B" }}
          >
            Back
          </Button>
        </div>
      </div>
      {/* details */}
      <div className="flex flex-wrap px-3">
        {/* imgae and name */}
        <div className="flex justify-center items-center">
          <img src={"/dashboardicons/user.png"} alt="" />
          <p className="font-bold pl-4">{user?.profile?.name}</p>
        </div>
        {/* email */}
        <div className="singledetailitem">
          <label>Email</label>
          <p>{user?.user?.email}</p>
        </div>
        {/* DOB */}
        <div className="singledetailitem">
          <label>DOB</label>
          <p>{new Date(user.profile?.date_of_birth!).toLocaleDateString()}</p>
        </div>
        {/* gender */}
        <div className="singledetailitem">
          <label>Gender</label>
          <p>{user?.profile?.gender_identity}</p>
        </div>
        <div className="singledetailitem">
          <label>Racial Identity</label>
          <p>{user?.profile?.racial_identity}</p>
        </div>
        <div className="singledetailitem">
          <label>Who are you</label>
          <p>{user.profile?.who_you_are!}</p>
        </div>
        <div className="singledetailitem">
          <label>School</label>
          <p>{user?.profile?.school}</p>
        </div>{" "}
        <div className="singledetailitem">
          <label>Address</label>
          <p>{user?.address?.address}</p>
        </div>{" "}
        <div className="singledetailitem">
          <label>city</label>
          <p>{user?.address?.city}</p>
        </div>
        <div className="singledetailitem">
          <label>state</label>
          <p>{user?.address?.state}</p>
        </div>
        <div className="singledetailitem">
          <label>Country</label>
          <p>{user?.address?.country}</p>
        </div>
        <div className="singledetailitem">
          <label>Zip Code</label>
          <p>{user?.address?.zip_code}</p>
        </div>
      </div>
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onConfirmDelete={confirmDelete}
        label="user"
      />
    </div>
  );
};

export default UserDetailProfile;
