import { Button, Input, Switch, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";

import "./CreateCourses.css";
import {
  CourseType,
  CreateCourseType,
  DropDownProps,
  Instructor,
  UserType,
} from "../../@types/user";
import ReactSelect from "react-select";
import {
  UpdateCourse,
  addDropDown,
  addNewCourse,
  getDropDownList,
  listOfInstructors,
  searchDropDown,
} from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ToastContainer, toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { CustomOption } from "../DropDownCustomoption/CustomOptions";
interface CourseResponse {
  courseId: string;
  totalSessions: string;
  course: CourseType;
}

interface InstructorRes {
  instructors: Instructor[];
  totalInstructors: number;
}

interface CreateNewCourseProps {
  setindexes: Dispatch<SetStateAction<number>>;
  setcoursesdata?: Dispatch<SetStateAction<CourseType[]>>;
  selectedCourseId: string;
  fromDashboard?: boolean;
  setselectedCourseId: Dispatch<SetStateAction<string>>;
  setTotalSessions: Dispatch<SetStateAction<any>>;
  selectedCourse?: CourseType;
  refresh?: () => {};
  setselectedCourse?: Dispatch<SetStateAction<CourseType | undefined>>;
}
const CreateNewCourseComponent: React.FC<CreateNewCourseProps> = ({
  setindexes,
  setcoursesdata,
  selectedCourseId,
  fromDashboard,
  setselectedCourseId,
  setTotalSessions,
  selectedCourse,
  refresh,
  setselectedCourse,
}) => {
  const [Coursedetails, setCourseDetails] = useState<CreateCourseType>(() => {
    return {
      course_title: "",
      course_category: "",
      total_duration: 0,
      course_lang: "",
      course_instructor: "",
      total_session: 0,
      paid_course: false,
      description: "",
      course_pic: null,
      course_amt: 0,
    };
  });

  useEffect(() => {
    if (selectedCourse?._id !== undefined) {
      setCourseDetails((prevDetails) => ({
        ...prevDetails,
        course_title: selectedCourse.course_title || "",
        course_category: selectedCourse.course_category || "",
        total_duration: selectedCourse.total_duration || 0,
        course_lang: selectedCourse.course_lang || "",
        course_instructor:
          typeof selectedCourse.course_instructor === "object"
            ? selectedCourse?.course_instructor?._id
            : selectedCourse.course_instructor || "",
        total_session: selectedCourse.total_session || 0,
        paid_course: selectedCourse.paid_course || false,
        description: selectedCourse.description || "",
        course_pic: selectedCourse.course_pic || null,
        course_amt: selectedCourse.paid_course
          ? selectedCourse.course_amt || 0
          : 0,
      }));
    }
  }, [selectedCourse, setselectedCourseId]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  // this is for fteching the data of all instructors
  const [allinstructors, setInstructors] = useState<Instructor[]>([]);
  useEffect(() => {
    const instructordata = Number(
      localStorage.getItem("totalInstructors") || 1000
    );
    if (usr) {
      const fetchInstructor = async () => {
        const usr2: UserType = usr;
        const res = (await listOfInstructors(
          usr2!.token,
          1,
          instructordata
        )) as InstructorRes;
        // const instructorsArray = res as InstructorType[];
        const sortedInstructors = res.instructors.sort(
          (a: Instructor, b: Instructor) => {
            return (
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
            );
          }
        );
        setInstructors(sortedInstructors);
      };
      fetchInstructor();
    }
  }, [usr]);

  const [imageurl, setImageurl] = useState("");

  const ontextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCourseDetails((prev: CreateCourseType) => ({ ...prev, [name]: value }));
  };

  // this is for selecting options
  const handleChange = (field: string, value: string | null | number) => {
    setCourseDetails((prevData: CreateCourseType) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // for image handling
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageurl(URL.createObjectURL(file));
      setCourseDetails((prev: CreateCourseType) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };

  const validateCourseDetails = (details: CreateCourseType) => {
    const errors = [];

    if (!details.course_title || details.course_title.trim().length === 0)
      errors.push("Course title is required");
    if (!details.course_category || details.course_category.trim().length === 0)
      errors.push("Course category is required");
    if (!details.total_duration || details.total_duration <= 0)
      errors.push("Total duration must be greater than 0");
    if (!details.course_lang || details.course_lang.trim().length === 0)
      errors.push("Course language is required");
    if (
      !details.course_instructor ||
      details.course_instructor.toString().trim().length === 0
    )
      errors.push("Course instructor is required");
    if (!details.total_session || details.total_session <= 0)
      errors.push("Total session must be greater than 0");
    if (details.paid_course && (!details.course_amt || details.course_amt <= 0))
      errors.push("Course amount must be greater than 0 for paid courses");
    if (!details.description || details.description.trim().length === 0)
      errors.push("Description is required");
    if (!details.course_pic) errors.push("Course picture is required");
    return errors;
  };
  // hanldeing the submit and this is creating new course
  const handleSaveNewCourse = async () => {
    if (usr) {
      try {
        const validationErrors = validateCourseDetails(Coursedetails);
        if (validationErrors.length > 0) {
          if (validationErrors.length < 3) {
            validationErrors.forEach((element) => {
              toast.error(element);
            });
          } else {
            toast.error("Fill all details");
          }
        } else {
          const response = (await addNewCourse(
            usr!.token,
            Coursedetails
          )) as CourseResponse;

          setselectedCourseId(response.courseId);
          setTotalSessions(response.totalSessions);
          setcoursesdata?.((prev: CourseType[] = []) => [
            response.course,
            ...prev,
          ]);
          // setinstructordata((prev: any) => [response, ...prev]);
          setindexes(2);
        }
      } catch (error) {
        console.error("Error adding new instructor:", error);
      }
    }
  };
  // this is for updating the course will not create the new course
  const hanldeCourseUpdate = async () => {
    if (usr) {
      const usr2: UserType = usr;

      try {
        const response = (await UpdateCourse(
          usr2!.token,
          Coursedetails,
          selectedCourseId
        )) as CourseResponse;
        setcoursesdata?.((prev: CourseType[]) =>
          prev.map((item) => {
            if (item._id === selectedCourseId) {
              return response.course;
            }
            return item;
          })
        );
        setselectedCourseId(response.courseId);
        setTotalSessions(response.totalSessions);
      } catch (error) {
        console.error("Error adding new instructor:", error);
      }
      if (fromDashboard) {
        refresh?.();
        setindexes(3);
      } else {
        setindexes(2);
      }
      setCourseDetails({
        course_title: "",
        course_category: "",
        total_duration: 0,
        course_lang: "",
        course_instructor: "",
        total_session: 0,
        paid_course: false,
        description: "",
        course_pic: null,
      });
    }
  };

  // option for selecting
  const [Categoryoptions, setCategoryOptions] = useState([
    { value: "", label: "" },
  ]);
  const Course_langOptions = [
    { value: "Telugu", label: "Telugu" },
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
    { value: "Tamil", label: "tamil" },
  ];

  const CourseInstructorOptions =
    allinstructors.length > 0
      ? allinstructors.map((instructor: Instructor) => {
          return { value: instructor._id, label: instructor.name };
        })
      : [{ value: "types", label: "types" }];
  const TotalSessionsOptions = [
    { value: 1, label: "1 Session" },
    { value: 2, label: "2 Sessions" },
    { value: 3, label: "3 Sessions" },
    { value: 4, label: "4 Sessions" },
    { value: 5, label: "5 Sessions" },
    { value: 6, label: "6 Sessions" },
    { value: 7, label: "7 Sessions" },
    { value: 8, label: "8 Sessions" },
    { value: 9, label: "9 Sessions" },
    { value: 10, label: "10 Sessions" },
  ];

  const fetchCourseCategory = async () => {
    try {
      const res = (await getDropDownList(
        usr?.token!,
        1,
        10,
        0
      )) as DropDownProps[];
      if (res) {
        setCategoryOptions(
          res.map((item) => ({ label: item.spelling, value: item._id }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCourseCategory();
  }, []);

  const fetchOptionswithSearch = async (inputValue: string, type: number) => {
    try {
      const res = (await searchDropDown(
        usr?.token!,
        inputValue,
        type
      )) as DropDownProps[];
      return res.map((item) => ({ value: item._id, label: item.spelling }));
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const loadOptions = (inputValue: string, type: number) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(fetchOptionswithSearch(inputValue, type));
      }, 1000);
    });

  // to create new option in course dropdown
  const createNewOptioninDropDown = async (
    type: number,
    spellings: string[]
  ) => {
    try {
      const res = (await addDropDown(
        usr?.token!,
        type,
        spellings
      )) as DropDownProps[];
      if (res) {
        setCategoryOptions((prev) => [
          { label: res[0].spelling, value: res[0]._id },
          ...prev,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const CustomSelectComponents = {
    Option: (props: any) => (
      <CustomOption
        className="z-10"
        {...props}
        setCategoryOptions={setCategoryOptions}
        type={0}
      />
    ),
  };
  return (
    <div className="createCourse w-[96%] bg-white mx-auto mb-3 rounded-2xl shadow-md">
      {/* title /header inside component */}
      <div className="flex justify-between py-4 lg:px-5 px-3 ">
        <p className="font-bold">
          {selectedCourse ? "Edit Course" : "Create New Courses"}
        </p>

        <Button
          onClick={() => {
            setselectedCourseId("");
            setindexes(0);
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="lg:px-5 px-3"
        >
          Back
        </Button>
      </div>
      <ToastContainer />

      {/* new course container */}
      <div className="lg:w-[70%] md:w-[75%] w-[90%] m-auto h-[100%]">
        <form>
          {/* upload image */}
          <div className="text-center w-[100%] mx-auto ">
            <div className=" mx-auto  w-[100px]">
              <div className="border-3 w-[100px] h-[100px] z-100 p-1 border-[#3F1B5B] rounded-full">
                <div className="rounded-full relative bg-[#F0F0F0] ">
                  {/* for image if we get from backend we get string and need to add a baseurl before it */}
                  {Coursedetails.course_pic instanceof File ? (
                    <img
                      src={
                        Coursedetails.course_pic === null
                          ? "/dashboardicons/upload.png"
                          : imageurl
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt="rgthw"
                    />
                  ) : (
                    <img
                      src={
                        Coursedetails?.course_pic !== null
                          ? `${image_Base_Url}${Coursedetails.course_pic}`
                          : "/dashboardicons/upload.png"
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt=""
                    />
                  )}
                </div>
              </div>

              <label htmlFor="fileInput" className="">
                <img
                  src="/dashboardicons/uploadicon.png"
                  alt=""
                  className="absolute  mt-[-42px] cursor-pointer "
                  style={{ width: "38px", height: "38px" }}
                />
              </label>
            </div>
            <input
              id="fileInput"
              // multiple
              name="course_pic"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>

          {/* selecting and wiritng component */}
          <div>
            {/* title and catgeory */}
            <div className="createCourserow">
              <div>
                <label>Course Title</label>
                <Input
                  required
                  onChange={ontextChange}
                  name="course_title"
                  placeholder="Enter here"
                  className="bg-white"
                  value={Coursedetails.course_title}
                />
              </div>
              <div className="w-[47%]">
                <label className="mb-2">Course Category</label>
                <CreatableSelect
                  required
                  value={Categoryoptions.find(
                    (Categoryoptions) =>
                      Categoryoptions.label?.toLowerCase() ===
                      Coursedetails.course_category?.toLowerCase()
                  )}
                  onChange={(selectedOption) =>
                    handleChange("course_category", selectedOption!.label)
                  }
                  name="type"
                  options={Categoryoptions}
                  className="w-[100%]"
                  components={CustomSelectComponents}
                  onCreateOption={(inputValue) => {
                    createNewOptioninDropDown(0, [inputValue]);
                  }}
                />
              </div>
            </div>
            {/* total duration and language */}
            <div className="createCourserow">
              <div>
                <label>Total Duration</label>
                <Input
                  required
                  onChange={ontextChange}
                  className="bg-white"
                  name="total_duration"
                  placeholder="Enter here"
                  isRequired
                  value={Coursedetails.total_duration}
                />
              </div>
              <div>
                <label className="mb-2">Course Language</label>
                <ReactSelect
                  required
                  value={Course_langOptions.find(
                    (Course_langOptions) =>
                      Course_langOptions.value === Coursedetails.course_lang
                  )}
                  onChange={(selectedOption) =>
                    handleChange("course_lang", selectedOption!.value)
                  }
                  name="course_lang"
                  options={Course_langOptions}
                  className="w-[100%]"
                />
              </div>
            </div>
            {/* instrunctoe and Total sessions */}
            <div className="createCourserow">
              <div>
                <label>Course Instructor</label>
                <ReactSelect
                  required
                  value={CourseInstructorOptions.find((option) => {
                    if (typeof Coursedetails.course_instructor === "string") {
                      return option.value === Coursedetails.course_instructor;
                    } else {
                      return (
                        option.value === Coursedetails.course_instructor._id
                      );
                    }
                  })}
                  onChange={(selectedOption) =>
                    handleChange("course_instructor", selectedOption!.value!)
                  }
                  name="course_instructor"
                  options={CourseInstructorOptions}
                  className="w-[100%]"
                />
              </div>
              <div>
                <label>Total Sessions</label>
                <ReactSelect
                  required
                  value={TotalSessionsOptions.find(
                    (TotalSessionsOptions) =>
                      TotalSessionsOptions.value === Coursedetails.total_session
                  )}
                  onChange={(selectedOption) =>
                    handleChange("total_session", selectedOption!.value)
                  }
                  name="type"
                  options={TotalSessionsOptions}
                  className="w-[100%]"
                />
              </div>
            </div>

            {/* paid course item and button */}
            <div className="createCourserow">
              <div>
                <div>
                  <p>Paid Course</p>
                </div>
              </div>
              <div>
                <Switch
                  name="paid"
                  isChecked={Coursedetails.paid_course}
                  onChange={() => {
                    setCourseDetails((prev: CreateCourseType) => ({
                      ...prev,
                      paid_course: !prev.paid_course,
                    }));
                  }}
                />
              </div>
            </div>
            {Coursedetails.paid_course && (
              <>
                <div className="w-[47%]">
                  <label>Course Amount</label>
                  <Input
                    onChange={ontextChange}
                    name="course_amt"
                    placeholder="Enter here"
                    required
                    value={Coursedetails.course_amt}
                  />
                </div>
              </>
            )}
            {/* descrpiton */}

            <div className="mt-[7px]">
              <label className="text-sm ml-4">Description</label>
              <Textarea
                value={Coursedetails.description}
                required
                onChange={ontextChange}
                name="description"
                className="w-[100%] mt-[10px]"
                placeholder="Enter here"
              />
            </div>

            <div className="w-[47%] m-auto">
              {selectedCourse ? (
                <Button
                  // type="submit"
                  onClick={hanldeCourseUpdate}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Next
                </Button>
              ) : (
                <Button
                  // type="submit"
                  onClick={handleSaveNewCourse}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewCourseComponent;
