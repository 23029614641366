import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import PushnotificationComponent from "../components/PushnotificationComponent";
import { PushNotificationType, UserType } from "../@types/user";
import { getListOfPushNotifications } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import HashLoader from "react-spinners/HashLoader";

// for creating new notification we used modalops that is why there is no indexes

const PushNotification = () => {
  const [loading, setloading] = useState(false);
  // infinite scrolling
  const [currentPage, setCurrentpage] = useState(0);

  const [data, setdata] = useState<PushNotificationType[]>([]);
  const [searchkey, setsearchkey] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  useEffect(() => {
    try {
      if (usr && searchkey.length === 0) {
        setloading(true);
        const usr2: UserType = usr;
        const fetchData = async () => {
          const listofnotifications = (await getListOfPushNotifications(
            usr2!.token,
            currentPage + 1,
            10
          )) as PushNotificationType[];
          setdata((prev) => [...prev, ...listofnotifications]);
        };
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  }, [searchkey.length, usr, currentPage]);
  return (
    <DashboardWrapper>
      <Titlebar title={"Push Notifications"} />
      {loading && data.length === 0 ? (
        <div className="w-[100] h-[80vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          <PushnotificationComponent
            searchkey={searchkey}
            setsearchkey={setsearchkey}
            data={data}
            setdata={setdata}
            loading={loading}
            setCurrentpage={setCurrentpage}
          />
        </>
      )}
    </DashboardWrapper>
  );
};

export default PushNotification;
