import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import Select from "react-select";
import { AdvertisersType, CreatedAd } from "../../@types/user";
import { createNewAd } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ToastContainer, toast } from "react-toastify";
import { image_Base_Url } from "../../config";

interface newAdProps {
  setPendingAds?: Dispatch<SetStateAction<CreatedAd[]>>;
  selectedAdvertiser: AdvertisersType | undefined;
  isOpen: boolean;
  onClose: () => void;
  selectedAd?: CreatedAd | undefined;
  setselectedAd?: Dispatch<SetStateAction<CreatedAd | undefined>>;
}

const CreatenewAd: React.FC<newAdProps> = ({
  setPendingAds,
  selectedAdvertiser,
  isOpen,
  onClose,
  selectedAd,
  setselectedAd,
}) => {
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [newdata, setNewData] = useState<CreatedAd>({
    advertiser_id: selectedAdvertiser?._id!,
    title: "",
    description: "",
    ad_posting_platform: 0,
    audience: 0,
    ad_type: 0,
    duration: 0,
    ad_image: null,
    link: "",
    status: 0,
    image: "",
  });
  useEffect(() => {
    if (selectedAd?._id !== undefined) {
      setNewData({
        advertiser_id: selectedAdvertiser?._id!,
        title: selectedAd.title,
        description: selectedAd.description,
        ad_posting_platform: selectedAd.ad_posting_platform,
        audience: selectedAd.audience,
        ad_type: selectedAd.ad_type,
        duration: selectedAd.duration,
        ad_image: selectedAd.ad_image,
        link: selectedAd.link,
        status: selectedAd.status,
        image: selectedAd.image,
      });
    }
  }, [selectedAd, selectedAdvertiser]);

  useEffect(() => {
    if (!isOpen) {
      setselectedAd?.(undefined);
    }
  }, [isOpen]);

  const [imageurl, setImageurl] = useState("");

  const optionsforPlatform = [
    { value: 1, label: "Ads Across Android" },
    { value: 2, label: "Ads Across iOS" },
  ];
  const optionsForTargetAudience = [
    { value: 1, label: "Students" },
    { value: 2, label: "Minister" },
    { value: 3, label: "Clergy" },
    { value: 4, label: "Non-profit Organizer" },
    { value: 5, label: "Business Leader" },
    { value: 6, label: "Professor" },
    { value: 7, label: "All" },
  ];

  const optionsForAdType = [
    { value: 1, label: "Android Banner" },
    { value: 2, label: "Android Interstitial" },
    { value: 3, label: "Android Rewarded" },
    { value: 4, label: "Android Native" },
    { value: 5, label: "iOS Standard Banner" },
    { value: 6, label: "Android App Open" },
    { value: 7, label: "iOS Large Banner" },
    { value: 8, label: "iOS Interstitial" },
    { value: 9, label: "iOS Carousel" },
    { value: 10, label: "iOS Native" },
  ];

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event?.target?.files[0];
      setImageurl(URL.createObjectURL(file));
      setNewData((prev: CreatedAd) => ({ ...prev, [event.target.name]: file }));
    }
  };

  // this is for input text change
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setNewData((prev) => ({
      ...prev,
      [name]: name === "duration" ? parseInt(value, 10) : value,
    }));
  };

  // for options change
  const optionsChange = (e: any, name: string) => {
    setNewData((prev) => ({ ...prev, [name]: e.value }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (usr) {
      try {
        if (newdata.ad_image === null) {
          toast.error("Select an Image");
        } else {
          const res = (await createNewAd(usr.token, newdata)) as CreatedAd;
          if (res) {
            setPendingAds?.((prev: CreatedAd[]) => [res, ...prev]);
          }
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Ad</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <form onSubmit={handleSubmit}>
            <div className="mt-2">
              <label className="text-sm font-semibold">Ad Title</label>
              <Input
                className="text-sm"
                placeholder="Enter here"
                name="title"
                onChange={onchange}
                required
                value={newdata.title}
              />
            </div>
            <ToastContainer />
            <div className="mt-2">
              <label className="text-sm font-semibold">Ad Description</label>
              <Input
                className="text-sm"
                placeholder="Enter here"
                name="description"
                onChange={onchange}
                required
                value={newdata.description}
              />
            </div>
            <div className="mt-2">
              <label className="text-sm font-semibold">
                Ad Posting Platform
              </label>
              <Select
                value={optionsforPlatform.find(
                  (option) => option.value === newdata.ad_posting_platform
                )}
                onChange={(e) => optionsChange(e, "ad_posting_platform")}
                options={optionsforPlatform}
                required
              />
            </div>
            <div className="mt-2">
              <label className="text-sm font-semibold">Target Audience</label>
              <Select
                onChange={(e) => optionsChange(e, "audience")}
                value={optionsForTargetAudience.find(
                  (option) => option.value === newdata.audience
                )}
                options={optionsForTargetAudience}
                required
              />
            </div>
            <div className="mt-2">
              <label className="text-sm font-semibold">Select Ad Type</label>
              <Select
                onChange={(e) => optionsChange(e, "ad_type")}
                value={optionsForAdType.find(
                  (option) => option.value === newdata.ad_type
                )}
                options={optionsForAdType}
                required
              />
            </div>
            <div className="mt-2">
              <label className="text-sm font-semibold">
                Select Ad Duration
              </label>
              <Input
                name="duration"
                onChange={onchange}
                className="text-sm"
                placeholder="Enter here"
                value={newdata.duration}
              />
            </div>
            {/* for banner */}

            {newdata.ad_type !== 0 && (
              <>
                <div className="mt-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="mb-1 text-sm font-semibold mt-3">
                        {optionsForAdType.find(
                          (item) => item.value === newdata.ad_type
                        )?.label || "-"}
                      </p>
                      {/* <p className="mb-1 text-sm">Banner Size(size x size)</p> */}
                    </div>
                  </div>

                  <div className="mt-2">
                    <label className="text-sm font-semibold">
                      Link to Redirect
                    </label>
                    <Input
                      name="link"
                      onChange={onchange}
                      className="text-sm"
                      placeholder="Enter here"
                      required
                      value={newdata.link}
                    />
                  </div>
                  {/* for image */}
                  <div className="flex">
                    <img
                      className="py-2 cursor-pointer"
                      src="/dashboardicons/selectimage.png"
                      onClick={() => {
                        document.getElementById("fileInput")?.click();
                      }}
                      alt=""
                    />
                    {/* Hidden Input File */}
                    <input
                      name="ad_image"
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    {newdata.ad_image || newdata.image ? (
                      <>
                        {newdata.ad_image instanceof File ? (
                          <img
                            src={imageurl}
                            alt=""
                            className="w-[60px] h-[60px] m-3 rounded-lg"
                          />
                        ) : (
                          newdata.image && (
                            <img
                              src={`${image_Base_Url}${newdata.image}`}
                              alt="gbd"
                              className="w-[60px] h-[60px] m-3 rounded-lg"
                            />
                          )
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="w-[80%] mx-auto mt-3">
              {selectedAd?._id !== undefined ? (
                <Button
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Save
                </Button>
              )}
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreatenewAd;
