import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { SupportTicket } from "../../@types/user";

const TicketDetails = ({
  isOpen,
  onClose,
  selectedTicket,
}: {
  isOpen: boolean;
  onClose: () => void;
  selectedTicket?: SupportTicket;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ticket Information</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p className="font-semibold text-sm">Title</p>
          <p className="text-xs">{selectedTicket?.title}</p>
          <p className="font-semibold text-sm">Topic </p>
          <p className="text-xs">{selectedTicket?.subject}</p>
          <p className="font-semibold text-sm">Descrption</p>
          <p className="text-xs">{selectedTicket?.description}</p>
          <p className="font-semibold text-sm">Status</p>
          <p className="text-xs text-green-600">
            {selectedTicket?.status === 0 ? "Open" : "Close"}
          </p>
          <p className="font-semibold text-sm">Created on</p>
          <p className="text-xs ">
            {/* {new Date(selectedTicket?.createdAt!).toISOString().split("T")[0]} */}
          </p>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TicketDetails;
