import { Button, Card } from "@chakra-ui/react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import MobileTicketView from "./MobileTicketView";
import { SupportTicket } from "../../@types/user";
import { formatDistanceToNowStrict as fns } from "date-fns";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import { ClipLoader } from "react-spinners";
const MobileAllTickets = ({
  supportTickets,
  setSupportTickets,
  currentPage,
  setCurrentPage,
  isLoading,
}: {
  supportTickets: SupportTicket[];
  setSupportTickets: Dispatch<SetStateAction<SupportTicket[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  isLoading: boolean;
}) => {
  const [selectedTicket_idx, setSelectedTicket_idx] = useState<number>(0);
  const [indexes, setIndexes] = useState(0);

  const handleClick = (index: number) => {
    setSelectedTicket_idx(index);
    setIndexes(1);
  };
  function TicketStatus(status: number) {
    if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Open";
    } else {
      return "Closed";
    }
  }
  // for infinite scroll of chat list
  const bottomboundaryRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        setCurrentPage((prev) => prev + 1);
      }
    });
    const currentBottomBoundaryRef = bottomboundaryRef.current;
    if (currentBottomBoundaryRef) {
      observer.observe(currentBottomBoundaryRef);
    }
    return () => {
      if (currentBottomBoundaryRef) {
        observer.unobserve(currentBottomBoundaryRef);
      }
    };
  }, []);
  return (
    <div className="w-[90%] mx-auto">
      {indexes === 0 && (
        <>
          <Tabs variant="soft-rounded" className="px-2 ">
            <TabList className="py-2">
              <Tab
                _selected={{ color: "black", bg: "yellow.400" }}
                backgroundColor={"rgba(238, 238, 238, 1)"}
                fontSize={"xs"}
                mr={"8px"}
              >
                All Tickets
              </Tab>
              <Tab
                _selected={{ color: "black", bg: "yellow.400" }}
                backgroundColor={"rgba(238, 238, 238, 1)"}
                fontSize={"xs"}
                mr={"8px"}
              >
                Open
              </Tab>
              <Tab
                _selected={{ color: "black", bg: "yellow.400" }}
                backgroundColor={"rgba(238, 238, 238, 1)"}
                fontSize={"xs"}
              >
                Closed
              </Tab>
            </TabList>

            <TabPanels height={"69vh"} maxHeight={"78vh"} overflow={"scroll"}>
              <TabPanel paddingY={0} paddingX={3} position="relative">
                {supportTickets.map((item: SupportTicket, index: number) => (
                  <div key={index}>
                    <Card
                      onClick={() => handleClick(index)}
                      className="w-[95%] mt-6 bg-white shadow-md rounded-lg px-4 py-2"
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="mb-0">{item.title}</p>
                        </div>
                        <div>
                          <p className="mb-0">{TicketStatus(item.status)}</p>
                          <p className="mb-0">
                            {fns(new Date(item.createdAt!), {
                              addSuffix: false,
                            })}
                          </p>
                        </div>
                      </div>
                      <p className="mb-0">
                        {item.description.length < 30
                          ? item.description
                          : item.description.substring(0, 29) + "..."}
                      </p>
                    </Card>
                  </div>
                ))}
              </TabPanel>
              <TabPanel paddingY={0} paddingX={3} position="relative">
                {supportTickets.map((item: SupportTicket, index: number) => (
                  <div key={index}>
                    {item.status === 1 && (
                      <>
                        <Card
                          onClick={() => handleClick(index)}
                          className="w-[95%] mt-2 bg-white shadow-md rounded-lg px-4 py-2"
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="mb-0">{item.title}</p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {TicketStatus(item.status)}
                              </p>
                              <p className="mb-0">
                                {fns(new Date(item.createdAt!), {
                                  addSuffix: false,
                                })}
                              </p>
                            </div>
                          </div>
                          <p className="mb-0">
                            {item.description.length < 30
                              ? item.description
                              : item.description.substring(0, 29) + "..."}
                          </p>
                        </Card>
                      </>
                    )}
                  </div>
                ))}
              </TabPanel>
              <TabPanel paddingY={0} paddingX={3} position="relative">
                {supportTickets.map((item: SupportTicket, index: number) => (
                  <div key={index}>
                    {item.status === 2 && (
                      <>
                        <Card
                          onClick={() => handleClick(index)}
                          className="w-[95%] mt-6 bg-white shadow-md rounded-lg px-4 py-2"
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="mb-0">{item.title}</p>
                            </div>
                            <div>
                              <p className="mb-0">
                                {TicketStatus(item.status)}
                              </p>
                              <p className="mb-0">
                                {fns(new Date(item.createdAt!), {
                                  addSuffix: false,
                                })}
                              </p>
                            </div>
                          </div>
                          <p className="mb-0">
                            {item.description.length < 30
                              ? item.description
                              : item.description.substring(0, 29) + "..."}
                          </p>
                        </Card>
                      </>
                    )}
                  </div>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>

          <div ref={bottomboundaryRef} className="w-100">
            {/* if scrolled here then load and change set current page to +1  */}
          </div>
          {isLoading && (
            <div className="w-100 flex justify-center">
              <ClipLoader />
            </div>
          )}
        </>
      )}

      {/* chat view page */}
      {selectedTicket_idx !== undefined && indexes === 1 && (
        <MobileTicketView
          selectedTicket={supportTickets[selectedTicket_idx]}
          setindexes={setIndexes}
          setSupportTickets={setSupportTickets}
        />
      )}
    </div>
  );
};

export default MobileAllTickets;
