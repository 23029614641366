import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AdvertisersType, CreatedAd } from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { deleteAd } from "../../Api";
import DeleteAlert from "../Alert/DeleteAlert";
import { image_Base_Url } from "../../config";
import CreatenewAd from "../modal/CreatenewAd";

const ActiveAds = ({
  data,
  setActiveAdsdata,
  selectedAdvertiser,
}: {
  data: CreatedAd[];
  setActiveAdsdata: any;
  selectedAdvertiser: AdvertisersType | undefined;
}) => {
  const selector = useSelector((state: RootState) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAdOpen,
    onOpen: onAdonpen,
    onClose: onAdClose,
  } = useDisclosure();
  const [ad_id, setad_id] = useState("");
  const [selectedAd, setselectedAd] = useState<CreatedAd>();
  const usr = selector.user;

  const handledelete = async (id: string) => {
    try {
      if (usr) {
        const res = await deleteAd(usr.token, id);
        if (res) {
          setActiveAdsdata((prev: CreatedAd[]) =>
            prev.filter((item: CreatedAd) => item._id !== id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const confirmDelete = () => {
    handledelete(ad_id);
    onClose();
  };
  return (
    <div
      className="h-[50vh] overflow-scroll w-[98%] rounded-lg"
      style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
    >
      {data.length === 0 && (
        <>
          <div className="h-[100%] flex justify-center items-center">
            <p className="font-bold">No Active Ads </p>
          </div>
        </>
      )}
      {data.length > 0 && (
        <>
          <TableContainer
            fontFamily={"Montserrat"}
            className="py-4 dashboard_usertable"
            scrollBehavior={"smooth"}
            style={{ scrollbarWidth: "none" }}
          >
            <Table
              size="sm"
              variant={"simple"}
              background={"white"}
              borderRadius={"25px"}
            >
              <Thead className="font-bold">
                <Tr>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Ad Title
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Descirption
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Ad Platforms
                  </Th>

                  <Th textTransform={"none"} color={"black"}>
                    Target Audience
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Ad Duration
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Status
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Response
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {data &&
                  data.map((item: CreatedAd, index: number) => (
                    <Tr key={index}>
                      <Td className="flex items-center min-w-[150px] max-w-[270px] text-[13px] pl-3 pt-2 text-wrap">
                        {item.image ? (
                          <img
                            src={`${image_Base_Url}${item.image}`}
                            alt=""
                            className="h-[64px] w-[64px] rounded-md"
                          />
                        ) : (
                          <img src="/dashboardicons/courseIcon.png" alt="" />
                        )}

                        <span className="min-w-[150px] mb-0 max-w-[200px] h-[100%] text-[13px] pl-3 pt-2 text-wrap">
                          {" "}
                          {item.title}
                        </span>
                      </Td>
                      <Td className="min-w-[150px] max-w-[200px] text-[13px] pl-3 text-wrap">
                        {item.description}
                      </Td>
                      <Td className="max-w-[220px] pl-3 pt-2 text-wrap">
                        {item.ad_posting_platform}
                      </Td>
                      <Td className="pl-3">{item.audience}</Td>
                      <Td>{item.duration} days</Td>
                      <Td className="pl-3 ">
                        {item.status === 1 ? "Active" : "No-Active"}
                      </Td>
                      <Td className="">
                        <div className="flex mx-auto h-[100%] pl-3 items-start min-w-[100px]">
                          <Tooltip label="Edit" placement={"top"}>
                            <img
                              onClick={() => {
                                setselectedAd(item);
                                onAdonpen();
                              }}
                              src="/dashboardicons/editicon.png"
                              className="h-[28px] w-[28px] cursor-pointer"
                              alt=""
                            />
                          </Tooltip>
                          <Tooltip label="Delete" placement={"top"}>
                            <img
                              onClick={() => {
                                setad_id(item._id!);
                                onOpen();
                              }}
                              src="/dashboardicons/deleteicon.png"
                              alt=""
                              className="h-[28px] w-[28px] ml-2 cursor-pointer"
                            />
                          </Tooltip>
                        </div>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <CreatenewAd
        selectedAdvertiser={selectedAdvertiser}
        selectedAd={selectedAd}
        isOpen={isAdOpen}
        onClose={onAdClose}
        setselectedAd={setselectedAd}
      />
      <DeleteAlert
        isOpen={isOpen}
        onClose={onClose}
        onConfirmDelete={confirmDelete}
        label="Ad"
      />
    </div>
  );
};

export default ActiveAds;
