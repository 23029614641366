import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import {
  Address,
  DropDownProps,
  User,
  UserProfile,
  UserType,
} from "../../@types/user";
import {
  editUserAddress,
  editUserProfile,
  getDropDownList,
  searchDropDown,
} from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface Option {
  id: number;
  value?: string;
  label: string;
}
const EditUserModel = ({
  setListOfUser,
  isOpen,
  onClose,
  usersdata,
  setCompleteUserdetails,
  refresh,
  fromDashboard,
}: any) => {
  const {
    user,
    address,
    profile,
  }: { user: User; address: Address; profile: UserProfile } = usersdata;
  // console.log(usersdata);

  const [data, setData] = useState<UserProfile>({
    _id: profile?._id || "",
    name: profile?.name || "",
    date_of_birth: profile?.date_of_birth || "",
    bio: profile?.bio || "",
    gender_identity: profile?.gender_identity || "",
    racial_identity: profile?.racial_identity || "",
    sexual_orientation: profile?.sexual_orientation || "",
    who_you_are: profile?.who_you_are || "",
    denomination: profile?.denomination || "",
    faith: profile?.faith || "",
    your_company: profile?.your_company || "",
    school: profile?.school || "",
    uid: profile !== null ? profile?.uid : user?._id!,
    profile_pic: profile?.profile_pic,
    createdAt: profile?.createdAt,
    updatedAt: profile?.updatedAt,
  });

  //this is of user address details

  const [addressData, setAddressData] = useState({
    _id: address?._id,
    address: address?.address,
    city: address?.city,
    country: address?.country,
    createdAt: address?.createdAt,
    state: address?.state,
    uid: address?.uid,
    updatedAt: address?.updatedAt,
    zip_code: address?.zip_code,
  });

  // console.log(data);

  // these all options for user profile
  const [genderOptions, setGenderOptions] = useState([
    { value: "", label: "" },
  ]);
  const [raical_entity_options, setracial_entity_options] = useState([
    { value: "", label: "" },
  ]);
  const [sexual_orientation_options, setsexual_orientation_options] = useState([
    { value: "", label: "" },
  ]);
  const [faith_options, setfaith_options] = useState([
    { value: "", label: "" },
  ]);

  const who_you_are_options = [
    { value: "Student", label: "Student" },
    { value: "not know", label: "not know" },
    { value: "Other", label: "Other" },
  ];
  const church_or_demonimation_options = [
    { value: "Protestant", label: "Protestant" },
    { value: "Catholic", label: "Catholic" },
    { value: "Orthodox", label: "Orthodox" },
    { value: "Other", label: "Other" },
  ];

  const school_options = [
    { value: "School", label: "School" },
    { value: "Catholic", label: "Catholic" },
    { value: "Other", label: "Other" },
  ];

  const city_options = [
    { value: "City1", label: "City1" },
    { value: "City2", label: "City2" },
    { value: "City3", label: "City3" },
    { value: "City4", label: "City4" },
  ];
  const country_options = [
    { value: "Country1", label: "Country1" },
    { value: "Country2", label: "Country2" },
    { value: "Country3", label: "Country3" },
    { value: "Country4", label: "Country4" },
  ];
  const state_options = [
    { value: "State1", label: "State1" },
    { value: "State2", label: "State2" },
    { value: "State2", label: "State2" },
    { value: "State2", label: "State2" },
  ];
  // this is for writing input
  const handleedituserOnchange = (e: any) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  // this is for selecting from options for usesr profile
  const habdleUserChange = (field: string, value: string | null) => {
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // this is for address changing events
  const handleAddressChange = (e: any) => {
    const { name, value } = e.target;
    setAddressData((prev) => ({ ...prev, [name]: value }));
  };

  // for address change from options
  const habdleAddressChange = (field: string, value: string | null) => {
    setAddressData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //foramting date for user profile

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // this is for user profile

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleUpdate = async () => {
    if (usr) {
      try {
        const usr2: UserType = usr;
        const usrdata = (await editUserProfile(
          usr2!.token,
          data
        )) as UserProfile;

        // this set is for modal
        setData(usrdata);
        // this is from userComponents in very begining because the structor is diff for two

        if (!fromDashboard) {
          setListOfUser((prev: Option[]) => {
            const updatedUser = prev?.map((item: any) =>
              item?.user?._id === user?._id!
                ? { ...item, profile: usrdata }
                : item
            );

            return updatedUser;
          });
        }

        // this is for user view
        setCompleteUserdetails((prev: any) => ({
          ...prev,
          profile: usrdata,
        }));
        if (fromDashboard) {
          refresh();
        }

        onClose();
      } catch (error) {
        console.log(error);
      }
    }
  };

  // submit button for address edit
  const handleAddressSubmit = async () => {
    if (usr) {
      try {
        const usr2: UserType = usr;
        const address = await editUserAddress(usr2!.token, addressData);
        setAddressData(address as Address);
        setCompleteUserdetails((prev: any) => ({
          ...prev,
          address: address,
        }));
        onClose();
        refresh();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchDropdownOptions = async (
    token: string,
    page: number,
    limit: number,
    type: number,

    setter: any
  ) => {
    try {
      const res = (await getDropDownList(
        token,
        page,
        limit,
        type
      )) as DropDownProps[];
      if (res) {
        setter((prevData: any) => [
          ...prevData,
          ...res.map((item) => ({
            id: item._id,
            value: item._id,
            label: item.spelling,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (usr?.token) {
      fetchDropdownOptions(usr.token, 1, 10, 2, setracial_entity_options);
      fetchDropdownOptions(usr.token, 1, 10, 1, setGenderOptions);
      fetchDropdownOptions(usr.token, 1, 10, 3, setsexual_orientation_options);
      fetchDropdownOptions(usr.token, 1, 10, 4, setfaith_options);
      fetchDropdownOptions(usr.token, 1, 10, 4, setfaith_options);
    }
  }, [usr?.token]);

  const loadOptions = (inputValue: string, type: number) =>
    new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve(fetchOptionswithSearch(inputValue, type));
      }, 1000);
    });

  const fetchOptionswithSearch = async (inputValue: string, type: number) => {
    try {
      const res = (await searchDropDown(
        usr?.token!,
        inputValue,
        type
      )) as DropDownProps[];
      return res.map((item) => ({ value: item._id, label: item.spelling }));
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  return (
    <div>
      <Modal
        size={"xl"}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addinstructor">
            <div className="w-[100%] m-auto">
              <Tabs variant="soft-rounded" fontSize={"small"}>
                <div className="w-[80%] md:w-[50%] lg:w-[50%] mx-auto">
                  <TabList>
                    <Tab
                      _selected={{ color: "black", bg: "yellow.400" }}
                      backgroundColor={"rgba(238, 238, 238, 1)"}
                      fontSize={"smaller"}
                      marginRight={"9px"}
                    >
                      Edit Profile
                    </Tab>
                    <Tab
                      _selected={{ color: "black", bg: "yellow.400" }}
                      backgroundColor={"rgba(238, 238, 238, 1)"}
                      fontSize={"smaller"}
                    >
                      Edit Address
                    </Tab>
                  </TabList>
                </div>
                <TabPanels>
                  <TabPanel paddingY={"0"}>
                    <p>
                      <label>Name *</label>
                      <Input
                        name="name"
                        value={data.name}
                        onChange={handleedituserOnchange}
                        placeholder=""
                        className="bg-white"
                      />
                    </p>
                    <p>
                      <label>Date of Birth *</label>
                      <Input
                        value={formatDate(data.date_of_birth)}
                        name="date_of_birth"
                        onChange={handleedituserOnchange}
                        type="date"
                        placeholder=""
                        className="bg-white"
                      />
                    </p>
                    <p>
                      <label>Bio *</label>
                      <Textarea
                        value={data.bio}
                        name="bio"
                        onChange={handleedituserOnchange}
                        placeholder=""
                        className="bg-white"
                      />
                    </p>
                    <p>
                      <label>Gender *</label>
                      <AsyncSelect
                        value={genderOptions.find(
                          (option) =>
                            option.label.toLowerCase() ===
                            data?.gender_identity?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange(
                            "gender_identity",
                            selectedOption!.label
                          )
                        }
                        loadOptions={(inputValue) => loadOptions(inputValue, 1)}
                        defaultOptions={genderOptions}
                      />
                    </p>
                    <p>
                      <label>Racial Identity *</label>
                      <AsyncSelect
                        value={raical_entity_options.find(
                          (option) =>
                            option.label.toLowerCase() ===
                            data.racial_identity?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange(
                            "racial_identity",
                            selectedOption!.label
                          )
                        }
                        loadOptions={(inputValue) => loadOptions(inputValue, 2)}
                        defaultOptions={raical_entity_options}
                      />
                    </p>
                    <p>
                      <label>Sexual Orientation *</label>
                      <AsyncSelect
                        value={sexual_orientation_options.find(
                          (option) =>
                            option.label.toLowerCase() ===
                            data?.sexual_orientation?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange(
                            "sexual_orientation",
                            selectedOption!.label
                          )
                        }
                        loadOptions={(inputValue) => {
                          loadOptions(inputValue, 3);
                        }}
                        defaultOptions={sexual_orientation_options}
                      />
                    </p>
                    <p>
                      <label>Select who you are *</label>

                      <AsyncSelect
                        value={who_you_are_options.find(
                          (option) =>
                            option?.value?.toLowerCase() ===
                            data?.who_you_are?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange("who_you_are", selectedOption!.label)
                        }
                        defaultOptions={who_you_are_options}
                      />
                    </p>
                    <p>
                      <label>Denomination</label>
                      <ReactSelect
                        value={church_or_demonimation_options.find(
                          (option) =>
                            option.value.toLowerCase() ===
                            data?.denomination?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange(
                            "church_or_demonimation",
                            selectedOption!.label
                          )
                        }
                        options={church_or_demonimation_options}
                      />
                    </p>

                    {/* faith */}
                    <p>
                      <label>Faith</label>
                      <AsyncSelect
                        value={faith_options.find(
                          (option) =>
                            option.label.toLowerCase() ===
                            data?.faith?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange("faith", selectedOption!.label)
                        }
                        defaultOptions={faith_options}
                        loadOptions={(inputValue) => loadOptions(inputValue, 4)}
                      />
                    </p>
                    <p>
                      <label>Your compony</label>

                      <Input
                        name="your_company"
                        value={data.your_company}
                        onChange={handleedituserOnchange}
                        placeholder=""
                        className="bg-white"
                      />
                    </p>
                    <p>
                      <label>School</label>
                      <ReactSelect
                        value={school_options.find(
                          (option) =>
                            option?.value?.toLowerCase() ===
                            data?.school?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleUserChange("school", selectedOption!.value)
                        }
                        options={school_options}
                      />
                    </p>
                    <div className="w-[47%] m-auto">
                      <Button
                        onClick={handleUpdate}
                        style={{ backgroundColor: "#686628" }}
                        className="w-[100%] text-white mt-[12px] mb-3"
                      >
                        Update
                      </Button>
                    </div>
                  </TabPanel>

                  {/* edit address */}
                  <TabPanel paddingY={"0"}>
                    <p>
                      <label>Address</label>
                      <Input
                        name="address"
                        onChange={handleAddressChange}
                        value={addressData.address}
                        className="bg-white"
                      />
                    </p>
                    <p>
                      <label>City</label>
                      <ReactSelect
                        value={city_options.find(
                          (option) =>
                            option?.value?.toLowerCase() ===
                            addressData?.city?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleAddressChange("city", selectedOption!.value)
                        }
                        options={city_options}
                      />
                    </p>
                    <p>
                      <label>State</label>
                      <ReactSelect
                        value={state_options.find(
                          (option) =>
                            option?.value?.toLowerCase() ===
                            addressData?.state?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleAddressChange("state", selectedOption!.value)
                        }
                        options={state_options}
                      />
                    </p>
                    <p>
                      <label>Country</label>
                      <ReactSelect
                        value={country_options.find(
                          (option) =>
                            option?.value?.toLowerCase() ===
                            addressData?.country?.toLowerCase()
                        )}
                        onChange={(selectedOption) =>
                          habdleAddressChange("country", selectedOption!.value)
                        }
                        options={country_options}
                      />
                    </p>
                    <p>
                      <label>Zip Code</label>
                      <Input
                        className="bg-white border-1 border-gray-800"
                        name="zip_code"
                        onChange={handleAddressChange}
                        value={addressData.zip_code}
                      />
                    </p>
                    <div className="w-[47%] m-auto">
                      <Button
                        onClick={handleAddressSubmit}
                        style={{ backgroundColor: "#686628" }}
                        className="w-[100%] text-white mt-[12px] mb-3"
                      >
                        Update
                      </Button>
                    </div>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditUserModel;
