import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import InputComponents from "./InputComponents";
import {
  CourseType,
  CreateCourseSession,
  UserType,
  courseQuestionType,
} from "../../@types/user";

import {
  addQuestionToCourse,
  deleteCourseQuestion,
  getCoursebyId,
  getListOfCourseQuestions,
  updateQuestionToCourse,
} from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DeleteAlert from "../Alert/DeleteAlert";
import { toast, ToastContainer } from "react-toastify";

interface CourseContentAndQuestionProps {
  totalSessions?: number;
  setindexes: Dispatch<SetStateAction<number>>;
  selectedCourseId: string;
  setselectedCourseId?: Dispatch<SetStateAction<string>>;
}
const Coursecontentandquestion: React.FC<CourseContentAndQuestionProps> = ({
  setindexes,
  selectedCourseId,
  setselectedCourseId,
}) => {
  const [sessionCollections, setsessionCollections] = useState<
    CreateCourseSession[]
  >([]);
  const [selectedSessionid, setselectedSessionid] = useState("");
  const [newSession, setNewSession] = useState<CreateCourseSession>({
    cid: selectedCourseId,
    session_title: "",
    session_video: null,
    session_description: "",
  });

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  // this is for fetching the course details like sessions
  useEffect(() => {
    if (usr) {
      const usr2: UserType = usr;
      const fetchData = async () => {
        try {
          const res = (await getCoursebyId(
            usr2!.token,
            selectedCourseId
          )) as CourseType;

          setsessionCollections(res.sessions);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [selectedCourseId, usr]);

  const handleAddnewSession = async () => {
    setsessionCollections((prev) => [...prev, newSession]);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();

  const [listOfQuestions, setListOfQuestions] = useState<courseQuestionType[]>(
    []
  );

  const [question, setQuestion] = useState<courseQuestionType>({
    cid: selectedCourseId,
    question: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    ans: 1,
  });
  const [selectedQuestion, setSelectedQuestion] =
    useState<courseQuestionType>();

  useEffect(() => {
    if (selectedQuestion?._id !== undefined) {
      setQuestion({
        cid: selectedCourseId,
        question: selectedQuestion.question,
        option_1: selectedQuestion.option_1,
        option_2: selectedQuestion.option_2,
        option_3: selectedQuestion.option_3,
        option_4: selectedQuestion.option_4,
        ans: selectedQuestion.ans,
        questionId: selectedQuestion._id,
      });
    }
  }, [selectedQuestion, setQuestion, selectedCourseId]);

  // for knowledge tesst questions
  const fetchCourseQuestions = async () => {
    try {
      if (usr && selectedCourseId) {
        const res = (await getListOfCourseQuestions(
          usr.token,
          selectedCourseId
        )) as courseQuestionType[];
        setListOfQuestions(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourseQuestions();
  }, []);

  const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuestion((prev) => ({ ...prev, [name]: value }));
  };
  // new quesstion
  const handleSubmit = async () => {
    try {
      const res = (await addQuestionToCourse(
        usr!.token,
        question
      )) as courseQuestionType;
      if (res) {
        setListOfQuestions((prev) => [res, ...prev]);
        toast.success("New Question added", { position: "bottom-center" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setQuestion({
        cid: selectedCourseId,
        question: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        ans: 1,
      });
    }

    onClose();
  };

  const handleUpdate = async () => {
    try {
      const res = (await updateQuestionToCourse(
        usr!.token,
        question
      )) as courseQuestionType;

      if (res) {
        setListOfQuestions((prev) =>
          prev.map((item) => (item._id === selectedQuestion?._id! ? res : item))
        );
        toast.success("Question updated", { position: "bottom-center" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setQuestion({
        cid: selectedCourseId,
        question: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        ans: 1,
      });
    }

    onClose();
  };

  // delete question in course
  const handledeleteCourseQuestion = async () => {
    try {
      if (usr && selectedQuestion !== undefined) {
        const res = await deleteCourseQuestion(
          usr.token,
          selectedQuestion._id!
        );
        if (res) {
          setListOfQuestions((prev) =>
            prev.filter((item) => item._id !== selectedQuestion._id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handledeleteCourseQuestion();
    onDeleteClose();
    setSelectedQuestion(undefined);
  };

  useEffect(() => {
    if (!isOpen) {
      setQuestion({
        cid: selectedCourseId,
        question: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        ans: 1,
      });
      setSelectedQuestion(undefined);
    }
  }, [isOpen, selectedCourseId, selectedSessionid]);
  return (
    <div className="createCourse w-[100%] h-[100%] px-3 bg-white mx-auto mb-3 rounded-2xl shadow-md">
      <div className="flex justify-between py-4 lg:px-5 md:px-4 px-3">
        <p className="font-bold">Create New Sessions</p>
        <Button
          onClick={() => {
            setindexes(0);
            setselectedCourseId?.("");
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="px-4"
        >
          Back
        </Button>
      </div>
      <div className="w-[95%] mx-auto h-[100%]">
        <Tabs variant="soft-rounded" fontSize={"small"} flex={"wrap"}>
          <TabList
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            gap="6px"
            marginX={"12px"}
          >
            <Tab
              _selected={{ color: "black", bg: "yellow.400" }}
              backgroundColor={"rgba(238, 238, 238, 1)"}
              fontSize={"small"}
            >
              <p className="text-[14px] mb-0">All Sessions</p>
            </Tab>
            <Tab
              _selected={{ color: "black", bg: "yellow.400" }}
              backgroundColor={"rgba(238, 238, 238, 1)"}
              fontSize={"small"}
            >
              Knowledge Test Questions
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div className="w-[100%] mx-auto">
                <Accordion allowToggle={true}>
                  {sessionCollections.length > 0 &&
                    sessionCollections.map((item, index) => (
                      <div key={index} className="mt-3">
                        <Card className="shadow-md">
                          <AccordionItem
                            border={"none"}
                            onClick={() => setselectedSessionid(item._id!)}
                          >
                            <h2>
                              <AccordionButton>
                                <Box as="span" flex="1" textAlign="left">
                                  <p className="flex items-center">
                                    <img
                                      src={"/dashboardicons/titleicon.png"}
                                      className="h-[16px] w-[16px] mr-2"
                                      alt=""
                                    />
                                    <p className="mb-0 pl-1 text-sm py-2">
                                      {item.session_title ? (
                                        item.session_title
                                      ) : (
                                        <>Title here</>
                                      )}
                                    </p>
                                  </p>
                                </Box>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <InputComponents
                                sessionCollections={sessionCollections}
                                setsessionCollections={setsessionCollections}
                                selectedCourseId={selectedCourseId}
                                index={index}
                                singleSession={item}
                                selectedSessionid={selectedSessionid}
                                setselectedSessionid={setselectedSessionid}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        </Card>
                      </div>
                    ))}
                </Accordion>
                <div className="w-[100%]">
                  <Button
                    className="mt-2 mx-auto"
                    onClick={handleAddnewSession}
                  >
                    Add New Session
                  </Button>
                </div>
              </div>
            </TabPanel>

            {/* for questions */}
            <TabPanel>
              <div className="flex w-[100%] justify-end">
                <Button
                  onClick={onOpen}
                  bgColor={"#3F1B5B"}
                  color={"white"}
                  _hover={{ bgColor: "#3F1B5f" }}
                  className="lg:px-5 px-3 "
                  fontSize={"11px"}
                  borderRadius={"22px"}
                >
                  <span className="hidden lg:block"> Add-</span>New-Question
                </Button>
              </div>
              <div className="max-h-[50vh] min-h-[50vh] overflow-scroll scrollbar">
                {listOfQuestions !== undefined &&
                  listOfQuestions?.map(
                    (item: courseQuestionType, index: number) => (
                      <Card key={index} className="w-[100%] p-3 mt-3 flex">
                        <div className="flex justify-between items-center">
                          <p className="text-xs ">
                            Q {index + 1}.{" "}
                            {item.question.length > 50
                              ? item.question.substring(0, 49) + "..."
                              : item.question}
                            .
                          </p>
                          <div className="h-[100%]">
                            <Popover>
                              <PopoverTrigger>
                                <img
                                  className="cursor-pointer"
                                  src={"/dashboardicons/threedots.png"}
                                  alt=""
                                />
                              </PopoverTrigger>
                              <PopoverContent
                                mr={10}
                                maxWidth={"150px"}
                                padding={"3px"}
                                borderRadius={"20px"}
                                borderTopRightRadius={"0px"}
                                zIndex={"100"}
                              >
                                <PopoverBody
                                  borderRadius={"20px"}
                                  borderTopRightRadius={"0px"}
                                >
                                  <p
                                    onClick={() => {
                                      setSelectedQuestion(item);
                                      onOpen();
                                    }}
                                    className="text-black cursor-pointer text-[15px]"
                                  >
                                    Edit
                                  </p>
                                  <p
                                    onClick={() => {
                                      setSelectedQuestion(item);
                                      onDeleteOpen();
                                    }}
                                    className="text-red-500 cursor-pointer text-[15px]"
                                  >
                                    Delete
                                  </p>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                        <DeleteAlert
                          isOpen={isDeleteOpen}
                          onClose={onDeleteClose}
                          onConfirmDelete={confirmDelete}
                          label="Question"
                        />
                      </Card>
                    )
                  )}
              </div>
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    Create New Question
                    <ModalCloseButton />
                  </ModalHeader>
                  <ModalBody>
                    <label>Enter Question</label>
                    <Input
                      placeholder="Enter here"
                      backgroundColor={"#F4F4F4"}
                      className="mt-1"
                      name="question"
                      onChange={onchange}
                      value={question.question}
                      required
                    />
                    <p className="text-sm font-bold mt-2">Add Answer Options</p>
                    <p className="text-[12px]">
                      Enter your question options here and select the correct
                      answer
                    </p>
                    <RadioGroup
                      value={question.ans.toString()}
                      onChange={(value) =>
                        setQuestion((prev) => ({
                          ...prev,
                          ans: parseInt(value),
                        }))
                      }
                      name="ans"
                      defaultValue="1"
                      className="text-[12px]"
                    >
                      <Stack>
                        {[1, 2, 3, 4].map((option) => (
                          <div key={option}>
                            <label className="font-semibold">
                              Enter Answer option {option}
                            </label>
                            <div className="flex">
                              <Radio value={String(option)} />
                              <Input
                                value={
                                  question[
                                    `option_${option}` as keyof typeof question
                                  ]
                                }
                                name={
                                  `option_${option}` as keyof typeof question
                                }
                                onChange={onchange}
                                type="text"
                                placeholder="Enter here"
                                backgroundColor={"#F4F4F4"}
                                className="mt-1 ml-2"
                                required
                              />
                            </div>
                          </div>
                        ))}
                      </Stack>
                    </RadioGroup>
                    {selectedQuestion?._id !== undefined ? (
                      <Button
                        onClick={handleUpdate}
                        style={{ backgroundColor: "#686628" }}
                        className="w-[100%] text-white mt-[12px] mb-3"
                      >
                        update
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        style={{ backgroundColor: "#686628" }}
                        className="w-[100%] text-white mt-[12px] mb-3"
                      >
                        CREATE
                      </Button>
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>
              <ToastContainer />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Coursecontentandquestion;
