import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ActiveAds from "./ActiveAds";
import NewlyAddedAds from "./NewlyAddedAds";
import CreatenewAd from "../modal/CreatenewAd";
import { AdvertisersType, CreatedAd, UserType } from "../../@types/user";
import {
  deleteAdvertiser,
  getActiveAdsOfAdvertisers,
  getPendingAdsOfAdvertisers,
} from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DeleteAlert from "../Alert/DeleteAlert";
import CreateAdivertisers from "../modal/CreateAdivertisers";
interface AdvertiseProps {
  setindexes: any;
  selectedAdvertiser: AdvertisersType | undefined;
  setselectedAdvertiser: React.Dispatch<React.SetStateAction<any>>;
  setadvertisersList: React.Dispatch<React.SetStateAction<any>>;
}

const AdvertiserDetail: React.FC<AdvertiseProps> = ({
  setindexes,
  selectedAdvertiser,
  setselectedAdvertiser,
  setadvertisersList,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // for edit
  const {
    isOpen: isEditopen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  // for delete
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [fromDetail, setfromDetail] = useState(true);

  const [ActiveAdsdata, setActiveAdsdata] = useState<CreatedAd[]>([]);
  const [pendingAds, setPendingAds] = useState<CreatedAd[]>([]);

  const [tabindex, settabindex] = useState<number>(0);
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchActiveAds = async (token: string, advertiser_id: string) => {
    try {
      const ads = (await getActiveAdsOfAdvertisers(
        token,
        advertiser_id,
        1,
        100
      )) as CreatedAd[];
      setActiveAdsdata(ads);
    } catch (error) {
      console.error("Error fetching active ads:", error);
    }
  };

  const fetchPendingAds = async (token: string, advertiser_id: string) => {
    try {
      const ads = (await getPendingAdsOfAdvertisers(
        token,
        advertiser_id,
        1,
        10
      )) as CreatedAd[];
      setPendingAds(ads);
    } catch (error) {
      console.error("Error fetching active ads:", error);
    }
  };

  useEffect(() => {
    if (usr && selectedAdvertiser?._id !== undefined) {
      const usr2: UserType = usr;
      fetchActiveAds(usr2.token, selectedAdvertiser._id);
      fetchPendingAds(usr2.token, selectedAdvertiser._id);
    }
  }, [selectedAdvertiser, usr]);

  const handleDeleteAdvertiser = async (advertiserId: string) => {
    try {
      const res = await deleteAdvertiser(usr!.token, advertiserId);
      if (res) {
        setadvertisersList((prev: AdvertisersType[]) =>
          prev.filter((item) => item.user._id !== advertiserId)
        );
        setindexes(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDeleteAdvertiser(selectedAdvertiser?.user._id!);
    onDeleteAlertClose();
  };

  return (
    // we have userdetail class css in users component -> user.css
    <div className="userdetail w-[96%]  m-auto">
      {/*title and buttons  */}
      <div className=" bg-white pb-2  shadow-md rounded-lg">
        <div className="flex px-3 pt-3 justify-between items-center">
          <p className="title">User Detail</p>
          <div className="flex">
            <Button
              onClick={() => {
                onEditOpen();
              }}
              size={"sm"}
              bgColor={"#686628"}
              color={"white"}
              _hover={{ backgroundColor: "#686620" }}
            >
              <img
                src={"/dashboardicons/edit.png"}
                className="h-[18px] mr-2 w-[18px]"
                alt=""
              />
              Edit
            </Button>

            <Button
              onClick={() => {
                onDeleteAlertOpen();
              }}
              size={"sm"}
              bgColor={"#BF5529"}
              color={"white"}
              _hover={{ backgroundColor: "#BF5529" }}
            >
              <img
                src={"/dashboardicons/deleteicon.png"}
                className="h-[28px] w-[28px]"
                alt=""
              />
              Delete
            </Button>
            <Button
              onClick={() => {
                setselectedAdvertiser(undefined);
                setindexes(0);
              }}
              size={"sm"}
              bgColor={"#3F1B5B"}
              color={"white"}
              _hover={{ backgroundColor: "#3F1B5B" }}
            >
              Back
            </Button>
          </div>
        </div>
        <DeleteAlert
          isOpen={isDeleteAlertOpen}
          onClose={onDeleteAlertClose}
          onConfirmDelete={confirmDelete}
          label="Advertiser"
        />
        {/* details */}
        <div className="flex justify-around flex-wrap px-3">
          {/* imgae and name */}
          <div className="flex justify-center items-center">
            <img
              src={"/dashboardicons/userIcon.png"}
              className="rounded-full"
              alt=""
            />
            <p className="font-bold pl-4">{selectedAdvertiser?.name}</p>
          </div>
          {/* email */}
          <div className="singledetailitem">
            <label>Email</label>
            <p>{selectedAdvertiser?.user?.email}</p>
          </div>
          {/* Mobile No */}
          <div className="singledetailitem">
            <label>Mobile No</label>
            <p>{selectedAdvertiser?.phone}</p>
          </div>
          {/* DOB */}
          <div className="singledetailitem">
            <label>Created Date</label>
            <p>
              {selectedAdvertiser?.createdAt &&
                new Date(selectedAdvertiser.createdAt)
                  .toISOString()
                  .split("T")[0]}
            </p>
          </div>

          <div className="singledetailitem">
            <label>Status</label>
            <p>
              {selectedAdvertiser?.user?.account_status === 0
                ? "Active"
                : "Not Active"}
            </p>
          </div>
          <div className="singledetailitem">
            <label>Total Active Ads</label>
            <p>8</p>
          </div>
        </div>
      </div>
      <CreateAdivertisers
        selectedAdvertiser={selectedAdvertiser}
        setselectedAdvertiser={setselectedAdvertiser}
        setadvertisersList={setadvertisersList}
        isOpen={isEditopen}
        onClose={onEditClose}
        fromDetail={fromDetail}
      />

      {/* for active ads and Newly added ads and tjeir detaisl */}
      <div className="mt-3 lg:p-[14px] md:p-[7px] p-[4px] bg-white shadow-md rounded-lg h-[100%]">
        <Tabs
          index={tabindex}
          variant="soft-rounded"
          fontSize={"small"}
          flex={"wrap"}
        >
          <div className="flex justify-between">
            <TabList
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-start"
              gap="6px"
              marginX={"12px"}
            >
              <Tab
                onClick={() => settabindex(0)}
                _selected={{ color: "black", bg: "yellow.400" }}
                backgroundColor={"rgba(238, 238, 238, 1)"}
                fontSize={"small"}
              >
                Active ads
              </Tab>
              <Tab
                onClick={() => settabindex(1)}
                _selected={{ color: "black", bg: "yellow.400" }}
                backgroundColor={"rgba(238, 238, 238, 1)"}
                fontSize={"small"}
              >
                Newly added
              </Tab>
            </TabList>
            <div>
              <Button
                onClick={onOpen}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className="flex justify-center items-center"
              >
                <img
                  src={"/dashboardicons/addnew.png"}
                  className="h-[16px] w-[16px] mr-3"
                  alt=""
                />
                Create New Ad
              </Button>
            </div>
          </div>
          {selectedAdvertiser && (
            <CreatenewAd
              setPendingAds={setPendingAds}
              selectedAdvertiser={selectedAdvertiser}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}

          <TabPanels>
            <TabPanel>
              <ActiveAds
                data={ActiveAdsdata}
                setActiveAdsdata={setActiveAdsdata}
                selectedAdvertiser={selectedAdvertiser}
              />
            </TabPanel>
            <TabPanel>
              <NewlyAddedAds
                setActiveAdsdata={setActiveAdsdata}
                setPendingAds={setPendingAds}
                selectedAdvertiser={selectedAdvertiser}
                data={pendingAds}
                settabindex={settabindex}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default AdvertiserDetail;
