import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import UserAccessModel from "../components/modal/UserAccessModel";
import {
  blockAndunblockUsers,
  deleteSubAdmin,
  getListOfSubAdmins,
} from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { SubadminAccType } from "../@types/user";
import ReactPaginate from "react-paginate";
import DeleteAlert from "../components/Alert/DeleteAlert";

type Response = {
  totalCount: number;
  data: SubadminAccType[];
};

const Userpermission = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const [totalCount, setTotalCount] = useState(10);
  const [data, setData] = useState<SubadminAccType[]>([]);
  const [selectedid, setselectedid] = useState("");

  const [selectedSubAdmin, setselectedSubAdmin] = useState<
    SubadminAccType | undefined
  >();
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [currentPage, setCurrentpage] = useState(0);
  const itemsPerPage = 10;
  const fetchsubAdmin = async () => {
    try {
      const res = (await getListOfSubAdmins(usr?.token!, 1, 10)) as Response;
      if (res) {
        setTotalCount(res.totalCount);
        setData(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchsubAdmin();
  }, []);

  // for subadmin permissions
  // console.log(data);
  const handleBlockandUnBlock = async (Userid: string, block: boolean) => {
    if (usr) {
      try {
        const res = await blockAndunblockUsers(usr?.token!, Userid, block);
        if (res) {
          setData((prev) =>
            prev.map((item) =>
              item._id === Userid
                ? { ...item, account_status: block ? 2 : 1 }
                : item
            )
          );
        }

        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = async (selectedId: string) => {
    try {
      if (selectedid !== "") {
        const res = await deleteSubAdmin(usr?.token!, selectedId);
        if (res === 3) {
          setData((prev) => prev.filter((item) => item._id !== selectedId));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setselectedid("");
    }
  };
  const confirmDelete = () => {
    handleDelete(selectedid);
    onDeleteAlertClose();
  };

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };
  return (
    <DashboardWrapper>
      <Titlebar title="Users" />
      <div className="w-[95%] m-auto h-[100%] px-6 lg:px-10 md:px-10 rounded-2xl shadow-md bg-white">
        <p className="pt-[28px] w-[100%] text-left text-black flex items-center justify-between">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All User access
          </p>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            Create New User
          </Button>
        </p>
        <UserAccessModel
          isOpen={isOpen}
          onClose={onClose}
          setdata={setData}
          selectedSubAdmin={selectedSubAdmin}
          setselectedSubAdmin={setselectedSubAdmin}
        />
        {/*users- table */}
        {/* <EditableSelect /> */}
        <div
          className="overflow-y-auto hide-scrollbar min-h-[50vh]"
          style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
        >
          <TableContainer
            fontFamily={"Montserrat"}
            className="py-4 px-3 dashboard_usertable"
          >
            <Table
              size="sm"
              variant={"simple"}
              background={"white"}
              borderRadius={"25px"}
            >
              <Thead>
                <Tr>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Name
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Email
                  </Th>
                  <Th
                    textTransform={"none"}
                    color={"black"}
                    paddingTop={"20px"}
                    paddingBottom={"17px"}
                  >
                    Phone
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Status
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Created Date
                  </Th>
                  <Th textTransform={"none"} color={"black"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {data &&
                  data.map((item, index) => (
                    <>
                      <Tr key={index}>
                        <Td className="flex items-center w-full">
                          <p className=" pt-2 text-wrap min-w-[140px] max-w-[220px]">
                            {item.subadmin_data.name}
                          </p>
                        </Td>

                        <Td>
                          <p className="max-w-[250px] min-w-[190px] pl-1 pt-2 text-wrap">
                            {item.email}
                          </p>
                        </Td>
                        <Td>
                          <p className="max-w-[220px] min-w-[140px] pt-2 text-wrap ">
                            {item.subadmin_data.phone}
                          </p>
                        </Td>
                        <Td>
                          <p className="pt-2">{item.account_status}</p>
                        </Td>
                        <Td>
                          <p className="pt-2">
                            {
                              new Date(item?.createdAt!)
                                .toISOString()
                                .split("T")[0]
                            }
                          </p>
                        </Td>
                        <Td>
                          <div className="flex m-auto items-start gap-1 min-w-[100px]">
                            <Tooltip label="Edit" placement={"top"}>
                              <img
                                onClick={() => {
                                  onOpen();
                                  setselectedSubAdmin(item);
                                }}
                                src="/dashboardicons/editicon.png"
                                className="cursor-pointer h-[28px] w-[28px]"
                                alt=""
                              />
                            </Tooltip>
                            {item.account_status !== 1 ? (
                              <Tooltip label="Unblock" placement={"top"}>
                                <img
                                  onClick={() => {
                                    handleBlockandUnBlock(item._id, false);
                                  }}
                                  src="/dashboardicons/actionlockedicon.png"
                                  className="h-[28px] w-[28px]"
                                  alt=""
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip label="Block" placement={"top"}>
                                <img
                                  onClick={() => {
                                    handleBlockandUnBlock(item._id, true);
                                  }}
                                  src="/dashboardicons/actionunlockedicon.png"
                                  className="h-[28px] w-[28px]"
                                  alt=""
                                />
                              </Tooltip>
                            )}
                            <Tooltip label="Delete" placement={"top"}>
                              <img
                                onClick={() => {
                                  setselectedid(item._id);
                                  onDeleteAlertOpen();
                                }}
                                src="/dashboardicons/deleteicon.png"
                                alt=""
                                className="h-[28px] w-[28px]"
                              />
                            </Tooltip>
                          </div>
                        </Td>
                      </Tr>
                    </>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <DeleteAlert
            isOpen={isDeleteAlertOpen}
            onClose={onDeleteAlertClose}
            onConfirmDelete={confirmDelete}
            label="Delete Sub Admin"
          />
        </div>
        <div className="mt-3 px-2">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(totalCount / itemsPerPage)}
            previousLabel="previous"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
            marginPagesDisplayed={2}
            forcePage={currentPage}
          />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Userpermission;
