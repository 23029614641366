import {
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";

import "./userpagination.css";
import { UserType, userWithUserandProfile } from "../@types/user";
import { image_Base_Url } from "../config";
import { blockAndunblockUsers, deleteUser, searchUsers } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import DeleteAlert from "./Alert/DeleteAlert";
import HashLoader from "react-spinners/HashLoader";
import { CiSearch } from "react-icons/ci";

interface UserComponentProps {
  listofUsers: userWithUserandProfile[];
  setindexes: Dispatch<SetStateAction<any>>;
  searchKey: string;
  setSearchkey: Dispatch<SetStateAction<any>>;
  setListOfUser: Dispatch<SetStateAction<userWithUserandProfile[]>>;
  setSelectedUser: Dispatch<SetStateAction<userWithUserandProfile | undefined>>;
  loading?: boolean;
}

const UserComponent: React.FC<UserComponentProps> = ({
  listofUsers,
  setindexes,
  searchKey,
  setSearchkey,
  setListOfUser,
  setSelectedUser,
  loading,
}) => {
  // console.log(listofUsers)
  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchkey(value);
  };
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const selector = useSelector((state: RootState) => state.user);
  const [selectuserId, setselectedUserId] = useState("");
  const usr = selector.user;
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchKey.trim() !== "") {
        const usr2: UserType = usr;
        const data = await searchUsers(usr2!.token, searchKey);
        setListOfUser(data as userWithUserandProfile[]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBlockandUnBlock = async (Userid: string, block: boolean) => {
    const usr = localStorage.getItem("user");
    if (usr) {
      const usr2: UserType = JSON.parse(usr);
      try {
        await blockAndunblockUsers(usr2!.token, Userid, block);

        setListOfUser((prev) => {
          if (!prev) return prev; // Handle case when prev is undefined
          const updatedUsers = prev.map((item) => {
            if (item.user._id === Userid) {
              return {
                ...item,
                user: { ...item.user, account_status: block ? 2 : 1 },
              };
            }
            return item;
          });
          return updatedUsers;
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = async (user_id: string) => {
    try {
      if (usr && user_id) {
        const res = await deleteUser(usr.token, user_id);
        if (res) {
          setListOfUser((prev) =>
            prev.filter((item) => item.user._id !== user_id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeleteUser = () => {
    handleDelete(selectuserId);
    onDeleteAlertClose();
  };

  return (
    <div className="">
      <div className="px-3 pb-2 mt-2">
        <p className="pt-[28px] text-left text-black flex items-center justify-between">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            User List
          </p>
          <form onSubmit={handleSubmit} className="relative">
            <Input
              value={searchKey}
              onChange={handlechange}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
      </div>
      {/*users- table */}
      {loading ? (
        <div className="w-[100] h-[50vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          {" "}
          <div
            className="overflow-y-auto hide-scrollbar"
            style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
          >
            <TableContainer
              fontFamily={"Montserrat"}
              className="py-4 px-3 dashboard_usertable"
            >
              <Table
                size="sm"
                variant={"simple"}
                background={"white"}
                borderRadius={"25px"}
              >
                <Thead>
                  <Tr>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Name
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      paddingTop={"20px"}
                      paddingBottom={"17px"}
                      fontSize={"15px"}
                    >
                      Email
                    </Th>

                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Status
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Created Date
                    </Th>
                    <Th
                      textTransform={"none"}
                      color={"black"}
                      fontSize={"15px"}
                    >
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <div className="w-[70vw] h-[40vh] flex justify-center items-center">
                      <HashLoader />
                    </div>
                  ) : (
                    <>
                      {listofUsers &&
                        listofUsers.map(
                          (item: userWithUserandProfile, index: number) => (
                            <Tr key={index}>
                              <Td className="flex items-center">
                                {item.profile?.profile_pic ? (
                                  <img
                                    src={`${image_Base_Url}${item.profile.profile_pic}`}
                                    alt=""
                                    className="min-w-[50px] max-w-[50px] object-cover rounded-md h-[50px] opacity-100"
                                  />
                                ) : (
                                  ""
                                )}

                                <p
                                  className={`h-[100%] pl-2 pt-2 text-wrap  text-xs md:text-sm lg:text-sm ${
                                    item.user.account_status !== 1
                                      ? "opacity-60"
                                      : ""
                                  }`}
                                >
                                  {!item.profile?.name
                                    ? "No-name"
                                    : item.profile?.name}
                                </p>
                              </Td>

                              <Td>
                                <p
                                  className={`max-w-[280px] h-[100%] pl-1 pt-2 text-wrap text-xs md:text-sm lg:text-sm ${
                                    item.user.account_status !== 1
                                      ? "opacity-60"
                                      : ""
                                  }`}
                                >
                                  {item.user.email}
                                </p>
                              </Td>

                              <Td>
                                <p
                                  className={`pt-2 text-xs md:text-sm lg:text-sm ${
                                    item.user.account_status !== 1
                                      ? "opacity-60"
                                      : ""
                                  }`}
                                >
                                  {item.user.account_status}
                                </p>
                              </Td>
                              <Td>
                                <p
                                  className={`pt-2 text-xs md:text-sm lg:text-sm ${
                                    item.user.account_status !== 1
                                      ? "opacity-60"
                                      : ""
                                  }`}
                                >
                                  {
                                    new Date(item.user.createdAt)
                                      .toISOString()
                                      .split("T")[0]
                                  }
                                </p>
                              </Td>
                              <Td>
                                {/* actions */}
                                <div className="flex m-auto items-start gap-1 min-w-[100px]">
                                  <Tooltip label="View" placement={"top"}>
                                    <img
                                      onClick={() => {
                                        setSelectedUser(item);
                                        setindexes(1);
                                      }}
                                      src="/dashboardicons/actionicon1.png"
                                      className="cursor-pointer h-[28px] w-[28px]"
                                      alt=""
                                    />
                                  </Tooltip>
                                  {item.user.account_status !== 1 ? (
                                    <Tooltip label="Unblock" placement={"top"}>
                                      <img
                                        onClick={() => {
                                          handleBlockandUnBlock(
                                            item.user._id,
                                            false
                                          );
                                        }}
                                        src="/dashboardicons/actionlockedicon.png"
                                        className="h-[28px] w-[28px] cursor-pointer"
                                        alt=""
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip label="Block" placement={"top"}>
                                      <img
                                        onClick={() => {
                                          handleBlockandUnBlock(
                                            item.user._id,
                                            true
                                          );
                                        }}
                                        src="/dashboardicons/actionunlockedicon.png"
                                        className="h-[28px] w-[28px] cursor-pointer"
                                        alt=""
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip label="Delete" placement={"top"}>
                                    <img
                                      onClick={() => {
                                        setselectedUserId(item.user._id);
                                        onDeleteAlertOpen();
                                      }}
                                      src="/dashboardicons/deleteicon.png"
                                      alt=""
                                      className="h-[28px] w-[28px] cursor-pointer"
                                    />
                                  </Tooltip>
                                </div>
                              </Td>
                            </Tr>
                          )
                        )}
                    </>
                  )}{" "}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onConfirmDelete={confirmDeleteUser}
        label="Delete User"
      />
    </div>
  );
};

export default UserComponent;
