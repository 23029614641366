import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import AdvertisersComponent from "../components/AdvetisersComponent";
import AdvertiserDetail from "../components/Advertisers/AdvertiserDetail";
import { AdvertisersType, UserType } from "../@types/user";
import { getListOfAdvertisers } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import HashLoader from "react-spinners/HashLoader";

const Advertisers = () => {
  const [loading, setloading] = useState(false);
  const [advertisersList, setadvertisersList] = useState<AdvertisersType[]>([]);
  const [searchkey, setsearchkey] = useState("");
  const [selectedAdvertiser, setselectedAdvertiser] = useState<
    AdvertisersType | undefined
  >();
  const [indexes, setindexes] = useState(0);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  useEffect(() => {
    try {
      if (usr && searchkey.length === 0) {
        setloading(true);
        const usr2: UserType = usr;
        const fetchData = async () => {
          const advertisersList = await getListOfAdvertisers(
            usr2!.token,
            1,
            10
          );
          setadvertisersList(advertisersList as AdvertisersType[]);
        };
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [searchkey.length, usr]);

  useEffect(() => {
    setselectedAdvertiser(selectedAdvertiser);
  }, [selectedAdvertiser]);

  return (
    <DashboardWrapper>
      <Titlebar title={"Advertisers Management"} />
      {loading ? (
        <div className="w-[100%] h-[80vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          {indexes === 0 && (
            <AdvertisersComponent
              advertisersList={advertisersList}
              setadvertisersList={setadvertisersList}
              setindexes={setindexes}
              searchkey={searchkey}
              setsearchkey={setsearchkey}
              selectedAdvertiser={selectedAdvertiser}
              setselectedAdvertiser={setselectedAdvertiser}
            />
          )}
        </>
      )}

      {indexes === 1 && (
        <AdvertiserDetail
          setindexes={setindexes}
          selectedAdvertiser={selectedAdvertiser}
          setselectedAdvertiser={setselectedAdvertiser}
          setadvertisersList={setadvertisersList}
        />
      )}
    </DashboardWrapper>
  );
};

export default Advertisers;
