import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { SetStateAction, useEffect, useState } from "react";
import {
  AdvertisersType,
  UserType,
  createAdvertiser,
  getAdvertiserType,
} from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { createNewAdvertiser, updateAdvertiser } from "../../Api";

interface CreateAdivertisersProps {
  setadvertisersList: React.Dispatch<SetStateAction<AdvertisersType[]>>;
  selectedAdvertiser: AdvertisersType | undefined;
  isOpen: boolean;
  setselectedAdvertiser: React.Dispatch<
    SetStateAction<AdvertisersType | undefined>
  >;
  onClose: () => void;
  fromDetail?: boolean;
}

const CreateAdivertisers: React.FC<CreateAdivertisersProps> = ({
  setadvertisersList,
  selectedAdvertiser,
  setselectedAdvertiser,
  isOpen,
  onClose,
  fromDetail,
}) => {
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [newData, setNewData] = useState<createAdvertiser>({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (selectedAdvertiser) {
      setNewData({
        name: selectedAdvertiser.name,
        email: selectedAdvertiser.user.email,
        phone: selectedAdvertiser.phone,
      });
    }
  }, [selectedAdvertiser, isOpen]);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewData((prev: createAdvertiser) => ({ ...prev, [name]: value }));
  };

  // for creating new adverister
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (usr) {
        const usr2: UserType = usr;
        const res = (await createNewAdvertiser(
          usr2!.token,
          newData
        )) as getAdvertiserType;
        const resss: AdvertisersType = {
          _id: res.advertiser_info._id, 
          name: res.advertiser_info.name,
          phone: res.advertiser_info.phone,
          profile_pic: res.advertiser_info.profile_pic,
          createdAt: res.advertiser_info.createdAt,
          updatedAt: res.advertiser_info.updatedAt,
          user: res.user_section,
        };
        setadvertisersList((prev: AdvertisersType[]) => [resss, ...prev]);
      }
    } catch (error) {
      console.error("An error occurred while creating advertiser:", error);
    } finally {
      onClose();
    }
  };

  // for updating advertiser
  const handleUpdate = async (selectedId: string) => {
    try {
      if (usr && selectedId) {
        const usr2: UserType = usr;
        const res = (await updateAdvertiser(
          usr2!.token,
          newData,
          selectedId
        )) as AdvertisersType;

        setadvertisersList((prev: AdvertisersType[]) =>
          prev.map((item) =>
            item._id === selectedId
              ? { ...item, name: res.name, phone: res.phone }
              : item
          )
        );
      }
    } catch (error) {
      console.error("An error occurred while creating advertiser:", error);
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setNewData({
        name: "",
        email: "",
        phone: "",
      });
      if (!fromDetail) {
        setselectedAdvertiser(undefined);
      }
    }
  }, [isOpen, setselectedAdvertiser, fromDetail]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{selectedAdvertiser ? "Edit Advertisers" : "Create New Advertisers"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <form onSubmit={handleSubmit}>
            <div className="mt-3">
              <label className="text-sm font-semibold">Name</label>
              <Input
                required
                onChange={handleChange}
                className="text-sm"
                name="name"
                placeholder="Enter here"
                value={newData.name}
              />
            </div>
            <div className="mt-3">
              <label className="text-sm font-semibold">Email</label>
              <Input
                required
                onChange={handleChange}
                value={newData.email}
                name="email"
                className="text-sm"
                placeholder="Enter here"
              />
            </div>
            <div className="mt-3">
              <label className="text-sm font-semibold">Phone</label>
              <Input
                required
                value={newData.phone}
                onChange={handleChange}
                name="phone"
                className="text-sm"
                placeholder="Enter here"
              />
            </div>
            <div className="w-[100%] m-auto">
              {selectedAdvertiser !== undefined ? (
                <Button
                  onClick={() => {
                    handleUpdate(selectedAdvertiser._id!);
                  }}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3 font-[Georgia] font-normal uppercase "
                >
                  Update
                </Button>
              ) : (
                <Button
                  type="submit"
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3 font-[Georgia] font-normal uppercase"
                >
                  Create
                </Button>
              )}
            </div>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateAdivertisers;
