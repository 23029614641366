import { Button, Input, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactSelect from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { EventType, UserType } from "../../@types/user";
import { EditEvent, addNewEvent } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
import { ToastContainer, toast } from "react-toastify";
interface CreateEventProps {
  setindexes: React.Dispatch<React.SetStateAction<number>>;
  selectedEvent: EventType | undefined;
  setSelectedEvent: React.Dispatch<React.SetStateAction<EventType | undefined>>;
  setEventsList: React.Dispatch<React.SetStateAction<EventType[]>>;
}
const CreateEvent: React.FC<CreateEventProps> = ({
  selectedEvent,
  setSelectedEvent,
  setindexes,
  setEventsList,
}) => {
  const [newData, setNewData] = useState<EventType>(() => {
    if (selectedEvent?._id !== undefined) {
      return {
        image: selectedEvent.image,
        title: selectedEvent.title,
        type: selectedEvent.type,
        address: selectedEvent.address,
        date: selectedEvent.date.toString(),
        description: selectedEvent.description,
      };
    } else {
      return {
        image: null,
        title: "",
        type: "",
        address: "",
        date: new Date().toString(),
        description: "",
      };
    }
  });

  const [imageurl, setImageurl] = useState("");

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const onchange = (e: any) => {
    const { name, value } = e.target;
    setNewData((prev) => ({ ...prev, [name]: value }));
  };
  // this is for selecting options
  const handleChange = (field: string, value: string | null) => {
    setNewData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleSubmit = async () => {
    try {
      if (newData.image === null) {
        console.log("object");
        return toast.warning("Image required", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (usr) {
        const usr2: UserType = usr;
        const newEvent = (await addNewEvent(usr2!.token, newData)) as EventType;
        setEventsList((prev: EventType[]) => [newEvent, ...prev]);
      }
    } catch (error) {
      console.log(error);
    }

    setindexes(0);
  };

  const handleUpdate = async () => {
    try {
      if (usr && selectedEvent?._id !== undefined) {
        const usr2: UserType = usr;
        const editedEvent = (await EditEvent(
          usr2!.token,
          newData,
          selectedEvent!._id
        )) as EventType;
        setEventsList((prevEvents) =>
          prevEvents.map((event) =>
            event._id === selectedEvent?._id ? editedEvent : event
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedEvent(undefined);
    }

    setindexes(0);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageurl(URL.createObjectURL(file));
      setNewData((prev) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };

  const options = [{ value: "tyeps", label: "tyeps" }];
  return (
    <div className="createCourse w-[96%] bg-white mx-auto mb-3 rounded-2xl shadow-md">
      <ToastContainer />
      <div className="flex justify-between py-4 px-5">
        <p className="font-bold">Create New Events</p>

        <Button
          onClick={() => {
            setSelectedEvent(undefined);
            setindexes(0);
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="px-5"
        >
          Back
        </Button>
      </div>

      {/* new course container */}
      <div className="w-[70%] m-auto h-[100%]">
        {/* upload image */}
        <div className="text-center w-[100%] mx-auto ">
          <div className=" mx-auto  w-[100px]">
            <div className="border-3 p-1 border-[#3F1B5B] rounded-full">
              <div className="rounded-full relative bg-[#F0F0F0] ">
                {newData?.image instanceof File ? (
                  <img
                    src={
                      newData.image === null
                        ? "/dashboardicons/upload.png"
                        : imageurl
                    }
                    className="mx-auto rounded-full h-[87px] w-[100px]"
                    alt={
                      newData.image === null ? "Upload icon" : " event picture"
                    }
                  />
                ) : (
                  <img
                    src={
                      newData?.image !== null
                        ? `${image_Base_Url}${newData?.image}`
                        : "/dashboardicons/upload.png"
                    }
                    className="mx-auto rounded-full h-[87px] w-[100px]"
                    alt={newData?.title || "Default profile picture"}
                  />
                )}
              </div>
            </div>

            <label htmlFor="fileInput" className="">
              <img
                src="/dashboardicons/uploadicon.png"
                alt=""
                className="absolute  mt-[-42px] cursor-pointer "
                style={{ width: "38px", height: "38px" }}
              />
            </label>
          </div>
          <input
            id="fileInput"
            // multiple
            name="image"
            accept="image/*"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </div>

        {/* selecting and wiritng component */}
        <div>
          {/* title and catgeory */}
          <div className="createCourserow">
            <div className=" lg:w-[47%]">
              <label>Event Title</label>
              <Input
                value={newData.title}
                onChange={onchange}
                name="title"
                placeholder="Enter here"
              />
            </div>
            <div className="lg:w-[47%] pt-2 ">
              <label>Event Type</label>
              <ReactSelect
                value={options.find((option) => option.value === newData.type)}
                onChange={(selectedOption) =>
                  handleChange("type", selectedOption!.value)
                }
                name="type"
                options={options}
                className="w-[100%] color-black"
              />
            </div>
          </div>
          {/* total duration and language */}
          <div className="createCourserow">
            <div>
              <label>Address</label>
              <Input
                value={newData.address}
                onChange={onchange}
                name="address"
                placeholder="Enter here"
              />
            </div>
            <div>
              <label>Event date</label>
              <Input
                onChange={onchange}
                value={formatDate(newData.date)}
                name="date"
                type="date"
              />
            </div>
          </div>

          {/* descrpiton */}

          <div className="mt-[7px]">
            <label className="text-sm ml-4">Description</label>
            <Textarea
              value={newData.description}
              onChange={onchange}
              name="description"
              className="w-[100%] mt-[10px]"
              placeholder="Enter here"
            />
          </div>

          <div className="w-[47%] m-auto">
            {selectedEvent === undefined ? (
              <Button
                onClick={handleSubmit}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                SAVE
              </Button>
            ) : (
              <Button
                onClick={handleUpdate}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                Update
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
