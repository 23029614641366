import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React, { useState } from "react";
import ContentComponent from "./ContentComponent";
import QuestionComponent from "./QuestionComponent";
import { CreateCourseSession } from "../../@types/user";
interface InputComponentsProps {
  sessionCollections: CreateCourseSession[];
  setsessionCollections: React.Dispatch<
    React.SetStateAction<CreateCourseSession[]>
  >;
  selectedCourseId: string;
  index: number;
  singleSession: CreateCourseSession;
  selectedSessionid: string;
  setselectedSessionid: React.Dispatch<React.SetStateAction<string>>;
}

const InputComponents: React.FC<InputComponentsProps> = ({
  sessionCollections,
  setsessionCollections,
  selectedCourseId,
  index,
  singleSession,
  selectedSessionid,
  setselectedSessionid,
}) => {
  const [tabindex, settabindex] = useState<number>(0);
  return (
    <div>
      <div className="w-[95%] mx-auto ml-2">
        <Tabs
          index={tabindex}
          variant="soft-rounded"
          fontSize={"small"}
          flex={"wrap"}
        >
          <TabList>
            <Tab
              _selected={{ color: "black", bg: "yellow.400" }}
              backgroundColor={"rgba(238, 238, 238, 1)"}
              fontSize={"small"}
              onClick={() => {
                settabindex(0);
              }}
            >
              Course Content
            </Tab>
            <Tab
              _selected={{ color: "black", bg: "yellow.400" }}
              backgroundColor={"rgba(238, 238, 238, 1)"}
              fontSize={"small"}
              marginLeft={"3px"}
              onClick={() => {
                settabindex(1);
              }}
            >
              Questions
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel paddingX={"0"}>
              <ContentComponent
                sessionCollections={sessionCollections}
                setsessionCollections={setsessionCollections}
                index={index}
                selectedCourseId={selectedCourseId}
                settabindex={settabindex}
                selectedSessionid={selectedSessionid}
                setselectedSessionid={setselectedSessionid}
                singleSession={singleSession}
              />
            </TabPanel>
            <TabPanel paddingX={"0"}>
              {selectedCourseId && selectedSessionid && (
                <QuestionComponent
                  selectedSessionid={selectedSessionid}
                  selectedCourseId={selectedCourseId}
                />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default InputComponents;
