import React, { useState } from "react";
import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import EventAttendees from "./modal/EventAttendees";
import { EventType, UserType } from "../@types/user";
import { deleteEvent, searchEvent } from "../Api";
import { image_Base_Url } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import DeleteAlert from "./Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface EventComponentProps {
  EventsList: EventType[];
  setindexes: React.Dispatch<React.SetStateAction<number>>;
  searchkey: string;
  setsearchkey: React.Dispatch<React.SetStateAction<string>>;

  setEventsList: React.Dispatch<React.SetStateAction<EventType[]>>;
  setSelectedEvent: React.Dispatch<React.SetStateAction<EventType | undefined>>;
}

const EventComponent: React.FC<EventComponentProps> = ({
  setindexes,
  EventsList,
  searchkey,
  setsearchkey,
  setEventsList,
  setSelectedEvent,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [selectedEventId, setselectedEventId] = useState<string | undefined>(
    ""
  );
  // console.log(EventsList);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  // this is for search
  const handlesubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (usr && searchkey.trim() !== "") {
        try {
          const usr2: UserType = usr;
          const data = (await searchEvent(
            usr2!.token,
            searchkey
          )) as EventType[];
          setEventsList(data);
        } catch (parseError) {
          console.error("Error parsing user data:", parseError);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // delete event
  const handleDelete = async (eventId: string) => {
    if (eventId && usr) {
      try {
        const usr2: UserType = usr;
        const res = await deleteEvent(usr2!.token, eventId);
        console.log(res);
        if (res) {
          setEventsList((prev: EventType[]) =>
            prev.filter((item) => item._id !== eventId)
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const confirmDelete = async () => {
    await handleDelete(selectedEventId!);
    onDeleteAlertClose();
  };

  return (
    <div className="">
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Events
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchkey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={() => {
              setindexes(1);
            }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center mx-3"
          >
            <img
              src={"/dashboardicons/announcementicon.png"}
              className="h-[16px] w-[16px] lg:mr-3 md:mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Create New Event
            </p>
          </Button>
        </p>
      </div>

      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="font-bold">
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Event Title
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Event Type
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Address
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Total Attenders
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Created Date
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {EventsList.length > 0 &&
                EventsList.map((item: EventType, index: number) => (
                  <Tr key={index}>
                    <Td className="flex items-center">
                      <img
                        src={`${image_Base_Url}${item.image}`}
                        alt=""
                        className="w-[50px] h-[50px] rounded-md"
                      />
                      <span className=" min-w-[150px] mb-0 max-w-[200px] text-[13px] pl-3 text-wrap">
                        {" "}
                        {item.title}
                      </span>
                    </Td>
                    <Td className="min-w-[100px] mb-0 max-w-[200px] text-[13px] pl-3 pt-2 text-wrap">
                      {item.type}
                    </Td>
                    <Td>
                      <p className="max-w-[220px] min-w-[160px] mb-0 pl-3 text-[13px] text-wrap">
                        {item.address}
                      </p>
                    </Td>
                    <Td className="pl-3">
                      {item.attendersCount ? item.attendersCount : "0"}
                      {"   "}people
                    </Td>
                    <Td>
                      <p className=" text-[13px] mb-0">
                        {formatDate(item.createdAt!)}
                      </p>
                    </Td>
                    <Td>
                      <div className="flex m-auto items-start gap-1 min-w-[100px]">
                        <Tooltip label="Edit" placement={"top"}>
                          <img
                            onClick={() => {
                              setSelectedEvent(item);
                              setindexes(1);
                            }}
                            src="/dashboardicons/editicon.png"
                            className="h-[28px] w-[28px] cursor-pointer"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip label="Event Attendees" placement={"top"}>
                          <img
                            onClick={() => {
                              setselectedEventId(item._id);
                              onOpen();
                            }}
                            src="/dashboardicons/newevent.png"
                            className="h-[28px] w-[28px] cursor-pointer"
                            alt=""
                          />
                        </Tooltip>
                        <Tooltip label="Delete" placement={"top"}>
                          <img
                            onClick={() => {
                              setselectedEventId(item._id!);
                              onDeleteAlertOpen();
                            }}
                            src="/dashboardicons/deleteicon.png"
                            alt=""
                            className="h-[28px] w-[28px] cursor-pointer"
                          />
                        </Tooltip>
                      </div>
                    </Td>
                  </Tr>
                ))}
              <DeleteAlert
                isOpen={isDeleteAlertOpen}
                onClose={onDeleteAlertClose}
                onConfirmDelete={confirmDelete}
                label="Delete Event"
              />
              {selectedEventId !== "" && (
                <EventAttendees
                  isOpen={isOpen}
                  onClose={onClose}
                  selectedEventId={selectedEventId}
                  setselectedEventId={setselectedEventId}
                />
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default EventComponent;
