import "./Dashboard.css";
import Titlebar from "../components/titlebarComponent/Titlebar";
import DashboardComponent from "../components/dashboarComponentd";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import { useEffect, useState } from "react";
import UserView from "../components/Users/UserView";
import HashLoader from "react-spinners/HashLoader";
import { CourseType, Data, userWithUserandProfile } from "../@types/user";
import { AdminDashboardData } from "../Api";
import CreateNewCourseComponent from "../components/CoursesComponents/CreateNewCourseComponent";
import Coursecontentandquestion from "../components/CoursesComponents/Coursecontentandquestion";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setSubAdmin } from "../redux/userSlice";

const Dashboard = () => {
  // total data in dashboard
  const [sampledata, setsampledata] = useState<Data>();
  const [loading, setloading] = useState(false);

  //selected users index
  const [selectedUser, setselectedUser] = useState<
    userWithUserandProfile | undefined
  >();

  // this is for courses
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedCourse, setselectedCourse] = useState<CourseType>();

  // for check whether it is coming from dashboard or from the courses
  const [fromDashboard, setfromDashboard] = useState(true);
  const [totalSessions, setTotalSessions] = useState(3);

  const dispatch = useDispatch();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const fetchdata = async () => {
    const data = (await AdminDashboardData(usr!.token)) as Data;
    dispatch(setSubAdmin(data.subAdmin ?? undefined));
    setsampledata(data as Data);
  };

  // fetching the data
  useEffect(() => {
    try {
      if (usr) {
        setloading(true);
        fetchdata();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [usr]);

  const [indexes, setindexes] = useState(0);

  return (
    <>
      <DashboardWrapper>
        <Titlebar title={"Dashboard"} />
        {loading ? (
          <div className="w-[100] h-[80vh] flex justify-center items-center">
            <HashLoader />
          </div>
        ) : (
          <>
            {indexes === 0 && sampledata !== undefined && (
              <DashboardComponent
                setindexes={setindexes}
                setselectedUser={setselectedUser}
                selectedCourseId={selectedCourseId}
                setSelectedCourseId={setSelectedCourseId}
                setselectedCourse={setselectedCourse}
                sampledata={sampledata}
                setsampledata={setsampledata}
                refresh={fetchdata}
              />
            )}
            {indexes === 1 && sampledata !== undefined && (
              <UserView
                fromDashboard={fromDashboard}
                setindexes={setindexes}
                selectedUser={selectedUser}
                refresh={fetchdata}
              />
            )}
            {/* courses which are in dashboard */}
            {indexes === 2 && selectedCourseId && selectedCourse && (
              <CreateNewCourseComponent
                selectedCourseId={selectedCourseId}
                setselectedCourseId={setSelectedCourseId}
                fromDashboard={fromDashboard}
                setindexes={setindexes}
                setTotalSessions={setTotalSessions}
                selectedCourse={selectedCourse}
                refresh={fetchdata}
              />
            )}
            {indexes === 3 && (
              <Coursecontentandquestion
                totalSessions={totalSessions}
                selectedCourseId={selectedCourseId}
                setindexes={setindexes}
              />
            )}
          </>
        )}
      </DashboardWrapper>
    </>
  );
};

export default Dashboard;
