import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Instructor } from "../@types/user";
import { deleteInstructor, searchInstructor } from "../Api";
import { image_Base_Url } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import DeleteAlert from "./Alert/DeleteAlert";
import HashLoader from "react-spinners/HashLoader";
import { CiSearch } from "react-icons/ci";

interface Props {
  setindexes: Dispatch<SetStateAction<number>>;
  instructordata: Instructor[];
  setinstructordata: Dispatch<SetStateAction<Instructor[]>>;
  setsearchkey: Dispatch<SetStateAction<string>>;
  setselectedInstructor: Dispatch<SetStateAction<Instructor | undefined>>;
  searchkey: string;
  setselectedindex: Dispatch<SetStateAction<number>>;
  loading: boolean;
}

const InstuctorComponent: React.FC<Props> = ({
  setindexes,
  instructordata,
  setinstructordata,
  setsearchkey,
  searchkey,
  setselectedInstructor,
  setselectedindex,
  loading,
}) => {
  const selector = useSelector((state: RootState) => state.user);
  const [instructorid, setinstructor] = useState("");
  const [courseCount, setCourseCount] = useState(0);
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  const toast = useToast();
  const usr = selector.user;
  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchkey.trim() !== "") {
        const searchdata = await searchInstructor(usr.token, searchkey);
        setinstructordata(searchdata as Instructor[]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteoneInstructor = async (id: string, courseCount: number) => {
    try {
      if (courseCount > 0) {
        return toast({
          title: "Instructor can't be Deleted",
          description: "This Instructor is assigned to a Course",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      if (usr && id) {
        const res = await deleteInstructor(usr.token, id);
        if (res) {
          setinstructordata((prev: Instructor[]) =>
            prev.filter((item) => item._id !== id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    deleteoneInstructor(instructorid, courseCount);
    onDeleteAlertClose();
  };

  return (
    <div className="">
      {/* dashboard Insturctor */}
      <div className="flex items-center px-3 justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Instructor List
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchkey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
             <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={() => {
              setindexes(1);
            }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/newinstructorpng.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Add New Instrucotr
            </p>
          </Button>
        </p>
      </div>
      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer fontFamily={"Montserrat"} className="py-4 px-3  ">
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
            className="max-h-[50vh]"
          >
            <Thead>
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  maxWidth={"120px"}
                  paddingBottom={"17px"}
                >
                  Instructor Name
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Bio
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Email
                </Th>
                {/* <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Mono
                </Th> */}
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Course Created
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Created Date
                </Th>

                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody className="overflow-scroll ">
              {loading ? (
                <div className="w-[70vw] h-[40vh] flex justify-center items-center">
                  <HashLoader />
                </div>
              ) : (
                <>
                  {instructordata &&
                    instructordata.map((item: Instructor, index: number) => (
                      <>
                        <Tr key={index}>
                          <Td className="flex items-center  h-[100%]">
                            {item.profile_pic !== null ? (
                              <img
                                src={`${image_Base_Url}${item.profile_pic}`}
                                alt=""
                                className="w-[50px] h-[50px] rounded-md"
                              />
                            ) : (
                              ""
                            )}

                            <p className="min-w-[120px] pt-2 text-wrap text-xs lg:text-sm pr-3 max-w-[220px] ml-2">
                              {item.name.slice(0, 12)}
                            </p>
                          </Td>
                          <Td>
                            <p className="max-w-[200px] min-w-[120px] mb-0 text-xs lg:text-sm  text-wrap">
                              {item.bio.length > 30
                                ? item.bio.substring(0, 29) + "..."
                                : item.bio}
                            </p>
                          </Td>
                          <Td>
                            <p className="max-w-[120px]  mb-0 text-xs lg:text-sm text-wrap">
                              {item.email !== null ? item.email : "--"}
                            </p>
                          </Td>
                          {/* <Td>
                            <p className=" text-xs  mb-0 lg:text-sm">
                              {item.mono !== null ? item.mono : "--"}
                            </p>
                          </Td> */}
                          <Td>
                            <p className=" text-xs  mb-0 lg:text-sm">
                              {item.courseCount} courses
                            </p>
                          </Td>
                          <Td>
                            <p className=" text-xs  mb-0 lg:text-sm">
                              {item.createdAt &&
                                new Date(item.createdAt)
                                  .toISOString()
                                  .split("T")[0]}
                            </p>
                          </Td>
                          <Td>
                            <div className="flex m-auto  items-start gap-1 min-w-[100px]">
                              <Tooltip label="Edit" placement={"top"}>
                                <img
                                  onClick={() => {
                                    setselectedInstructor(item);
                                    setselectedindex(index);
                                    setindexes(1);
                                  }}
                                  src="/dashboardicons/editicon.png"
                                  className="h-[28px] w-[28px] cursor-pointer"
                                  alt=""
                                />
                              </Tooltip>
                              <Tooltip label="Delete" placement={"top"}>
                                <img
                                  onClick={() => {
                                    setinstructor(item._id!);
                                    setCourseCount(item.courseCount!);
                                    onDeleteAlertOpen();
                                  }}
                                  src="/dashboardicons/deleteicon.png"
                                  alt=""
                                  className="h-[28px] w-[28px] cursor-pointer"
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      </>
                    ))}
                </>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
      <DeleteAlert
        isOpen={isDeleteAlertOpen}
        onClose={onDeleteAlertClose}
        onConfirmDelete={confirmDelete}
        label="Delete Instructor"
      />
    </div>
  );
};

export default InstuctorComponent;
