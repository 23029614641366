import { Button, Input, Textarea } from "@chakra-ui/react";
import React, { FormEvent, useState } from "react";
import { UserType, announcementTypes } from "../../@types/user";
import { addNewAnnouncement, updateAnnouncements } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface CreateAnnouncementsProps {
  setdata: React.Dispatch<React.SetStateAction<announcementTypes[]>>;
  setindexes: React.Dispatch<React.SetStateAction<number>>;
  selectedindex: number;
  selectedAnnouncement?: announcementTypes;
  setSelectedAnnouncement: React.Dispatch<
    React.SetStateAction<announcementTypes | undefined>
  >;
}

const CreateAnnouncements: React.FC<CreateAnnouncementsProps> = ({
  setdata,
  setindexes,
  selectedindex,
  selectedAnnouncement,
  setSelectedAnnouncement,
}) => {
  const [newData, setnewData] = useState(() => {
    if (selectedAnnouncement !== undefined) {
      return {
        title: selectedAnnouncement.title,
        description: selectedAnnouncement.description,
        date: selectedAnnouncement.date.toString(),
      };
    } else {
      return {
        title: "",
        date: new Date().toString(),
        description: "",
      };
    }
  });

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setnewData((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr) {
        const res = (await addNewAnnouncement(
          usr!.token,
          newData
        )) as announcementTypes;
        setnewData({ title: "", date: new Date().toString(), description: "" });
        setdata((prev: any) => [res, ...prev]);
        setindexes(0);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  // for updating announcements
  const handleUpdate = async () => {
    try {
      if (usr && selectedAnnouncement?._id !== undefined) {
        const usr2: UserType = usr;
        const res = (await updateAnnouncements(
          usr2!.token,
          newData,
          selectedAnnouncement!._id
        )) as announcementTypes;

        setdata((prev: announcementTypes[]) => {
          const newDataArray = [...prev];
          newDataArray[selectedindex] = res;
          console.log(newDataArray);
          return newDataArray;
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    setindexes(0);
  };
  return (
    <div className="addinstructor w-[96%] h-[85%] bg-white  mx-auto mb-3 rounded-2xl shadow-md">
      {/* title /header inside component */}
      <div className="flex justify-between py-4 lg:px-5 md:px-4 px-3">
        <p className="font-bold">
          {selectedAnnouncement === undefined
            ? "Create New Announcement"
            : "Edit Announcement"}
        </p>

        <Button
          onClick={() => {
            setSelectedAnnouncement(undefined);
            setindexes(0);
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="lg:px-5 md:px-4 px-3"
        >
          Back
        </Button>
      </div>

      {/* input boxes */}
      <form onSubmit={handleSubmit}>
        <div className="lg:w-[40%] md:w-[65%] w-[80%] m-auto ">
          <label>Announcement Title</label>
          <Input
            name="title"
            value={newData.title}
            onChange={onchange}
            placeholder="Enter here"
          />
          <label>Announcement Date</label>
          <Input
            onChange={onchange}
            value={formatDate(newData.date)}
            name="date"
            type="date"
            placeholder="Enter here"
          />

          <label>Description</label>
          <Textarea
            name="description"
            onChange={onchange}
            minHeight={"20vh"}
            className="w-[100%] mt-[10px]"
            placeholder="Enter here"
            value={newData.description}
          />
          {selectedAnnouncement === undefined ? (
            <Button
              type="submit"
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={handleUpdate}
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
            >
              Update
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateAnnouncements;
