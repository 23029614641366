import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaqType } from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { createFAQ, editFaq } from "../../Api";

const CreateFAQ = ({
  selectedFaq,
  setselectedFaq,
  setdata,
  isOpen,
  onClose,
}: any) => {
  const [newData, setnewData] = useState<FaqType>({
    ques: "",
    ans: "",
  });

  useEffect(() => {
    if (selectedFaq?._id !== undefined) {
      setnewData({
        ques: selectedFaq.ques,
        ans: selectedFaq.ans,
      });
    } else {
      setnewData({
        ques: "",
        ans: "",
      });
    }
  }, [selectedFaq]);
  const onchange = (e: any) => {
    const { name, value } = e.target;
    setnewData((prev: any) => ({ ...prev, [name]: value }));
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handleSubmit = async () => {
    if (usr) {
      try {
        const res = await createFAQ(usr.token, newData);
        console.log(res);
        setdata((prev: FaqType[]) => [res, ...prev]);
      } catch (error) {
        console.error(error);
      } finally {
        setnewData({ ques: "", ans: "" });
        onClose();
      }
    }
  };
  const hanldeEdit = async () => {
    if (usr && selectedFaq._id !== undefined) {
      try {
        const res = await editFaq(usr.token, newData, selectedFaq._id!);
        if (res) {
          setdata((prev: FaqType[]) =>
            prev.map((item: FaqType) =>
              item._id === selectedFaq._id ? res : item
            )
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setnewData({ ques: "", ans: "" });
        onClose();
      }
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setselectedFaq(undefined);
    }
  }, [setselectedFaq, isOpen]);

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New FAQs  </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addinstructor">
            <div>
              <label className="text-sm font-semibold">FAQ Title</label>
              <Input
                name="ques"
                onChange={onchange}
                className="text-sm"
                placeholder="Enter here"
                value={newData.ques}
              />
            </div>

            <div className="mt-3">
              <label className="text-sm font-semibold">Description</label>
              <Textarea
                name="ans"
                onChange={onchange}
                className="text-sm"
                placeholder="Enter here"
                value={newData.ans}
              />
            </div>
            <div className="w-[87%] m-auto">
              {selectedFaq?._id ? (
                <Button
                  onClick={hanldeEdit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  ADD
                </Button>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CreateFAQ;
