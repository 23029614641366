import React from "react";
import { deleteDropDown } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
export const CustomOption = ({
  data,
  setCategoryOptions,
  innerRef,
  innerProps,
  isSelected,
}: any) => {
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const deleteOption = async (optionToDelete: string) => {
    try {
      const res = await deleteDropDown(usr?.token!, [optionToDelete]);
      if (res) {
        setCategoryOptions((options: any) =>
          options.filter((item: any) => item.value !== optionToDelete)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`flex justify-between w-full ${
        isSelected ? "bg-blue-500 text-white " : ""
      } p-2`}
    >
      <span>{data.label}</span>
      {!data?.__isNew__ && (
        <div className="flex">
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              deleteOption(data.value);
            }}
            className="delete-btn"
          >
            <RiDeleteBin6Fill color="red" size={20} />
          </button>
        </div>
      )}
    </div>
  );
};
