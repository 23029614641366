import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import UserComponent from "../components/UserComponent";
import UserView from "../components/Users/UserView";
import { UserType, userWithUserandProfile } from "../@types/user";
import { ListOfAllusers } from "../Api";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
// import HashLoader from "react-spinners/HashLoader";

const User = () => {
  // pagination

  const [loading, setloading] = useState(false);
  const [indexes, setindexes] = useState(0);
  const [selectedUser, setSelectedUser] = useState<userWithUserandProfile>();
  const [searchKey, setSearchkey] = useState("");
  const totalItems = Number(localStorage.getItem("totalUsers") || 10);
  const [currentPage, setCurrentpage] = useState(0);
  const itemsPerPage = 10;
  const [listofUsers, setListOfUser] = useState<userWithUserandProfile[]>([]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchdata = async () => {
    const usr2: UserType = usr;
    if (searchKey.length === 0) {
      setloading(true);
      const data = await ListOfAllusers(
        currentPage + 1,
        itemsPerPage,
        usr2!.token
      );
      setListOfUser(data as userWithUserandProfile[]);
      setloading(false);
    }
  };
  useEffect(() => {
    try {
      if (usr) {
        fetchdata();
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentPage, searchKey, usr]);
  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };

  return (
    <DashboardWrapper>
      <Titlebar title={"Users"} />
      {
        <>
          {indexes === 0 && (
            <div className="w-[97%] m-auto h-[85vh] px-2 lg:px-10 md:px-4 rounded-2xl shadow-md bg-white">
              <UserComponent
                searchKey={searchKey}
                setSearchkey={setSearchkey}
                setindexes={setindexes}
                listofUsers={listofUsers}
                setListOfUser={setListOfUser}
                setSelectedUser={setSelectedUser}
                loading={loading}
              />
              <div className="mt-3 px-2 w-100 overflow-scroll" style={{scrollbarWidth:"none"}}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={Math.ceil(totalItems / itemsPerPage)}
                  previousLabel="prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  marginPagesDisplayed={1}
                  forcePage={currentPage}
                />
              </div>
            </div>
          )}
        </>
      }

      {listofUsers.length > 0 && indexes === 1 && (
        <UserView
          setindexes={setindexes}
          selectedUser={selectedUser}
          setListOfUser={setListOfUser}
        />
      )}
    </DashboardWrapper>
  );
};

export default User;
