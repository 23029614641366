import React, { useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import AdminComponent from "../components/AdminComponent";

const AdminProfile = () => {
  return (
    <DashboardWrapper>
      <Titlebar title="Admin Profile" />
      <AdminComponent />
    </DashboardWrapper>
  );
};

export default AdminProfile;
