import React from "react";
import Sidebar from "../SidebarComponents/Sidebar";
import AuthenticatedLayout from "./AuthenticatedLayout";
type props = {
  children: React.ReactNode;
};

const DashboardWrapper = ({ children }: props) => {
  return (
    // authentication

    <div className="DashboardContainer">
      <div className="subcontainer-1 z-10 max-h-[10vh]">
        <Sidebar />
      </div>
      <div className="subcontainer-2">
        {/* <Titlebar /> */}
        <AuthenticatedLayout>{children}</AuthenticatedLayout>
      </div>
    </div>
    // </AuthenticatedLayout>
  );
};

export default DashboardWrapper;
