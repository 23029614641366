import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import EventComponent from "../components/EventComponent";
import CreateEvent from "../components/Event/CreateEvent";
import { EventType, UserType } from "../@types/user";
import { listOfEvents } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ReactPaginate from "react-paginate";
import HashLoader from "react-spinners/HashLoader";
interface fetchResponse {
  events: EventType[];
  totalEvents: number;
}

const Events = () => {
  const [loading, setloading] = useState(false);
  const [indexes, setindexes] = useState(0);
  const [searchkey, setsearchkey] = useState("");
  const [EventsList, setEventsList] = useState<EventType[]>([]);

  const [selectedEvent, setSelectedEvent] = useState<EventType>();
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const [currentPage, setCurrentpage] = useState(0);
  const [totalInstructors, setTotalInstructors] = useState(10);
  const itemsPerPage = 10;
  // console.log("HEELLOO", process.env.FIREBASE_APIkEY);

  useEffect(() => {
    try {
      if (usr && searchkey.length === 0) {
        const fetchdata = async () => {
          setloading(true);
          const usr2: UserType = usr;
          // token , page number and items in a page
          const data = (await listOfEvents(
            usr2!.token,
            currentPage + 1,
            itemsPerPage
          )) as fetchResponse;

          const eventsArray = data.events as EventType[];
          const sortedEvents = eventsArray.sort((a, b) => {
            return (
              new Date(b.createdAt!).getTime() -
              new Date(a.createdAt!).getTime()
            );
          });
          setEventsList(sortedEvents as EventType[]);
          setTotalInstructors(data.totalEvents);
        };
        fetchdata();
      } else {
        console.log("something went wrong");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [searchkey, usr, currentPage]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };

  return (
    <DashboardWrapper>
      <Titlebar title={"Events"} />

      {loading ? (
        <div className="w-[100] h-[80vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          {indexes === 0 && (
            <div className="w-[97%] m-auto h-[85vh] px-2 lg:px-18 md:px-4 rounded-2xl shadow-md bg-white">
              <EventComponent
                searchkey={searchkey}
                setsearchkey={setsearchkey}
                EventsList={EventsList}
                setindexes={setindexes}
                setEventsList={setEventsList}
                setSelectedEvent={setSelectedEvent}
              />
              <div className="mt-3 px-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={Math.ceil(totalInstructors / itemsPerPage)}
                  previousLabel="prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  marginPagesDisplayed={2}
                  // className="mt-3"
                />
              </div>
            </div>
          )}
        </>
      )}

      {indexes === 1 && (
        <CreateEvent
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setEventsList={setEventsList}
          setindexes={setindexes}
        />
      )}
    </DashboardWrapper>
  );
};

export default Events;
