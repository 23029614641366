import { newSubAdminType, SubadminAccType } from "../@types/user";

export const conversionSubAdmin = (response: newSubAdminType):SubadminAccType => {
  return {
    _id: response.user_section._id,
    role: response.user_section.role,
    email: response.user_section.email,
    account_status: response.user_section.account_status,
    user_status: response.user_section.user_status,
    email_verified: response.user_section.email_verified,
    account_type: response.user_section.account_type,
    logout: response.user_section.logout,
    createdAt: response.user_section.createdAt,
    updatedAt: response.user_section.updatedAt,
    subadmin_data: {
      _id: response.subadmin_info._id,
      user: response.subadmin_info.user,
      name: response.subadmin_info.name,
      phone: response.subadmin_info.phone,
      accesses: response.subadmin_info.accesses,
      createdAt: response.subadmin_info.createdAt,
      updatedAt: response.subadmin_info.updatedAt,
    },
  };
};
