import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SubAdminType, UserType } from "../@types/user";

export interface userState {
  user: null | UserType;
  subAdmin: undefined | SubAdminType;
}

const initialState: userState = {
  user: null,
  subAdmin: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setSubAdmin: (state, action: PayloadAction<SubAdminType | undefined>) => {
      state.subAdmin = action.payload;
      localStorage.setItem("access", JSON.stringify(state.subAdmin));
    },
    logout: (state) => {
      state.user = null;
      state.subAdmin = undefined;
      sessionStorage.removeItem("user");
      localStorage.removeItem("user");
      sessionStorage.removeItem("access");
      localStorage.removeItem("access");
    },
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setSubAdmin } = userSlice.actions;

export default userSlice.reducer;
