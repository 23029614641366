import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactPlayer from "react-player";
import { UserType, VideoType } from "../../@types/user";
import { EditVidoes, addNewVidoes } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
import { AxiosProgressEvent } from "axios";
import toast from "react-hot-toast";

const CreateVideo = ({
  selectedVideo,
  setSelectedVideo,
  setListOfVideos,
  isOpen,
  onClose,
}: {
  selectedVideo: VideoType | undefined;
  setSelectedVideo: Dispatch<SetStateAction<VideoType | undefined>>;
  setListOfVideos: Dispatch<SetStateAction<VideoType[]>>;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [newData, setnewData] = useState<VideoType>({
    title: "",
    description: "",
    thumbnail: null,
  });
  const [video, setVideo] = useState<File | null>(null);
  const [inputKey, setInputKey] = useState<string>(Date.now().toString());
  useEffect(() => {
    if (selectedVideo && selectedVideo._id !== undefined) {
      setnewData({
        title: selectedVideo.title,
        description: selectedVideo.description,
        thumbnail: selectedVideo.thumbnail,
      });
      setVideo(selectedVideo.video!);
    } else {
      setnewData({
        title: "",
        description: "",
        thumbnail: null,
      });
      setVideo(null);
    }
  }, [selectedVideo]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const onchange = useCallback((e: any) => {
    const { name, value } = e.target;
    setnewData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handlechange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files !== null && e?.target?.files[0];
    if (files) {
      const fileType = files.type.split("/")[0];
      if (fileType === "video" && e.target.name === "video") {
        setVideo(files);
      } else if (e.target.name === "thumbnail" && fileType === "image") {
        setnewData((prev) => ({
          ...prev,
          [e.target.name]: files,
        }));
      } else {
        toast.error("Please select a valid file.", {
          position: "top-center",
        });
        return;
      }
    }
  }, []);

  const handleRemoveVideo = () => {
    setVideo(null);
    setInputKey(Date.now().toString());
  };

  const hanldeSubmit = useCallback(async () => {
    if (
      video === null ||
      newData.thumbnail === null ||
      newData.title.trim().length === 0 ||
      newData.description.trim().length === 0
    ) {
      toast.error("Please Fill all Fields");
    } else {
      if (usr) {
        const toastId = toast("Uploading...");
        try {
          const res = (await addNewVidoes(
            usr!.token,
            newData,
            video,
            (progressEvent: AxiosProgressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!
              );
              toast.loading(`${percentCompleted} % uploading completed`, {
                id: toastId,
              });
            }
          )) as VideoType;
          toast.remove(toastId);
          setnewData({
            title: "",
            description: "",
            thumbnail: null,
          });
          setVideo(null);
          setListOfVideos((prev: VideoType[]) => [res, ...prev]);
        } catch (error) {
          console.error("Error adding new instructor:", error);
        } finally {
          toast.remove();
          onClose();
        }
      }
    }
  }, [newData, setListOfVideos, usr, onClose, video]);
  const handleUpdate = async () => {
    if (
      video === null ||
      newData.thumbnail === null ||
      newData.title.trim().length === 0 ||
      newData.description.trim().length === 0
    ) {
      toast.error("Please Fill all Fields");
    } else {
      if (usr && selectedVideo?._id!) {
        const usr2: UserType = usr;
        try {
          const res = (await EditVidoes(
            usr2!.token,
            newData,
            selectedVideo._id!
          )) as VideoType;
          setListOfVideos((item: VideoType[]) =>
            item.map((eachvid) => (eachvid._id === res._id ? res : eachvid))
          );
          toast.success("Updated successfully");
        } catch (error) {
          console.error("Error adding new instructor:", error);
        } finally {
          onClose();
        }
      }
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedVideo(undefined);
      setnewData({
        title: "",
        video: null,
        description: "",
        thumbnail: null,
      });
    }
  }, [isOpen, setSelectedVideo]);

  const memoizedPlayer = useMemo(() => {
    return (
      <div className="relative mt-3">
        {video !== null ? (
          video instanceof File ? (
            <ReactPlayer
              url={URL.createObjectURL(video)}
              width={"100%"}
              height={"120px"}
              controls
            />
          ) : (
            <>
              <ReactPlayer
                url={`${image_Base_Url}${video}`}
                width={"100%"}
                height={"120px"}
                controls
                clas
              />
              <div
                className="absolute top-0 right-0 cursor-pointer"
                onClick={handleRemoveVideo}
              >
                X
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
    );
  }, [video]);

  return (
    <>
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Create New Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="addinstructor">
            <div className="flex justify-between items-center">
              <p className="font-bold text-sm">Upload Your Video</p>

              <Button
                borderRadius={"24px"}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className="flex justify-center items-center"
              >
                <label htmlFor="fileInput" className="flex m-0">
                  <img
                    src={"/dashboardicons/plus.png"}
                    className="h-[16px] w-[16px] mr-3"
                    alt=""
                  />
                  <p className="lg:text-[14px] lg:block md:block hidden m-0">
                    {video === null ? "Select Video" : "Change Video"}
                  </p>
                </label>
              </Button>

              <input
                key={inputKey}
                type="file"
                accept="video/*"
                multiple
                name="video"
                style={{ display: "none" }}
                id="fileInput"
                onChange={handlechange}
              />
            </div>
            <div>
              <div className="relative mt-3">{memoizedPlayer}</div>
            </div>

            {/* for thumbnail */}
            <div className="flex justify-end mt-3 items-center">
              <Button
                borderRadius={"24px"}
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className="flex justify-center items-center"
              >
                <label htmlFor="fileImageInput" className="flex m-0">
                  <img
                    src={"/dashboardicons/plus.png"}
                    className="h-[16px] w-[16px] mr-3"
                    alt=""
                  />
                  <p className="lg:text-[14px]  m-0 lg:m-0">
                    {newData.thumbnail === null
                      ? "Select Thumbnail"
                      : "Change Thumbnail"}
                  </p>
                </label>
              </Button>

              <input
                type="file"
                accept="image/*"
                multiple
                name="thumbnail"
                style={{ display: "none" }}
                id="fileImageInput"
                onChange={handlechange}
              />
            </div>
            {newData.thumbnail !== null ? (
              <div>
                <div className="relative mt-3 h-[68px] w-[68px]">
                  {newData.thumbnail instanceof File ? (
                    <img
                      src={URL.createObjectURL(newData.thumbnail)}
                      alt=""
                      className="object-cover rounded-lg w-full h-full"
                    />
                  ) : (
                    <img
                      src={`${image_Base_Url}${newData.thumbnail}`}
                      alt=""
                      className="object-cover rounded-lg w-full h-full"
                    />
                  )}

                  <div
                    className="absolute top-[-8px] right-[-2px] cursor-pointer"
                    // onClick={handleRemoveThumbnail}
                  >
                    X
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="mt-3">
              <label className="text-sm font-semibold">Video Title</label>
              <Input
                name="title"
                onChange={onchange}
                className="text-sm"
                placeholder="Enter here"
                value={newData.title}
              />
            </div>
            <div className="mt-3">
              <label className="text-sm font-semibold">Description</label>
              <Textarea
                name="description"
                onChange={onchange}
                className="text-sm"
                value={newData.description}
                placeholder="Enter here"
              />
            </div>
            <div className="w-[47%] m-auto">
              {selectedVideo ? (
                <Button
                  onClick={handleUpdate}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update Video
                </Button>
              ) : (
                <Button
                  onClick={hanldeSubmit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Upload Video
                </Button>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateVideo;
