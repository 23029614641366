import { Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import UserDetailProfile from "./UserDetailProfile";
import UserCoursesView from "./UserCoursesView";
import { fetchCompleteDetailsofUser } from "../../Api";
import {
  Data,
  UserType,
  userViewType,
  userWithUserandProfile,
} from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HashLoader from "react-spinners/HashLoader";

interface UserViewProps {
  setindexes: Dispatch<SetStateAction<number>>;
  fromDashboard?: boolean;
  setListOfUser?: Dispatch<SetStateAction<userWithUserandProfile[]>>;
  setsampledata?: Dispatch<SetStateAction<Data | undefined>>;
  selectedUser: userWithUserandProfile | undefined;
  refresh?: () => void;
}

const UserView: React.FC<UserViewProps> = ({
  setindexes,
  selectedUser,
  setListOfUser,
  setsampledata,
  refresh,
  fromDashboard,
}) => {
  const [Completeuserdetails, setCompleteUserdetails] =
    useState<userViewType>();
  const [loading, setloading] = useState(false);
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchData = async (usr: UserType) => {
    try {
      const data = await fetchCompleteDetailsofUser(
        selectedUser?.user._id!,
        usr!.token
      );
      setCompleteUserdetails(data as userViewType);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      if (usr) {
        setloading(true);
        fetchData(usr);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [usr]);

  return (
    <div>
      {loading ? (
        <div className="w-[100] h-[80vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          {Completeuserdetails !== undefined && (
            <UserDetailProfile
              // list of user fom user page
              setListOfUser={setListOfUser}
              setindexes={setindexes}
              user={Completeuserdetails}
              setCompleteUserdetails={setCompleteUserdetails}
              refresh={refresh}
              fromDashboard={fromDashboard}
            />
          )}
          {Completeuserdetails && (
            <UserCoursesView data={Completeuserdetails} />
          )}
        </>
      )}
    </div>
  );
};

export default UserView;
