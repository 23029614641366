import React, { Dispatch, useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import InstuctorComponent from "../components/InstructorComponent";
import AddInstructor from "../components/Instructor/AddInstructor";
import { Instructor as InstructorTypes } from "../@types/user";
import { listOfInstructors } from "../Api";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

interface response {
  instructors: InstructorTypes[];
  totalInstructors: number;
}

const Instructor = () => {
  const [indexes, setindexes] = useState(0);
  const [searchkey, setsearchkey] = useState("");
  const [totalInstructors, setTotalInstructors] = useState(0);
  const [loading, setloading] = useState(false);
  const [instructordata, setinstructordata] = useState<InstructorTypes[]>([]);

  const [selectedInstructor, setselectedInstructor] =
    useState<InstructorTypes>();
  const [selectedindex, setselectedindex] = useState(0);

  const [currentPage, setCurrentpage] = useState(0);

  const itemsPerPage = 10;
  // this is for fetching entire list of instrucotrs

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const fetchInstructor = async (
    token: string,
    page: number,
    limit: number
  ) => {
    try {
      setloading(true);
      const data = (await listOfInstructors(token, page, limit)) as response;
      const instructorsArray = data.instructors;
      setTotalInstructors(data.totalInstructors);
      const sortedInstructors = instructorsArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setinstructordata(sortedInstructors);
    } catch (error) {
      console.error("Error fetching instructors:", error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (usr && searchkey.length === 0) {
      fetchInstructor(usr!.token, currentPage + 1, itemsPerPage);
    }
  }, [currentPage, searchkey, usr]);
  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };
  return (
    <div>
      <DashboardWrapper>
        <Titlebar title={"Instructor"} />

        <>
          {indexes === 0 && (
            <div className="w-[97%] m-auto h-[85vh] px-2 lg:px-18 md:px-4 rounded-2xl shadow-md bg-white">
              <InstuctorComponent
                searchkey={searchkey}
                setsearchkey={setsearchkey}
                setindexes={setindexes}
                instructordata={instructordata}
                setinstructordata={setinstructordata}
                setselectedInstructor={setselectedInstructor}
                setselectedindex={setselectedindex}
                loading={loading}
              />
              <div className="mt-3 px-2">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={Math.ceil(totalInstructors / itemsPerPage)}
                  previousLabel="prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  marginPagesDisplayed={2}
                  // className="mt-3"
                  forcePage={currentPage}
                />
              </div>
            </div>
          )}
        </>

        {indexes === 1 && (
          <AddInstructor
            setindexes={setindexes}
            setinstructordata={setinstructordata}
            selectedInstructor={selectedInstructor}
            setselectedInstructor={setselectedInstructor}
            selectedindex={selectedindex}
          />
        )}
      </DashboardWrapper>
    </div>
  );
};

export default Instructor;
