import {
  Button,
  Card,
  CardBody,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { deleteFaq, getFaqs } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { FaqType } from "../@types/user";
import CreateFAQ from "./modal/CreateFaq";
import DeleteAlert from "./Alert/DeleteAlert";

const FAQs = () => {
  const [data, setdata] = useState<FaqType[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const [popoverOpen, setPopoverOpen] = useState<string | undefined>(undefined);
  const [selectedFaq, setselectedFaq] = useState<FaqType>();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const limit = 100;
  const page = 1;
  const fetchFaqs = async () => {
    if (usr) {
      try {
        const res = (await getFaqs(usr.token, limit, page)) as FaqType[];
        setdata(res);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    fetchFaqs();
  }, []);

  const hanldeDelete = async () => {
    try {
      if (usr && selectedFaq !== undefined) {
        const res = await deleteFaq(usr.token!, selectedFaq._id!);
        if (res === 3) {
          setdata((prev: FaqType[]) =>
            prev.filter((item) => item._id !== selectedFaq._id)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    hanldeDelete();
    onDeleteClose();
  };
  return (
    <div className="w-[100%] lg:w-[95%] md:w-[95%] m-auto h-[80vh] px-6 lg:px-4 md:px-4 bg-white shadow-md rounded-lg">
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All FAQs
          </p>
        </p>
        <p color={"black"}>
          <Button
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/announcementicon.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />

            <p
              onClick={() => onOpen()}
              className="lg:text-[14px] lg:block md:block hidden  m-0 "
            >
              ADD New FAQs
            </p>
          </Button>
          <CreateFAQ
            selectedFaq={selectedFaq}
            setselectedFaq={setselectedFaq}
            setdata={setdata}
            isOpen={isOpen}
            onClose={onClose}
          />
        </p>
      </div>
      <div className="subscritpionTable ">
        {data.length > 0 &&
          data.map((item: FaqType, index: number) => (
            <Card
              key={item._id}
              className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl"
            >
              <CardBody className=" pt-2 pb-0">
                {/* right and left */}
                <div className="flex items-center justify-between">
                  {/* left */}
                  <div className="flex">
                    <div className="">
                      <div className="flex mt-2 pb-0 items-center">
                        <p className="mb-0 pl-2 font-bold text-[13px]">
                          {item.ques}
                        </p>
                      </div>
                      <div>
                        <p className="pl-2 mt-1">{item.ans}</p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Popover
                      isOpen={popoverOpen === item._id}
                      onClose={() => setPopoverOpen(undefined)}
                    >
                      <PopoverTrigger>
                        <img
                          className="cursor-pointer"
                          src={"/dashboardicons/threedots.png"}
                          alt=""
                          onClick={() => setPopoverOpen(item._id)}
                        />
                      </PopoverTrigger>

                      <PopoverContent
                        maxWidth={"150px"}
                        padding={"3px"}
                        borderRadius={"20px"}
                        borderTopRightRadius={"0px"}
                      >
                        <PopoverBody
                          borderRadius={"20px"}
                          borderTopRightRadius={"0px"}
                        >
                          <p
                            onClick={() => {
                              setselectedFaq(item);
                              onOpen();
                            }}
                            className="text-black pt-1 cursor-pointer text-[15px]"
                          >
                            Edit
                          </p>

                          <p
                            onClick={() => {
                              setselectedFaq(item);
                              onDeleteOpen();
                            }}
                            className="text-red-500 cursor-pointer text-[15px]"
                          >
                            Delete
                          </p>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </CardBody>
              <DeleteAlert
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                onConfirmDelete={confirmDelete}
                label="Faq"
              />
            </Card>
          ))}
      </div>
    </div>
  );
};

export default FAQs;
