import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";

type ListItem = {
  label: string;
  icon: string;
  link: string;
};

type SidebarComponentProps = {
  selectedList: ListItem[];
};

const SidebarComponent: React.FC<SidebarComponentProps> = ({
  selectedList,
}) => {
  const currentPathname = window.location.pathname;

  return (
    <div className="h-[100%] w-[100%]">
      {/* background images */}
      <div className="backgroundimages">
        <div className="absolute z-100 pointer-events-none top-0">
          <img src="/sidebaricons/background1.png" alt="" />
        </div>
        <div className="absolute pointer-events-none z-100 bottom-0">
          <img src="/sidebaricons/background.png" alt="" />
        </div>
      </div>

      {/* container */}
      <div className="w-[100%] min-h-[100vh] flex flex-col bg-[#686628]">
        {/* logo */}
        <div className="sidebarlogo">
          <img src="\sidebaricons\sidebarlogo1.png" alt="" />
        </div>

        <div className="mb-8">
          {selectedList?.map((item: ListItem, index: number) => (
            <div
              key={index}
              className={`eachbutton mt-1 ${
                item.link === currentPathname ? "active" : ""
              }`}
            >
              <Link to={item.link}>
                <button
                  type="button"
                  className="text-center rounded-[18px] cursor-pointer flex items-center z-100"
                  style={{ color: "#FFFFFF" }} // Set the color of text and image
                >
                  <img src={item.icon} alt="" className="pointer-events-none" />
                  <p style={{ fontFamily: "Montserrat" }} className="pt-[15px]">
                    {item.label}
                  </p>
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarComponent;
