import { Button, Card, CardBody, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import "./Subscription.css";
const SubscriptionComponent = ({ setindexes, sampledata }: any) => {
  return (
    <div className="w-[95%] m-auto h-[100%] px-6 lg:px-10 md:px-10 rounded-2xl shadow-md bg-white">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Subscription List
          </p>
          <Input
            //   onChange={(e) => hanldeSearch(e.target.value)}
            placeholder="Search here"
            minWidth={"170px"}
            width={"20vw"}
            maxWidth={"250px"}
          />
        </p>
        <p color={"black"}>
          <Button
            onClick={() => {
              setindexes(1);
            }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addnew.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] text-[12px] lg:block md:block hidden  m-0 ">
              Add New Subscription
            </p>
          </Button>
        </p>
      </div>
      <div className="subscritpionTable ">
        {sampledata.map((item: any) => (
          <>
            <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
              <CardBody className="p-3 pt-2 pb-0">
                {/* right and left */}
                <div className="flex items-center justify-between">
                  {/* left */}
                  <div className="block lg:flex md:flex items-center">
                    {/* one */}
                    <div>
                      {/* up */}
                      <div className="pt-2 pb-0 flex items-center">
                        <p className="font-bold pb-0">
                          ${item.price}/{item.type}
                        </p>
                        <p className="bg-yellow-400 font-semibold text-center pb-0 ml-2 p-1 px-2 rounded-full">
                          1 week free trail
                        </p>
                      </div>
                      {/* down */}
                      <p className="text-gray-400 p-0">
                        then 18.99$ per month.cancel anytime
                      </p>
                    </div>
                    {/* two */}

                    <p className="lg:pl-[24px]   font-semibold text-[#5e5a64]">
                      Plan Type: {item.type}
                    </p>

                    {/* three */}
                    <p className="lg:pl-[24px] flex text-[#5e5a64]">
                      <img
                        src={"/dashboardicons/clock.png"}
                        className="opacity-70 px-2"
                        alt=""
                      />
                      {item.Date}
                    </p>
                  </div>
                  {/* right */}
                  <div>
                    <img
                      className="cursor-pointer"
                      src={"/dashboardicons/threedots.png"}
                      alt=""
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionComponent;
