import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { EventAttenders } from "../../Api";
import { image_Base_Url } from "../../config";

interface AttendeesData {
  createdAt: string;
  updatedAt: string;
  user_email: string;
  user_name: string;
  user_profile_pic: string | null;
  _id: string;
}

const EventAttendees = ({
  isOpen,
  onClose,
  selectedEventId,
  setselectedEventId,
}: any) => {
  const [data, setdata] = useState<AttendeesData[]>([]);
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  useEffect(() => {
    if (usr) {
      const fetchdata = async () => {
        const res = await EventAttenders(usr!.token, 10, 1, selectedEventId);

        setdata(res as AttendeesData[]);
      };
      fetchdata();
    }
  }, [usr, selectedEventId]);

  const handleEmailClick = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  const handleClose = () => {
    setselectedEventId("");
    onClose();
  };

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Event Attenders</ModalHeader>
        <ModalCloseButton />

        <ModalBody
          maxHeight={"70vh"}
          minHeight={"50vh"}
          scrollBehavior={"smooth"}
          className="addinstructor overflow-scroll px-3 hide-scrollbar"
          style={{ maxHeight: "calc(90vh - 160px)", scrollbarWidth: "none" }}
        >
          {data.length > 0 ? (
            data.map((item, index) => (
              <>
                <div
                  key={index}
                  className="flex my-3 items-center justify-between"
                >
                  {/* one */}
                  <div className="flex items-center">
                    <div>
                      {item.user_profile_pic !== null ? (
                        <img
                          src={`${image_Base_Url}${item.user_profile_pic}`}
                          alt=""
                          className="w-[52px] h-[52px] rounded-md"
                        />
                      ) : (
                        <img src={"/dashboardicons/usericon.png"} alt="" />
                      )}
                    </div>
                    <div className="ml-2">
                      <p className="mb-0 font-bold lg:text-sm md:text-sm text-[10px]">
                        {item.user_name}
                      </p>
                      <p
                        onClick={() => {
                          handleEmailClick(item.user_email);
                        }}
                        className="mb-0 hover:underline cursor-pointer lg:text-sm md:text-sm text-[10px]"
                      >
                        {item.user_email}
                      </p>
                    </div>
                  </div>
                  {/* two */}
                  <div className="flex items-center justify-center">
                    <img
                      src={"/dashboardicons/clock.png"}
                      className="w-[16px] h-[16px] opacity-40"
                      alt=""
                    />
                    <p className="mb-0 ml-2 lg:text-sm md:text-sm text-[10px]">
                      {item.createdAt &&
                        new Date(item.createdAt).toISOString().split("T")[0]}
                    </p>
                  </div>
                  {/* three */}
                  <div>
                    <p className="mb-0 text-[#3F1B5B] font-semibold lg:text-sm md:text-sm text-[9px]">
                      Attending
                    </p>
                  </div>
                </div>
                <p
                  className="text-xs m-0"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                  }}
                ></p>
              </>
            ))
          ) : (
            <>
              <div className="flex justify-center items-center h-[100%]">
                No Attenders Found
              </div>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EventAttendees;
