import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";

const AdvertiseReject = ({
  isOpen,
  onClose,
  setreject_reason,
  handleReject,
  ad_id,
}: {
  isOpen: boolean;
  onClose: () => void;
  setreject_reason: Dispatch<SetStateAction<string>>;
  handleReject: (ad_id: string) => {};
  ad_id: string;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="rounded-lg">
        <ModalHeader>Enter Reason for Rejection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <label>Enter here</label>
          <Textarea
            placeholder="enter here"
            onChange={(e) => setreject_reason(e.target.value)}
          />
          <Button
            onClick={() => handleReject(ad_id)}
            bgColor={"#BF5529"}
            color={"white"}
            className="w-[100%] mt-3"
          >
            REJECT
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AdvertiseReject;
