import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { CreatedJobType, JobType, UserType } from "../../@types/user";
import { EditJob, addNewJob } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";

const CreateJobs = ({
  setdata,
  isOpen,
  onClose,
  selectedJob,
  setselectedJob,
}: any) => {
  const [newData, setnewData] = useState<CreatedJobType>({
    title: "",
    job_image: null,
    category: "",
    status_detail: "",
    apply_link: "",
    description: "",
    job_doc: null,
    doc: "",
  });

  useEffect(() => {
    if (selectedJob !== undefined) {
      setnewData({
        title: selectedJob.title,
        job_image: selectedJob.image,
        category: selectedJob.category,
        status_detail: selectedJob.status_detail,
        apply_link: selectedJob.apply_link,
        description: selectedJob.description,
        job_doc: selectedJob.doc,
        doc: selectedJob.doc,
      });
    } else {
      setnewData({
        title: "",
        job_image: null,
        category: "",
        status_detail: "",
        apply_link: "",
        description: "",
        job_doc: null,
        doc: "",
      });
    }
  }, [selectedJob]);

  const [imageurl, setImageurl] = useState("");

  const onchange = (e: any) => {
    const { name, value } = e.target;

    setnewData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageurl(URL.createObjectURL(file));
      if (event.target.name === "job_doc") {
        setnewData((prev) => ({
          ...prev,
          doc: file.name.toString(),
        }));
      }
      setnewData((prev) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const handlesubmit = async () => {
    try {
      if (newData.job_image === null && newData.job_doc === null) {
        alert("image and podcast need to selected");
      } else {
        if (usr) {
          const usr2: UserType = usr;
          try {
            const res = await addNewJob(usr2!.token, newData);
            setdata((prev: JobType[]) => [res, ...prev]);
          } catch (error) {
            console.error("Error adding new instructor:", error);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  // for edit
  const handleEdit = async (jobId: string) => {
    if (newData.job_image === null && newData.job_doc === null) {
      alert("image and podcast need to selected");
    } else {
      if (usr && jobId !== undefined) {
        const usr2: UserType = usr;

        try {
          const res = await EditJob(usr2!.token, newData, jobId);
          setdata((prev: JobType[]) =>
            prev.map((item) => (item._id === selectedJob._id ? res : item))
          );
        } catch (error) {
          console.error("Error adding new instructor:", error);
        }
      }
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setselectedJob(undefined);
      setnewData({
        title: "",
        job_image: null,
        category: "",
        status_detail: "",
        apply_link: "",
        description: "",
        job_doc: null,
        doc: "",
      });
    }
  }, [isOpen, setselectedJob]);

  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedJob ? "Edit Job Details" : "Create New Job"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <div className="text-center w-[100%] mx-auto ">
            <div className=" mx-auto  w-[100px]">
              <div className="border-3 w-[100px] h-[100px] z-100 p-1 border-[#3F1B5B] rounded-full">
                <div className=" rounded-full relative bg-[#F0F0F0] ">
                  {newData?.job_image instanceof File ? (
                    <img
                      src={
                        newData.job_image === null
                          ? "/dashboardicons/upload.png"
                          : imageurl
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt={
                        newData.job_image === null
                          ? "Upload icon"
                          : "Instructor profile picture"
                      }
                    />
                  ) : (
                    <img
                      src={
                        newData?.job_image !== null
                          ? `${image_Base_Url}${newData?.job_image}`
                          : "/dashboardicons/upload.png"
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt={newData?.title || "Default profile picture"}
                    />
                  )}
                </div>
              </div>

              <label htmlFor="fileInput" className="">
                <img
                  src="/dashboardicons/uploadicon.png"
                  alt=""
                  className="absolute  mt-[-42px] cursor-pointer "
                  style={{ width: "38px", height: "38px" }}
                />
              </label>
            </div>
            <input
              id="fileInput"
              name="job_image"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Job Title</label>
            <Input
              name="title"
              onChange={onchange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.title}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Job Category</label>
            <Textarea
              name="category"
              onChange={onchange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.category}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Status Detail</label>
            <Input
              name="status_detail"
              onChange={onchange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.status_detail}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Apply link</label>
            <Input
              onChange={onchange}
              name="apply_link"
              className="text-sm"
              placeholder="Enter here"
              value={newData.apply_link}
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Description</label>
            <Textarea
              name="description"
              onChange={onchange}
              className="text-sm"
              placeholder="Enter here"
              value={newData.description}
            />
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-center">
              Upload Pdf
              <Button
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className="flex justify-center items-center text-[12px]"
                borderRadius={"20px"}
              >
                {newData.job_doc === null ? (
                  <label
                    htmlFor="pdfInput"
                    className="text-sm mt-0 font-semibold"
                  >
                    select Doc
                  </label>
                ) : (
                  <label
                    htmlFor="pdfInput"
                    className="text-sm mt-0 font-semibold"
                  >
                    Change Doc
                  </label>
                )}
              </Button>
              <input
                id="pdfInput"
                name="job_doc"
                accept=".pdf"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>

            <div className="w-[100%] h-[10vh] mt-3 bg-[#ead5fe] flex flex-col justify-center items-center">
              <img
                src={"/dashboardicons/pdficon.png"}
                className="h-[24px] w-[24px]"
                alt=""
              />
              <p className="text-black text-sm mb-0">
                <>
                  {newData?.doc?.slice(0, 12)}
                  {/* {".pdf"} */}
                </>
              </p>
            </div>
          </div>
          <div className="w-[80%] mx-auto mt-3">
            {selectedJob ? (
              <Button
                onClick={() => handleEdit(selectedJob._id!)}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handlesubmit}
                style={{ backgroundColor: "#686628" }}
                className="w-[100%] text-white mt-[12px] mb-3"
              >
                Save
              </Button>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateJobs;
