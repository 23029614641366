import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "./Subscription.css";
import { deleteAnnouncements, listOfAnnouncementswithSearch } from "../Api";
import { UserType, announcementTypes } from "../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import DeleteAlert from "./Alert/DeleteAlert";
import SearchIcon from "@mui/icons-material/Search";
import HashLoader from "react-spinners/HashLoader";
interface AnnouncementProps {
  data: announcementTypes[];
  setdata: React.Dispatch<React.SetStateAction<announcementTypes[]>>;
  setindexes: React.Dispatch<React.SetStateAction<number>>;
  searchkey: string;
  setsearchkey: React.Dispatch<React.SetStateAction<string>>;
  setselectedindex: React.Dispatch<React.SetStateAction<number>>;
  setSelectedAnnouncement: React.Dispatch<
    React.SetStateAction<announcementTypes | undefined>
  >;
  loading: boolean;
}

const AnnouncementComponent: React.FC<AnnouncementProps> = ({
  data,
  setdata,
  setindexes,
  searchkey,
  setsearchkey,
  setselectedindex,
  setSelectedAnnouncement,
  loading,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${day}`;
  };
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [popoverOpen, setPopoverOpen] = useState<string | undefined>(undefined);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  // this is only for delete
  const [selectedid, setselectedid] = useState("");

  const handlesubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (usr && searchkey.trim() !== "") {
        const usr2: UserType = usr;
        const data = (await listOfAnnouncementswithSearch(
          usr2!.token,
          searchkey
        )) as announcementTypes[];

        setdata(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (announcementid: string, onClose?: () => void) => {
    try {
      if (usr) {
        const usr2: UserType = usr;
        const res = await deleteAnnouncements(usr2!.token, announcementid);

        if (res) {
          setdata((prev) => prev.filter((item) => item._id !== announcementid));
        }
      }
      onClose?.();
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedid);
    onDeleteAlertClose();
  };

  return (
    <div className="w-[95%] m-auto max-h-[85vh] h-[80%] px-3 lg:px-10 md:px-6 rounded-2xl shadow-md bg-white">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Announcements
          </p>
          <form onSubmit={handlesubmit}>
            <div className="relative">
              <Input
                value={searchkey}
                onChange={(e) => {
                  setsearchkey(e.target.value);
                }}
                placeholder="Search here"
                paddingLeft={"15%"}
                minWidth={"170px"}
                width={"20vw"}
                maxWidth={"250px"}
              />
              {searchkey.trim().length === 0 && (
                <SearchIcon className="absolute left-2 top-1/2 right-3 transform -translate-y-1/2 text-gray-400" />
              )}
            </div>
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={() => {
              setindexes(1);
            }}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/announcementicon.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />

            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Create New Announcement
            </p>
          </Button>
        </p>
      </div>
      <div className="subscritpionTable min-h-[50vh]">
        {loading ? (
          <div className="w-[100] h-[50vh] flex justify-center items-center">
            <HashLoader />
          </div>
        ) : (
          <>
            {" "}
            {data.map((item: announcementTypes, index: number) => (
              <Card
                key={item._id}
                className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl"
              >
                <CardBody className=" pt-2 pb-0">
                  {/* right and left */}
                  <div className="flex items-center justify-between">
                    {/* left */}
                    <div className="flex">
                      <div className="">
                        <div className="flex mt-2 pb-0 items-center">
                          <p className="mb-0 pl-2 font-bold text-[13px]">
                            {item.title}
                          </p>
                          <p className="mb-0 pl-2 flex">
                            <img
                              src={"/dashboardicons/clock.png"}
                              className="opacity-70 px-2"
                              alt=""
                            />
                            {formatDate(item.date)}
                          </p>
                        </div>
                        <div>
                          <p className="pl-2 mt-1">
                            {item.description.length > 80 ? (
                              <>
                                {item.description.slice(0, 79)}
                                {"..."}
                              </>
                            ) : (
                              item.description
                            )}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Popover
                        isOpen={
                          popoverOpen === item._id && item._id !== undefined
                        }
                        onClose={() => setPopoverOpen(undefined)}
                      >
                        <PopoverTrigger>
                          <img
                            className="cursor-pointer"
                            src={"/dashboardicons/threedots.png"}
                            alt=""
                            onClick={() => setPopoverOpen(item._id)}
                          />
                        </PopoverTrigger>
                        <PopoverContent
                          mr={10}
                          maxWidth={"150px"}
                          padding={"3px"}
                          borderRadius={"20px"}
                          borderTopRightRadius={"0px"}
                        >
                          <PopoverBody
                            borderRadius={"20px"}
                            borderTopRightRadius={"0px"}
                          >
                            <p
                              onClick={() => {
                                setSelectedAnnouncement(item);
                                setselectedindex(index);
                                setindexes(1);
                              }}
                              className="text-black pt-1 cursor-pointer text-[15px]"
                            >
                              Edit
                            </p>

                            <p
                              onClick={() => {
                                setselectedid(item._id!);
                                onDeleteAlertOpen();
                              }}
                              className="text-red-500 cursor-pointer text-[15px]"
                            >
                              Delete
                            </p>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  <DeleteAlert
                    isOpen={isDeleteAlertOpen}
                    onClose={onDeleteAlertClose}
                    onConfirmDelete={confirmDelete}
                    label="Delete annonucements"
                  />
                </CardBody>
              </Card>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AnnouncementComponent;
