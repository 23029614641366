import {
  Card,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { FeedbackType } from "../../@types/user";
import { image_Base_Url } from "../../config";
interface AllfeedbackProps {
  userEmail: string | undefined;
  userName: string | undefined;
  data?: FeedbackType;
  isOpen: boolean;
  onClose: () => void;
}

const Allfeedback: React.FC<AllfeedbackProps> = ({
  userEmail,
  userName,
  data,
  isOpen,
  onClose,
}) => {
  const status_emojis = {
    1: { emoji: "😢", description: "needs improvement" },
    2: { emoji: "😄", description: "great" },
    3: { emoji: "😊", description: "good" },
    0: { emoji: "🙂", description: "okay" },
    4: { emoji: "😍", description: "Loved this app" },
  };
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Feed back detail</ModalHeader>
        <ModalCloseButton />
        {/* addinstructor make the label and input styled */}
        <ModalBody className="addinstructor">
          <div className="text-center w-[16%] mx-auto">
            {data?.profile_pic ? (
              <img
                src={`${image_Base_Url}${data.profile_pic}`}
                className="w-[80px] h-[80px] rounded-full"
                alt=""
              />
            ) : (
              <img
                src={"/dashboardicons/userIcon.png"}
                className="w-[80px] h-[80px] rounded-full"
                alt=""
              />
            )}
          </div>
          <div className="text-center">
            <p className="text-lg mt-2 font-bold mb-0">
              {userName !== undefined && userName}
            </p>
            <p className="text-xs">{userEmail !== undefined && userEmail}</p>
          </div>
          <Card bgColor={"#ede7e7"} className="bg-[#ede7e7] m-3">
            <div className="text-center">
              <div className="mt-3">
                <p className="text-4xl mb-0">
                  {
                    status_emojis[
                      data?.feedback_emoji as keyof typeof status_emojis
                    ]?.emoji
                  }
                </p>
                <p className="mb-0">
                  {
                    status_emojis[
                      data?.feedback_emoji as keyof typeof status_emojis
                    ]?.description
                  }
                </p>
              </div>
              <p className="font-semibold pt-3">{data?.feedback}</p>
              <p className="text-xs px-3">{data?.description}</p>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Allfeedback;
