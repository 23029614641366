import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import "./CreateCourses.css";
import { UserType, sessionQuestionType } from "../../@types/user";
import {
  addNewSessionQuestions,
  deletesessionQuestion,
  getListOfQuestionsinSession,
  updateSessionQuestions,
} from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import DeleteAlert from "../Alert/DeleteAlert";
import toast, { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

interface QuestionComponentProps {
  selectedSessionid: string;
  selectedCourseId: string;
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({
  selectedSessionid,
  selectedCourseId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const [listOfQuestions, setListOfQuestions] = useState<sessionQuestionType[]>(
    []
  );

  const [selectedQuestion, setselectedQuestion] =
    useState<sessionQuestionType>();
  const [question, setQuestion] = useState<sessionQuestionType>({
    cid: selectedCourseId,
    sid: selectedSessionid && selectedSessionid,
    questionId: "",
    question: "",
    option_1: "",
    option_2: "",
    option_3: "",
    option_4: "",
    ans: 1,
  });

  useEffect(() => {
    if (selectedQuestion?._id !== undefined) {
      setQuestion({
        questionId: selectedQuestion._id,
        question: selectedQuestion.question,
        option_1: selectedQuestion.option_1,
        option_2: selectedQuestion.option_2,
        option_3: selectedQuestion.option_3,
        option_4: selectedQuestion.option_4,
        ans: selectedQuestion.ans,
      });
    }
  }, [
    selectedCourseId,
    selectedQuestion,
    selectedSessionid,
    setselectedQuestion,
  ]);

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  useEffect(() => {
    if (usr) {
      const usr2: UserType = usr;
      const fetchData = async () => {
        const response = (await getListOfQuestionsinSession(
          usr2!.token,
          selectedCourseId,
          selectedSessionid
        )) as sessionQuestionType[];
        setListOfQuestions(response);
      };
      fetchData();
    }
  }, [selectedCourseId, selectedSessionid, usr]);

  const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (usr) {
        const response = (await addNewSessionQuestions(
          usr!.token,
          question
        )) as sessionQuestionType;
        setListOfQuestions((prev) => [response, ...prev]);
        setQuestion({
          cid: selectedCourseId,
          sid: selectedSessionid && selectedSessionid,
          question: "",
          option_1: "",
          option_2: "",
          option_3: "",
          option_4: "",
          ans: 1,
        });
        if (response) {
          toast.success("New Question Added");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  const handleUpdate = async () => {
    try {
      if (usr) {
        const response = (await updateSessionQuestions(
          usr!.token,
          question
        )) as sessionQuestionType;
        if (response) {
          setListOfQuestions((prev) => {
            const updatedList = prev.map((item) =>
              item._id === selectedQuestion?._id! ? response : item
            );
            return updatedList;
          });
        }
        toast.success("Question Updated", { position: "bottom-center" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setQuestion({
        cid: selectedCourseId,
        sid: selectedSessionid && selectedSessionid,
        question: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        ans: 1,
      });

      onClose();
    }
  };

  const handleDeleteQuestion = async () => {
    if (usr && selectedQuestion) {
      const res = (await deletesessionQuestion(
        usr.token,
        selectedQuestion._id!
      )) as sessionQuestionType;
      if (res) {
        setListOfQuestions((prev) =>
          prev.filter((item) => item._id !== selectedQuestion._id!)
        );
        toast.success("Question Deleted");
      }
    }
    try {
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDeleteQuestion();
    onDeleteClose();
    setselectedQuestion(undefined);
  };

  useEffect(() => {
    if (!isOpen) {
      setQuestion({
        cid: selectedCourseId,
        sid: selectedSessionid && selectedSessionid,
        question: "",
        option_1: "",
        option_2: "",
        option_3: "",
        option_4: "",
        ans: 1,
      });
      setselectedQuestion(undefined);
    }
  }, [isOpen, selectedCourseId, selectedSessionid]);

  return (
    <div>
      <Toaster />
      <ToastContainer />
      <div className="flex justify-between items-center">
        <p className="mb-1 font-semibold">Test Questions</p>
        <Button
          onClick={onOpen}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="lg:px-5 px-3 "
          fontSize={"11px"}
          borderRadius={"22px"}
        >
          <span className="hidden lg:block"> Add-</span>New-Question
        </Button>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Create New Question
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <label>Enter Question</label>
              <Input
                placeholder="Enter here"
                backgroundColor={"#F4F4F4"}
                className="mt-1"
                name="question"
                onChange={onchange}
                value={question.question}
                required
              />
              <p className="text-sm font-bold mt-2">Add Answer Options</p>
              <p className="text-[12px]">
                Enter your question options here and select the correct answer
              </p>
              <RadioGroup
                onChange={(value) =>
                  setQuestion((prev) => ({ ...prev, ans: parseInt(value) }))
                }
                name="ans"
                defaultValue="1"
                className="text-[12px]"
                value={question.ans.toString()}
              >
                <Stack>
                  {[1, 2, 3, 4].map((option) => (
                    <div key={option}>
                      <label className="font-semibold">
                        Enter Answer option {option}
                      </label>
                      <div className="flex">
                        <Radio value={String(option)} />

                        <Input
                          value={
                            question[
                              `option_${option}` as keyof typeof question
                            ]
                          }
                          name={`option_${option}` as keyof typeof question}
                          onChange={onchange}
                          type="text"
                          placeholder="Enter here"
                          backgroundColor={"#F4F4F4"}
                          className="mt-1 ml-2"
                          required
                        />
                      </div>
                    </div>
                  ))}
                </Stack>
              </RadioGroup>
              {selectedQuestion?._id !== undefined ? (
                <Button
                  onClick={handleUpdate}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  Update
                </Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  style={{ backgroundColor: "#686628" }}
                  className="w-[100%] text-white mt-[12px] mb-3"
                >
                  CREATE
                </Button>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
      <div className="max-h-[45vh] min-h-[20vh] overflow-scroll scrollbar">
        {listOfQuestions !== undefined && listOfQuestions.length === 0 ? (
          <div className="w-[100%] h-[75px] flex justify-center items-center">
            No Questions Added
          </div>
        ) : (
          listOfQuestions?.map((item: sessionQuestionType, index: number) => (
            <Card key={index} className="w-[100%] p-3 mt-2 flex">
              <div className="flex justify-between items-center">
                <p className="text-xs ">
                  Q {index + 1}.{" "}
                  {item.question.length > 50
                    ? item.question.substring(0, 50) + "...."
                    : item.question}
                  .
                </p>
                <div className="h-[100%]">
                  <Popover>
                    <PopoverTrigger>
                      <img
                        className="cursor-pointer"
                        src={"/dashboardicons/threedots.png"}
                        alt=""
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      mr={10}
                      maxWidth={"150px"}
                      padding={"3px"}
                      borderRadius={"20px"}
                      borderTopRightRadius={"0px"}
                      zIndex={"100"}
                    >
                      <PopoverBody
                        borderRadius={"20px"}
                        borderTopRightRadius={"0px"}
                      >
                        <p
                          onClick={() => {
                            setselectedQuestion(item);
                            onOpen();
                          }}
                          className="text-black cursor-pointer text-[15px]"
                        >
                          Edit
                        </p>
                        <p
                          onClick={() => {
                            setselectedQuestion(item);
                            onDeleteOpen();
                          }}
                          className="text-red-500 cursor-pointer text-[15px]"
                        >
                          Delete
                        </p>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
              <DeleteAlert
                isOpen={isDeleteOpen}
                onClose={onDeleteClose}
                onConfirmDelete={confirmDelete}
                label="Question"
              />
            </Card>
          ))
        )}
      </div>
    </div>
  );
};

export default QuestionComponent;
