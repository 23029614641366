import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Subscription.css";
import CreateJobs from "../modal/CreateJobs";
import { JobType, UserType } from "../../@types/user";
import { SearchResources, deleteJob, getListofJobs } from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ReactPaginate from "react-paginate";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "../Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";
interface response {
  jobs: JobType[];
}

const Jobs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [popoverOpen, setPopoverOpen] = useState<string | undefined>(undefined); // Track which popover is open
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [loading, setloading] = useState(false);
  const [data, setdata] = useState<JobType[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobType>();
  const [searchKey, setsearchkey] = useState("");
  const [totalJobs, setTotalJobs] = useState(10);
  const [currentPage, setCurrentpage] = useState(0);
  const itemsPerPage = 10;
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  // console.log(data);
  const fetchdata = async (usr: UserType) => {
    try {
      // token ,limit,page format
      setloading(true);
      const response = await getListofJobs(
        usr!.token,
        itemsPerPage,
        currentPage + 1
      );
      const JobsArray = response as JobType[];
      const sortedJobs = JobsArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setdata(sortedJobs);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (usr && searchKey.trim().length === 0) {
      fetchdata(usr);
    }
  }, [usr, searchKey]);

  const handleDelete = async (jobId: string) => {
    try {
      if (usr && jobId) {
        const res = await deleteJob(usr.token, jobId);
        if (res) {
          setdata((prev: JobType[]) =>
            prev.filter((item: JobType) => item._id !== jobId)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedJob?._id!);
    onDeleteAlertClose();
  };

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };

  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchKey.trim() !== "") {
        const res = (await SearchResources(
          usr.token,
          "job",
          searchKey
        )) as response;
        // console.log(res.jobs);
        setdata(res.jobs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-[95%] mx-auto">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Jobs
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchKey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addjob.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Create New Jobs
            </p>
          </Button>
          <CreateJobs
            setdata={setdata}
            selectedJob={selectedJob}
            setselectedJob={setSelectedJob}
            isOpen={isOpen}
            onClose={onClose}
          />
        </p>
      </div>

      {loading ? (
        <div className="w-[100] h-[50vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <>
          <div className="subscritpionTable  ">
            {data.length > 0 &&
              data.map((item: JobType) => (
                <>
                  <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                    <CardBody className=" pt-2 pb-0">
                      {/* right and left */}
                      <div className="flex items-center justify-between">
                        {/* left */}
                        <div className="flex">
                          {/* img */}
                          {item.image && (
                            <img
                              src={`${image_Base_Url}${item.image}`}
                              className=" w-[52px] my-1 pb-1 h-[52px] rounded-md m-auto"
                              alt=""
                            />
                          )}

                          {/* next to img */}
                          <div className="">
                            <div className="flex mt-2 pb-0 items-center">
                              <p className="mb-0 pl-2 font-bold text-[13px]">
                                {item.title}
                              </p>
                              <p className="mb-0 pl-2 flex">
                                <img
                                  src={"/dashboardicons/clock.png"}
                                  className="opacity-70 px-2 hidden lg:block md:block"
                                  alt=""
                                />
                                {item.createdAt &&
                                  new Date(item.createdAt)
                                    .toISOString()
                                    .split("T")[0]}
                              </p>
                            </div>
                            <div>
                              <p className="pl-2 mt-1 hidden md:block lg:block">
                                {item.description.toString().length > 80 ? (
                                  <>
                                    {item.description.slice(0, 79)}
                                    {"....."}
                                  </>
                                ) : (
                                  item.description
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* right */}
                        <div>
                          <Popover
                            isOpen={
                              popoverOpen === item?._id &&
                              item._id !== undefined
                            }
                            onClose={() => setPopoverOpen(undefined)}
                          >
                            <PopoverTrigger>
                              <img
                                className="cursor-pointer"
                                src={"/dashboardicons/threedots.png"}
                                alt=""
                                onClick={() => setPopoverOpen(item._id)}
                              />
                            </PopoverTrigger>
                            <PopoverContent
                              mr={10}
                              maxWidth={"150px"}
                              padding={"3px"}
                              borderRadius={"20px"}
                              borderTopRightRadius={"0px"}
                            >
                              <PopoverBody
                                borderRadius={"20px"}
                                borderTopRightRadius={"0px"}
                              >
                                <p
                                  onClick={() => {
                                    setSelectedJob(item);
                                    onOpen();
                                  }}
                                  className="text-black cursor-pointer text-[15px]"
                                >
                                  Edit
                                </p>

                                <p
                                  onClick={() => {
                                    setSelectedJob(item);
                                    onDeleteAlertOpen();
                                  }}
                                  className="text-red-500 cursor-pointer text-[15px]"
                                >
                                  Delete
                                </p>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </div>
                      </div>
                      <DeleteAlert
                        isOpen={isDeleteAlertOpen}
                        onClose={onDeleteAlertClose}
                        onConfirmDelete={confirmDelete}
                        label="Delete Job"
                      />
                    </CardBody>
                  </Card>
                </>
              ))}
          </div>
          <div className="mt-3 px-2">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={Math.ceil(totalJobs / itemsPerPage)}
              previousLabel="prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="page-num"
              previousClassName="page-num"
              nextLinkClassName="page-num"
              activeLinkClassName="active"
              marginPagesDisplayed={2}
              // className="mt-3"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Jobs;
