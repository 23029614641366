import {
  Card,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import Video from "./ResourcesTabs/Video";
import Documents from "./ResourcesTabs/Documents";
import Communties from "./ResourcesTabs/Communties";
import Jobs from "./ResourcesTabs/Jobs";
import Podcasts from "./ResourcesTabs/Podcasts";
import Blogs from "./ResourcesTabs/Blogs";

const ResourcesComponent = () => {
  return (
    <Card padding={"14px"} height={"88%"} width={"97%"} marginX={"auto"}>
      <Tabs variant="soft-rounded" fontSize={"small"} flex={"wrap"}>
        <TabList
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          gap="6px"
          marginX={"12px"}
        >
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Video
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Documents
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Communties
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Jobs
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Podcasts
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Blogs
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding={"0"}>
            <Video />
          </TabPanel>
          <TabPanel padding={"0"}>
            <Documents />
          </TabPanel>
          <TabPanel padding={"0"}>
            <Communties />
          </TabPanel>
          <TabPanel padding={"0"}>
            <Jobs />
          </TabPanel>
          <TabPanel padding={"0"}>
            <Podcasts />
          </TabPanel>
          <TabPanel padding={"0"}>
            <Blogs />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default ResourcesComponent;
