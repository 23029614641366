import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import SupporChat from "../components/SupporChat";
import MobileAllTickets from "../components/SupportChat/MobileAllTickets";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import FAQs from "../components/FAQs";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { AllSupportChatTickets } from "../Api";
import { SupportTicket } from "../@types/user";

const Supportchart = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const selector = useSelector((state: RootState) => state.user);
  const [supportTickets, setSupportTickets] = useState<SupportTicket[]>([]);

  const [currentPage, setCurrentpage] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [searchKey, setSearchkey] = useState("");
  const usr = selector.user;
  const getSupportChats = async () => {
    try {
      if (usr && searchKey.trim() === "") {
        setisLoading(true);
        const res = (await AllSupportChatTickets(
          usr.token,
          currentPage + 1,
          10
        )) as SupportTicket[];
        setSupportTickets((prev) => [...prev, ...res]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  };
  useEffect(() => {
    getSupportChats();
  }, [searchKey, currentPage]);

  return (
    <DashboardWrapper>
      <Titlebar title={"Support Tickets and FAQs"} />

      <Tabs variant="soft-rounded" fontSize={"small"} flex={"wrap"}>
        <TabList
          display="flex"
          flexWrap="wrap"
          justifyContent="flex-start"
          gap="6px"
          marginX={"12px"}
          className="px-6 lg:px-4 md:px-4"
        >
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            Support chat
          </Tab>
          <Tab
            _selected={{ color: "black", bg: "yellow.400" }}
            backgroundColor={"rgba(238, 238, 238, 1)"}
            fontSize={"small"}
          >
            FAQs
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel className="p-2">
            {windowWidth <= 768 ? (
              <>
                <MobileAllTickets
                  supportTickets={supportTickets}
                  setSupportTickets={setSupportTickets}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentpage}
                  isLoading={isLoading}
                />
              </>
            ) : (
              <SupporChat
                searchKey={searchKey}
                setsearchKey={setSearchkey}
                supportTickets={supportTickets}
                setsupportTickets={setSupportTickets}
                currentPage={currentPage}
                setCurrentPage={setCurrentpage}
                isLoading={isLoading}
              />
            )}
          </TabPanel>
          <TabPanel>
            <FAQs />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* from components page */}
    </DashboardWrapper>
  );
};

export default Supportchart;
