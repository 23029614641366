import {
  Button,
  Card,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

const AddpeopleinCommunity = ({ setnewData, isOpen, onClose }: any) => {
  const [data, setdata] = useState([1, 2, 3, 4]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Member</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <form
            onSubmit={() => {
              console.log("submited");
            }}
          >
            <div className="relative">
              <Input
                className="w-[100%] bg-[#F5F5F5]"
                type="text"
                placeholder="Search here"
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <SearchIcon className="text-gray-400" />
              </div>
            </div>
          </form>
          {data.map((item) => (
            <Card bgColor={"#F5F5F5"} className="mt-4 shadow-md">
              <div className="flex justify-between px-4 items-center">
                <div className="flex items-center">
                  <img
                    src={"/dashboardicons/usericon.png"}
                    className="w-[54px] h-[54px]"
                    alt=""
                  />
                  <div className="flex flex-col justify-center items-start">
                    <p className="mb-0 ml-1 md:ml-5 lg:ml-5 text-sm font-bold">
                      Jonathan williams
                    </p>
                    <p className="mb-0 bg-[yellow] ml-1 md:ml-5 lg:ml-5 rounded-full flex font-semibold text-[10px]">
                      <img
                        src={"/dashboardicons/communitytotalmembers.png"}
                        className="mr-2"
                        alt=""
                      />
                      154
                      <span className="hidden lg:block">totalmembers</span>
                    </p>
                  </div>
                </div>
                {/* rigt */}
                <div>
                  <Button
                    size={"sm"}
                    bgColor={"#3F1B5B"}
                    color={"white"}
                    _hover={{ bgColor: "#3F1B5m" }}
                    className=" flex justify-center items-center text-[12px]"
                    borderRadius={"8px"}
                    onClick={() =>
                      setnewData((prev: any) => ({
                        ...prev,
                        totalmembers: [...prev.totalmembers, "newMember"],
                      }))
                    }
                  >
                    <img
                      src={"/dashboardicons/plus.png"}
                      className="w-[12px] h-[12px]"
                      alt=""
                    />
                  </Button>
                </div>
              </div>
            </Card>
          ))}
          <div className="w-[80%] mx-auto mt-3">
            <Button
            onClick={onClose}
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
            >
              Add
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddpeopleinCommunity;
