import { Button, Input, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import ReactSelect from "react-select";

const Addsubscription = ({ setindexes, setsampledata }: any) => {
  const optionsForPlan = [
    { value: "Monthly", label: "Monthly" },
    { value: "6 Months", label: "6 Months" },
    { value: "Annually", label: "Annually" },
  ];

  const [data, setData] = useState({
    price: "",
    description: "",
    type: "",
    Date: new Date().toISOString().split("T")[0],
  });

  const onchange = (e: any) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const onTypeChange = (selectedOption: any) => {
    setData((prev) => ({ ...prev, type: selectedOption.value }));
  };

  const handleClick = () => {
    setsampledata((prev: any) => [data, ...prev]);
  };

  return (
    <div className="addinstructor w-[96%] h-[75vh] bg-white  mx-auto mb-3 rounded-2xl shadow-md">
      {/* title /header inside component */}
      <div className="flex justify-between py-4 px-5">
        <p className="font-bold text-[12px] lg:text-[14px] md:text-[14px]">
          Create New Subscription
        </p>

        <Button
          onClick={() => {
            setindexes(0);
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="px-4 text-[10px]"
        >
          Back
        </Button>
      </div>

      {/* input boxes */}
      <div className="lg:w-[40%] md:w-[65%] w-[80%] m-auto ">
        {/* <div className="bg-slate-400">nb</div> */}

        <label>Subscription price</label>
        <Input name="price" onChange={onchange} placeholder="Enter here" />

        <label>Subscription Type</label>
        <ReactSelect
          name="type"
          options={optionsForPlan}
          onChange={onTypeChange}
        />

        <label>Description</label>
        <Textarea
          name="description"
          onChange={onchange}
          className="w-[100%] mt-[10px]"
          placeholder="Enter here"
        />

        <Button
          onClick={handleClick}
          style={{ backgroundColor: "#686628" }}
          className="w-[100%] text-white mt-[12px] mb-3"
        >
          SAVE
        </Button>
      </div>
    </div>
  );
};
export default Addsubscription;
