import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Subscription.css";
import CreatePodcasts from "../modal/CreatePodcasts";
import { PodcastType, UserType } from "../../@types/user";
import { SearchResources, deletePodcast, getListOfPodcasts } from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import HashLoader from "react-spinners/HashLoader";
import DeleteAlert from "../Alert/DeleteAlert";
import { CiSearch } from "react-icons/ci";

interface searchKeyRes {
  podcasts: PodcastType[];
}

const Podcasts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const [loading, setloading] = useState(false);
  const [selectedPodcast, setSelectedPodcast] = useState<PodcastType>();
  const [podcasts, setpodcasts] = useState<PodcastType[]>([]);
  const [searchKey, setsearchkey] = useState("");
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchPodcastsData = async (usr: UserType) => {
    try {
      // token, limit, page format
      setloading(true);
      const response = await getListOfPodcasts(usr!.token, 10, 1);
      const podcastsArray = response as PodcastType[];
      const sortedPodcasts = podcastsArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setpodcasts(sortedPodcasts);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (usr && searchKey.trim().length === 0) {
          await fetchPodcastsData(usr);
        } else {
          console.log("something went wrong");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [usr, searchKey]);

  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && searchKey.trim() !== "") {
        const res = (await SearchResources(
          usr.token,
          "podcast",
          searchKey
        )) as searchKeyRes;

        setpodcasts(res.podcasts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (podcastId: string) => {
    if (usr && podcastId) {
      const res = await deletePodcast(usr.token, podcastId);
      if (res === 3) {
        setpodcasts((prev: PodcastType[]) =>
          prev.filter((item: PodcastType) => item._id !== podcastId)
        );
      }
    }
  };

  const confirmDelete = () => {
    if (selectedPodcast !== null) {
      handleDelete(selectedPodcast?._id!);
      onDeleteAlertClose();
    }
  };
  return (
    <div className="w-[95%]">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Podcasts
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              onChange={(e) => setsearchkey(e.target.value)}
              value={searchKey}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addjob.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Create New Podcasts
            </p>
          </Button>
          <CreatePodcasts
            setpodcasts={setpodcasts}
            isOpen={isOpen}
            onClose={onClose}
            selectedPodcast={selectedPodcast}
            setSelectedPodcast={setSelectedPodcast}
          />
        </p>
      </div>
      {loading ? (
        <>
          <div className="w-[100] h-[50vh] flex justify-center items-center">
            <HashLoader />
          </div>
        </>
      ) : (
        <>
          <div className="subscritpionTable min-h-[40vh]">
            {podcasts.map((item, index) => (
              <div key={index}>
                <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                  <CardBody className=" pt-2 pb-0">
                    {/* right and left */}
                    <div className="flex items-center justify-between">
                      {/* left */}
                      <div className="flex">
                        {/* img */}
                        <img
                          src={`${image_Base_Url}${item.image}`}
                          className=" w-[60px]  hidden lg:block md:block my-1 pb-1 h-[60px] m-auto rounded-md"
                          alt=""
                        />

                        {/* next to img */}
                        <div className="w-[100%]">
                          <div className="flex mt-2 pb-0 items-center">
                            <p className="mb-0 pl-2 font-bold text-[13px]">
                              {item.title}
                            </p>
                            <p className="mb-0 pl-2 flex ">
                              <img
                                src={"/dashboardicons/creatorDp.png"}
                                className="opacity-70 h-[19px] px-2"
                                alt=""
                              />
                              <p className="mb-0 font-semibold lg:text-[12px] md:text-[12px] text-[9px] text-[#5f5757]">
                                {item.host_name}
                              </p>
                            </p>{" "}
                            <p className="mb-0 pl-2 flex">
                              <img
                                src={"/dashboardicons/clock.png"}
                                className="opacity-70 px-2"
                                alt=""
                              />
                              {item.createdAt &&
                                new Date(item.createdAt)
                                  .toISOString()
                                  .split("T")[0]}
                            </p>
                          </div>
                          <div>
                            <p className="pl-2 mt-1 text-wrap">
                              {item.description.substring(0, 100)}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* right */}
                      <div>
                        <Popover>
                          <PopoverTrigger>
                            <img
                              className="cursor-pointer z-40"
                              src={"/dashboardicons/threedots.png"}
                              alt=""
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            mr={10}
                            maxWidth={"100px"}
                            padding={"3px"}
                            borderRadius={"20px"}
                            borderTopRightRadius={"0px"}
                          >
                            <PopoverBody
                              borderRadius={"20px"}
                              borderTopRightRadius={"0px"}
                            >
                              <p
                                onClick={() => {
                                  setSelectedPodcast(item);
                                  onOpen();
                                }}
                                className=" m-0 pb-2 cursor-pointer text-[15px] w-100 "
                              >
                                Edit
                              </p>

                              <p
                                onClick={() => {
                                  setSelectedPodcast(item);
                                  onDeleteAlertOpen();
                                }}
                                className="text-red-500 m-0 cursor-pointer text-[15px] "
                              >
                                Delete
                              </p>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <DeleteAlert
                  isOpen={isDeleteAlertOpen}
                  onClose={onDeleteAlertClose}
                  onConfirmDelete={confirmDelete}
                  label="Delete Podcast"
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Podcasts;
