import { Button, Input, Textarea } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import "./Addinstructor.css";
import { Instructor, UserType } from "../../@types/user";
import { addNewInstructor, updateInstructor } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { image_Base_Url } from "../../config";
const AddInstructor = ({
  setindexes,
  setinstructordata,
  selectedInstructor,
  setselectedInstructor,
  selectedindex,
}: {
  setindexes: Dispatch<SetStateAction<number>>;
  setinstructordata: Dispatch<SetStateAction<Instructor[]>>;
  selectedInstructor: Instructor | undefined;
  setselectedInstructor: Dispatch<SetStateAction<Instructor | undefined>>;
  selectedindex: number;
}) => {
  const [newData, setNewData] = useState<Instructor>(() => {
    if (selectedInstructor !== undefined) {
      return {
        name: selectedInstructor.name,
        bio: selectedInstructor.bio,
        email: selectedInstructor.email,
        mono: selectedInstructor.mono,
        profile_pic: selectedInstructor.profile_pic,
      };
    } else {
      return {
        name: "",
        bio: "",
        email: "",
        mono: "",
        profile_pic: null,
      };
    }
  });

  const [imageurl, setImageurl] = useState("");

  const onchange = (e: any) => {
    const { name, value } = e.target;
    setNewData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImageurl(URL.createObjectURL(file));
      setNewData((prev) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  // for creatinf new instrucotr

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (usr) {
      const usr2: UserType = usr;

      try {
        const response = (await addNewInstructor(
          usr2.token,
          newData
        )) as Instructor;
        setinstructordata((prev: Instructor[]) => [response, ...prev]);
        setindexes(0);
      } catch (error) {
        console.error("Error adding new instructor:", error);
      }
    }
  };

  // for updatring instructoe
  const hanldeUpdate = async () => {
    if (usr && selectedInstructor) {
      const usr2: UserType = usr;

      try {
        const response = (await updateInstructor(
          usr2.token,
          newData,
          selectedInstructor!._id!
        )) as Instructor;
        setinstructordata((prev: Instructor[]) => {
          const newDataArray = [...prev];
          newDataArray[selectedindex] = response;
          return newDataArray;
        });
        setindexes(0);
      } catch (error) {
        console.error("Error adding new instructor:", error);
      }
    }
  };

  return (
    <div className="addinstructor w-[96%] h-[85%] bg-white  mx-auto mb-3 rounded-2xl shadow-md">
      {/* title /header inside component */}
      <div className="flex justify-between py-4 px-5">
        <p className="font-bold">Create New Instructor</p>

        <Button
          onClick={() => {
            setindexes(0);
            setselectedInstructor(undefined);
          }}
          bgColor={"#3F1B5B"}
          color={"white"}
          _hover={{ bgColor: "#3F1B5f" }}
          className="lg:px-5 md:px-4 px-3"
        >
          Back
        </Button>
      </div>

      {/* input boxes */}
      <form onSubmit={handleSubmit}>
        <div className="lg:w-[40%] md:w-[65%] w-[80%] m-auto h-[100%]">
          {/* <div className="bg-slate-400">nb</div> */}
          <div className="text-center w-[100%] mx-auto ">
            <div className=" mx-auto  w-[100px]">
              <div className="border-3 w-[100px] h-[100px] z-100 p-1 border-[#3F1B5B] rounded-full">
                <div className="rounded-full relative bg-[#F0F0F0] ">
                  {newData?.profile_pic instanceof File ? (
                    <img
                      src={
                        newData.profile_pic === null
                          ? "/dashboardicons/upload.png"
                          : imageurl
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt={
                        newData.profile_pic === null
                          ? "Upload icon"
                          : "Instructor profile picture"
                      }
                    />
                  ) : (
                    <img
                      src={
                        newData?.profile_pic !== null
                          ? `${image_Base_Url}${newData?.profile_pic}`
                          : "/dashboardicons/upload.png"
                      }
                      className="mx-auto rounded-full h-[87px] w-[100px]"
                      alt={newData?.name || "Default profile picture"}
                    />
                  )}
                </div>
              </div>

              <label htmlFor="fileInput" className="">
                <img
                  src="/dashboardicons/uploadicon.png"
                  alt=""
                  className="absolute  mt-[-42px] cursor-pointer "
                  style={{ width: "38px", height: "38px" }}
                />
              </label>
            </div>
            <input
              id="fileInput"
              // multiple
              name="profile_pic"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </div>
          <label>Instructor Name</label>
          <Input
            onChange={onchange}
            name="name"
            value={newData?.name}
            placeholder="Enter here"
            required
          />
          <label>Bio</label>
          <Textarea
            onChange={onchange}
            name="bio"
            className="w-[100%] mt-[10px]"
            placeholder="Enter here"
            value={newData?.bio}
            required
          />
          <label>Email</label>
          <Input
            type="email"
            onChange={onchange}
            name="email"
            placeholder="Enter here"
            value={newData?.email ?? ""}
          />
          <label>Mobile</label>
          <Input
            onChange={onchange}
            name="mono"
            value={newData?.mono ?? ""}
            placeholder="Enter here"
          />
          {selectedInstructor?._id ? (
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
              onClick={hanldeUpdate}
            >
              Save
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
              type="submit"
            >
              Save
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddInstructor;
