import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const NewDocumentModel = ({ isOpen, onClose }: any) => {
  return (
    <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Document </ModalHeader>
        <ModalCloseButton />
        {/* addinstructor make the label and input styled */}
        <ModalBody className="addinstructor">
          <div className="flex justify-between items-center">
            <label className="text-sm font-semibold">Upload Pdf</label>
            <Button
              bgColor={"#3F1B5B"}
              color={"white"}
              _hover={{ bgColor: "#3F1B5m" }}
              className=" flex justify-center text-sm items-center text-[12px]"
              borderRadius={"20px"}
            >
              <img src={"/dashboardicons/plus.png"} className="mr-2" alt="" />
              <p className="mb-0 text-sm">Select Your Document</p>
            </Button>
          </div>
          <div className="w-[100%] h-[10vh] mt-3 bg-[#e8d8f4] rounded-md flex justify-center items-center">
            <img
              src={"/dashboardicons/pdficon.png"}
              className="h-[24px] w-[24px]"
              alt=""
            />
          </div>
          <label className="mt-3">PDF Title</label>
          <Input placeholder="Enter here" />
          <label>Description</label>
          <Textarea placeholder="Enter here" />

          <div className="w-[80%] mx-auto">
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewDocumentModel;
