import React from "react";
import { Card, CardBody } from "@chakra-ui/react";
interface InfoCardProps {
  icon: string;
  count: number | undefined;
  label: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, count, label }) => {
  return (
    <div>
      <Card
        maxW={"md"}
        borderRadius={"25px"}
        shadow={"md"}
        className="info-card min-w-[170px] h-[100%] min-h-[170px]"
      >
        <CardBody className="flex flex-col justify-start items-center mt-2">
          <img src={icon} alt="" className="w-[3.5rem] h-[3.5rem]" />
          <p className="font-bold mt-2">{count}</p>
          <p className="text-sm text-center">{label}</p>
        </CardBody>
      </Card>
    </div>
  );
};

export default InfoCard;
