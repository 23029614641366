import React, { Dispatch, SetStateAction } from "react";
import { formatDistanceToNow as fns } from "date-fns";
import { SupportTicket } from "../../@types/user";
const AllTickets = ({
  supportChat,
  setsupportTickets,
}: {
  // only one selected
  supportChat: SupportTicket;
  setsupportTickets?: Dispatch<SetStateAction<SupportTicket[]>>;
}) => {
  // return ths status
  function TicketStatus(status: number) {
    if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Open";
    } else {
      return "Closed";
    }
  }

  return (
    <div>
      <div className="flex cursor-pointer justify-between text-xs">
        {/* left */}
        <div>
          <p className="mb-2 font-semibold">{supportChat.title}</p>
          <p className="mb-2 text-wrap text-[10px]">
            {supportChat.description}
          </p>
        </div>
        {/* right */}
        <div>
          <p className="mb-2">{TicketStatus(supportChat.status)}</p>
          <p className="mb-2">{fns(supportChat.createdAt!)}</p>
        </div>
      </div>

      <p
        className="text-xs"
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      ></p>
    </div>
  );
};

export default AllTickets;
