import { Button, Card, CardBody, Input, useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import "../Subscription.css";
import CreateCommunity from "../modal/CreateCommunity";
import { Link } from "react-router-dom";

const Documents = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sampledata = [
    {
      CommunityIcon: "/dashboardicons/videoicon.png",
      communityName: "Community Name",
      CreatorName: "creator name",

      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam, quo. Aut sequi aperiam laborum animi minus, velit quo possimus tempore, natus alias laboriosam iste odio repudiandae inventore delectus amet maiores.",
    },
    {
      CommunityIcon: "/dashboardicons/videoicon.png",
      communityName: "Community Name",
      CreatorName: "creator name",

      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam, quo. Aut sequi aperiam laborum animi minus, velit quo possimus tempore, natus alias laboriosam iste odio repudiandae inventore delectus amet maiores.",
    },
    {
      CommunityIcon: "/dashboardicons/videoicon.png",
      communityName: "Community Name",
      CreatorName: "creator name",

      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam, quo. Aut sequi aperiam laborum animi minus, velit quo possimus tempore, natus alias laboriosam iste odio repudiandae inventore delectus amet maiores.",
    },
    {
      CommunityIcon: "/dashboardicons/videoicon.png",
      communityName: "Community Name",
      CreatorName: "creator name",
      totalmembers: [1, 3, 4, 5],
      text: " Lorem ipsum dolor sit amet, consectetur adipisicing elit.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam, quo. Aut sequi aperiam laborum animi minus, velit quo possimus tempore, natus alias laboriosam iste odio repudiandae inventore delectus amet maiores.",
    },
  ];

  const [data, setdata] = useState([...sampledata]);
  return (
    <div className="w-[95%]">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Communities
          </p>
          <Input
            //   onChange={(e) => hanldeSearch(e.target.value)}
            placeholder="Search here"
            minWidth={"170px"}
            width={"20vw"}
            maxWidth={"250px"}
          />
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/announcementicon.png"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Create New Community
            </p>
          </Button>
          <CreateCommunity
            setdata={setdata}
            isOpen1={isOpen}
            onClose1={onClose}
          />
        </p>
      </div>
      <div className="subscritpionTable ">
        {data.map((item, index) => (
          <div key={index}>
            <Link to={"/communitydetail"}>
              <Card
                onClick={() => console.log(index)}
                className="border cursor-pointer my-2 text-[12px] border-[#120B1C1A] rounded-3xl"
              >
                <CardBody className=" pt-2 pb-0">
                  {/* right and left */}
                  <div className="flex items-center justify-between">
                    {/* left */}
                    <div className="flex">
                      {/* img */}
                      <img
                        src={item.CommunityIcon}
                        className=" w-[60px] hidden lg:block md:block my-1 pb-1 h-[60px] m-auto"
                        alt=""
                      />

                      {/* next to img */}
                      <div className="w-[100%]">
                        <div className="flex mt-2 pb-0 items-center">
                          <p className="mb-0 pl-2 font-bold text-[13px]">
                            {item.communityName}
                          </p>
                          <p className="mb-0 lg:pl-2 lg:ml-4 flex items-center">
                            <img
                              src={"/dashboardicons/creatorDp.png"}
                              className="opacity-70 h-[19px] px-2"
                              alt=""
                            />
                            <p className="mb-0 font-semibold lg:text-[12px] md:text-[12px] text-[9px] text-[#5f5757]">
                              {item.CreatorName}
                            </p>
                            <p className="mb-0 bg-[yellow] ml-1 md:ml-5 lg:ml-5 p-1 rounded-full flex font-semibold text-[10px]">
                              <img
                                src={"/dashboardicons/communityMembers.png"}
                                className="mr-2"
                                alt=""
                              />
                              {item?.totalmembers
                                ? item.totalmembers.length
                                : "154"}
                              <span className="hidden lg:block">members</span>
                            </p>
                          </p>
                        </div>
                        <div>
                          <p className="pl-2 mt-1 w-[100%] text-wrap">
                            {item.text.substring(0, 100)}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* right */}
                    <div className="min-w-[5vw]">
                      <img
                        className="cursor-pointer"
                        src={"/dashboardicons/threedots.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Documents;
