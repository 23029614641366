import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { BsSendFill } from "react-icons/bs";
import TicketDetails from "../modal/TicketDetails";
import EmojiPicker from "emoji-picker-react";
import { BiSmile } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { CiImageOn } from "react-icons/ci";
import { MessageType, SupportTicket } from "../../@types/user";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  changeTicketStatus,
  GetTicketMessages,
  sendMessageSupportChat,
} from "../../Api";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../utils/firebase";
import { image_Base_Url } from "../../config";
import toast from "react-hot-toast";

const ChatView = ({
  selectedTicket,
  setsupportTickets,
}: {
  selectedTicket?: SupportTicket;
  setsupportTickets: Dispatch<SetStateAction<SupportTicket[]>>;
}) => {
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const {
    isOpen: isTicketOpen,
    onClose: onTicketClose,
    onOpen: onTicketOpen,
  } = useDisclosure();
  const [emojivisible, setemojivisible] = useState(false);
  const [text, settext] = useState<string>("");
  const [image, setImage] = useState<File | undefined>(undefined);

  const [messages, setMessages] = useState<MessageType[]>([]);
  const chatEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function TicketStatus(status: number) {
    if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Open";
    } else {
      return "Closed";
    }
  }

  useEffect(() => {
    onMessage(messaging, (payload) => {
      if (payload?.data?.ticketId === selectedTicket?._id) {
        const res: MessageType = {
          receiver: usr?.user?._id!,
          sender: payload?.data?.fromUser || "",
          message: payload.notification?.body || "",
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        setMessages((prev) => [...prev, res]);
      }
    });
  }, [selectedTicket?._id, usr]);

  const TicketMessages = async (ticket_id: string) => {
    try {
      setMessages([]);
      if (usr && ticket_id) {
        const res = (await GetTicketMessages(
          usr.token,
          1,
          15,
          ticket_id
        )) as MessageType[];
        console.log(res);
        const sortedMsgs = res.sort((b, a) => {
          return (
            new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
          );
        });
        setMessages(sortedMsgs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const groupMessagesByDay = (messages: MessageType[]) => {
    return messages.reduce((acc, message) => {
      const date = new Date(message.createdAt!).toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {} as { [key: string]: MessageType[] });
  };

  const groupedMessages = groupMessagesByDay(messages);

  const handleChangeStatus = async (status: number, ticketId: string) => {
    const statuscheck: { [key: number]: number } = {
      0: 1,
      1: 2,
      2: 1,
    };
    try {
      if (usr) {
        const res = (await changeTicketStatus(
          usr.token,
          ticketId,
          statuscheck[status]
        )) as SupportTicket;
        if (res) {
          setsupportTickets?.((prev: SupportTicket[]) =>
            prev.map((item: SupportTicket) =>
              item._id === selectedTicket?._id!
                ? { ...item, status: res.status }
                : item
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedTicket?._id !== undefined) {
      TicketMessages(selectedTicket._id);
    }
  }, [selectedTicket]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      setImage(file);
      toast.success("Added image");
    } else {
      return toast.error("Select Image");
    }
  };

  const handleSendMsg = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (usr && selectedTicket?._id !== undefined) {
        const res = (await sendMessageSupportChat(
          usr.token,
          selectedTicket._id,
          selectedTicket.user,
          text,
          image
        )) as MessageType;

        if (res) {
          setMessages((prev: MessageType[]) => [...prev, res]);
          settext("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (selectedTicket === undefined) {
    return (
      <div className="h-[67vh] w-100 flex  justify-between">
        <p className="flex mb-0 w-100 justify-center items-center">
          No Chat Selected
        </p>
      </div>
    );
  }

  return (
    <div className=" h-[60vh] flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-center text-xs">
          {/* left */}
          <div className="flex items-center">
            <div>
              <p className="mb-2 font-semibold">{selectedTicket?.title}</p>
              <p className="mb-0">Ticket No:{selectedTicket?._id}</p>
            </div>
            <p
              className={`${
                selectedTicket?.status === 1 ? "text-[green]" : "text-[red]"
              } mb-0 pl-2`}
            >
              {TicketStatus(selectedTicket.status)}
            </p>
          </div>
          {/* right */}
          <div>
            <Popover placement="bottom-start">
              <PopoverTrigger>
                <img
                  className="cursor-pointer"
                  src={"/dashboardicons/threedots.png"}
                  alt=""
                />
              </PopoverTrigger>
              <PopoverContent width={36}>
                <ul>
                  <li onClick={onTicketOpen} className="cursor-pointer my-3">
                    Ticket Details
                  </li>
                  <>
                    {selectedTicket.status === 0 && (
                      <li
                        onClick={() =>
                          handleChangeStatus(
                            selectedTicket.status,
                            selectedTicket?._id!
                          )
                        }
                        className="cursor-pointer"
                      >
                        Open
                      </li>
                    )}
                  </>
                  {selectedTicket.status === 1 && (
                    <li
                      onClick={() =>
                        handleChangeStatus(
                          selectedTicket.status,
                          selectedTicket?._id!
                        )
                      }
                      className="cursor-pointer"
                    >
                      Close
                    </li>
                  )}
                  {selectedTicket.status === 2 && (
                    <li
                      onClick={() =>
                        handleChangeStatus(
                          selectedTicket.status,
                          selectedTicket?._id!
                        )
                      }
                      className="cursor-pointer"
                    >
                      Reopen
                    </li>
                  )}
                </ul>
              </PopoverContent>
            </Popover>

            <TicketDetails
              selectedTicket={selectedTicket}
              isOpen={isTicketOpen}
              onClose={onTicketClose}
            />
          </div>
        </div>

        {/*chat  */}
        <div
          className="max-h-[55vh] min-h-[52vh] overflow-scroll text-sm"
          style={{ scrollbarWidth: "none" }}
        >
          {Object.entries(groupedMessages).map(([date, msgs]) => (
            <div key={date}>
              <p className="w-[100px] mx-auto text-center p-1 rounded-xl mb-0 text-xs bg-[#e3e3e3]">
                {date}
              </p>
              <div className="flex flex-col mt-2">
                {msgs.map((msg: MessageType, index: number) =>
                  msg.sender === usr?.user._id ? (
                    <div key={index} className="flex justify-end mb-2">
                      <>
                        {msg?.image ? (
                          <div className="flex-row">
                            <p className="mb-1">
                              {typeof msg.image === "string" && (
                                <img
                                  src={`${image_Base_Url}${msg.image} `}
                                  alt=""
                                  className="w-[20vw] h-[25vh] mb-0"
                                />
                              )}
                            </p>
                            <p className="bg-[#3F1B5B] p-3 text-white text-xs  rounded-tl-xl rounded-bl-xl rounded-br-xl">
                              {msg.message}
                            </p>
                          </div>
                        ) : (
                          <p className="bg-[#3F1B5B] p-3 text-white text-xs max-w-[50%] rounded-tl-xl rounded-bl-xl rounded-br-xl">
                            {msg.message}
                          </p>
                        )}
                      </>
                    </div>
                  ) : (
                    <div key={index} className="flex">
                      <>
                        {msg?.image ? (
                          <div className="flex-row">
                            <p className="mb-1">
                              {typeof msg.image === "string" && (
                                <img
                                  src={`${image_Base_Url}${msg.image} `}
                                  alt=""
                                  className="w-[20vw] h-[25vh]  mb-0"
                                />
                              )}
                            </p>
                            <p className="bg-gray-200 p-3  text-xs  rounded-tl-xl rounded-br-xl rounded-tr-xl">
                              {msg.message}
                            </p>
                          </div>
                        ) : (
                          <p className="bg-gray-200 p-3  text-xs max-w-[50%] rounded-tl-xl rounded-br-xl rounded-tr-xl">
                            {msg.message}
                          </p>
                        )}
                      </>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>
      </div>
      {/* bottom */}
      <form onSubmit={handleSendMsg}>
        <div className="flex">
          {selectedTicket?.status === 1 && (
            <>
              <Input
                placeholder="Type Message"
                backgroundColor={"rgba(238, 238, 238, 1)"}
                rounded={"3xl"}
                value={text}
                onChange={(e) => {
                  settext(e.target.value);
                }}
                className="relative w-80 px-12"
              />
              <div className="absolute right-16 pt-2 cursor-pointer z-40">
                <div className="flex">
                  {" "}
                  {emojivisible ? (
                    <span
                      onClick={() => {
                        setemojivisible(!emojivisible);
                      }}
                    >
                      <IoMdClose size={20} />
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        setemojivisible(!emojivisible);
                      }}
                    >
                      <BiSmile size={20} />
                    </span>
                  )}{" "}
                  <label htmlFor="fileInput" className="">
                    <CiImageOn size={20} />
                  </label>
                  <input
                    id="fileInput"
                    // multiple
                    name="course_pic"
                    accept="image/*"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </>
          )}

          {emojivisible && (
            <div className="right-0 bottom-24 absolute z-20">
              <EmojiPicker
                allowExpandReactions={false}
                searchDisabled={true}
                height={"350px"}
                className="absolute right-0 bottom-0 left-0 z-10"
                onEmojiClick={(e) => {
                  settext((text: string) => text + e.emoji);
                }}
              />
            </div>
          )}
          {selectedTicket.status === 1 && (
            <button className="bg-[#686628] ml-2  flex justify-center items-center px-[10px] rounded-full">
              <BsSendFill
                type="submit"
                className="cursor-pointer"
                size={18}
                color="white"
              />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChatView;
