import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import "./Titlebar.css";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userSlice";
import { RootState } from "../../redux/store";
import { getNotifiactionsOfMonth } from "../../Api";
import DeleteAlert from "../Alert/DeleteAlert";

type props = {
  title: string;
};

const Titlebar = ({ title }: props) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState<any>([]);
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: islogoutAlertOpen,
    onClose: onlogoutAlertClose,
    onOpen: onlogoutAlertOpen,
  } = useDisclosure();

  const fetchNotifications = async () => {
    try {
      if (usr) {
        const res = await getNotifiactionsOfMonth(usr.token);

        setNotifications(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchNotifications();
    }
  }, [isOpen]);

  const navigate = useNavigate();

  const hanldeLogout = () => {
    console.log("object");
    dispatch(logout());
    navigate("/");
  };

  const confirmLogout = () => {
    console.log("object");
    hanldeLogout();
    onlogoutAlertClose();
  };

  return (
    <div className="flex justify-between titlebar px-6 pt-6">
      <p
        style={{ fontFamily: "Montserrat" }}
        className=" text-black font-bold lg:text-[1.62rem] md:text-[1.63rem] text-[1.35rem]"
      >
        {title}
      </p>
      <div className="flex">
        {/* for notification  */}
        <div className="cursor-pointer pr-3">
          <Popover
            placement="bottom"
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
          >
            <PopoverTrigger>
              <img
                src="/titlebaricons/bell.png"
                className="w-[44px] h-[44px] relative"
                alt="Notification Icon"
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                className="absolute lg:right-12"
                shadow={"md"}
                borderRadius={"20px"}
                marginLeft={"12px"}
                margin={"auto"}
                minWidth={"25vw"}
                minHeight={"20vw"}
                maxHeight={"270px"}
                overflowY={"scroll"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "0",
                  },
                }}
              >
                <PopoverHeader
                  borderBottom={0}
                  className="flex justify-between items-center mx-4"
                >
                  <p className="m-0 mt-4 font-bold">Inbox</p>
                  <PopoverCloseButton className="my-3 mt-4 mx-4" />
                </PopoverHeader>
                <PopoverBody>
                  {notifications.map((item: any, index: number) => (
                    <Box
                      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                      key={index}
                      className="flex items-center py-2 px-1"
                    >
                      <div className="flex justify-between w-60">
                        <div className="flex items-center">
                          <img
                            src="/dashboardicons/notification.png"
                            alt=""
                            className="h-[3.65rem] w-[3.65rem]"
                          />
                          <div className="px-2 pt-2 w-40 text-wrap">
                            <p className="text-[13px] mb-0 leading-3 font-semibold">
                              {item.title.substring(0, 40)}
                            </p>
                            <p className="text-[11px] pt-2 leading-3 pb-2 m-0">
                              {item.description.substring(0, 70)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <p className="text-xs min-w-[70px] max-w-[80px]">
                        {formatDistanceToNow(item.date)} ago
                      </p>
                    </Box>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </div>

        {/* for profile */}
        <div className="cursor-pointer">
          <Popover>
            <PopoverTrigger>
              <img
                src="/titlebaricons/user.png"
                className="w-[44px] h-[44px]"
                alt="User Icon"
              />
            </PopoverTrigger>
            <PopoverContent
              mr={10}
              width={"15vw"}
              minWidth={"150px"}
              padding={"10px"}
              borderRadius={"20px"}
              borderTopRightRadius={"0px"}
            >
              <PopoverBody borderRadius={"20px"} borderTopRightRadius={"0px"}>
                <Link to={"/Admin"}>
                  <p className="text-black cursor-pointer text-[15px]">
                    My Profile
                  </p>
                </Link>
                {usr?.user.role === 1 && (
                  <Link to={"/userpermissons"}>
                    <p className="text-black cursor-pointer text-[15px]">
                      User Permission
                    </p>
                  </Link>
                )}

                <span
                  onClick={() => {
                    onlogoutAlertOpen();
                  }}
                >
                  <p className="text-red-500 cursor-pointer text-[15px]">
                    Logout
                  </p>
                </span>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <DeleteAlert
            isOpen={islogoutAlertOpen}
            onClose={onlogoutAlertClose}
            onConfirmDelete={confirmLogout}
            label="Logout"
          />
        </div>
      </div>
    </div>
  );
};

export default Titlebar;
