import {
  Button,
  Card,
  CardBody,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../Subscription.css";
import CreateVideo from "../modal/CreateVideo";
import { UserType, VideoType } from "../../@types/user";
import { SearchResources, deleteVideo, getListOfVideos } from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import ReactPaginate from "react-paginate";
import DeleteAlert from "../Alert/DeleteAlert";
import HashLoader from "react-spinners/HashLoader";
import { CiSearch } from "react-icons/ci";

interface Vidresponse {
  resourceVidoes: VideoType[];
  totalVideos: number;
}

interface SearchRes {
  videos: VideoType[];
}

const Video = () => {
  const {
    isOpen: isVidOpen,
    onOpen: onVidOpen,
    onClose: onVidClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();
  // selected video
  const [loading, setloading] = useState(false);
  const [selectedId, setselectedId] = useState("");

  const [data, setListOfVideos] = useState<VideoType[]>([]);
  const [searchKey, setsearchkey] = useState("");
  const [selectedVideo, setSelectedVideo] = useState<VideoType>();
  const [totalVideos, setTotalVideos] = useState(10);
  const [currentPage, setCurrentpage] = useState(0);
  const [popoverOpen, setPopoverOpen] = useState<string | undefined>(undefined);
  const itemsPerPage = 10;

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const fetchData = async (usr: UserType) => {
    try {
      // token, limit, page format
      setloading(true);
      const response = (await getListOfVideos(
        usr!.token,
        10,
        currentPage + 1
      )) as Vidresponse;
      setTotalVideos(response.totalVideos);
      const videosArray = response.resourceVidoes;

      const sortedVideos = videosArray.sort((a, b) => {
        return (
          new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
        );
      });
      setListOfVideos(sortedVideos);
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (usr && searchKey.trim().length === 0) {
      fetchData(usr);
    }
  }, [usr, searchKey, currentPage]);

  const handlesubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setloading(true);
      if (usr && searchKey.trim() !== "") {
        const res = (await SearchResources(
          usr.token,
          "video",
          searchKey
        )) as SearchRes;
        setListOfVideos(res.videos);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  const getThumbnailUrl = (thumbnail: string | File | null) => {
    if (thumbnail instanceof File) {
      // Use URL.createObjectURL to create a URL for the file
      return URL.createObjectURL(thumbnail);
    } else if (thumbnail !== null) {
      return `${image_Base_Url}${thumbnail}`;
    } else {
      return "/dashboardicons/videoicon.png";
    }
  };

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentpage(selectedPage.selected);
  };

  const handleDelete = async (videoId: string) => {
    try {
      const res = await deleteVideo(usr!.token, videoId);
      if (res && videoId) {
        setListOfVideos((videos: VideoType[]) =>
          videos.filter((video) => video._id !== videoId)
        );
        setPopoverOpen(undefined);
      }
    } catch (error) {
      console.log(error);
    } finally {
      onVidClose();
    }
  };

  const confirmDelete = () => {
    handleDelete(selectedId);
    onDeleteAlertClose();
  };

  return (
    <div className="w-[95%] mx-auto">
      {/* dashboard subscription */}
      <div className="flex items-center justify-between ">
        <p className=" pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Videos
          </p>
          <form onSubmit={handlesubmit} className="relative">
            <Input
              value={searchKey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
             <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onVidOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className=" flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addvid.svg"}
              className="h-[16px] w-[16px] mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden  m-0 ">
              Upload New Video
            </p>
          </Button>

          <CreateVideo
            selectedVideo={selectedVideo}
            setSelectedVideo={setSelectedVideo}
            setListOfVideos={setListOfVideos}
            isOpen={isVidOpen}
            onClose={onVidClose}
          />
        </p>
      </div>
      <div>
        <div className="subscritpionTable min-h-[50vh]">
          {loading ? (
            <div className="w-[100] h-[50vh] flex justify-center items-center">
              <HashLoader />
            </div>
          ) : (
            <>
              {data.length > 0 ? (
                data.map((item: VideoType) => (
                  <>
                    <Card className="border my-2 text-[12px] border-[#120B1C1A] rounded-3xl">
                      <CardBody className=" pt-2 pb-0">
                        {/* right and left */}
                        <div className="flex items-center justify-between">
                          {/* left */}
                          <div className="flex">
                            {/* img */}
                            <img
                              src={getThumbnailUrl(item.thumbnail)}
                              className=" w-[52px] my-1 pb-1 h-[52px] rounded-md m-auto"
                              alt=""
                            />

                            {/* next to img */}
                            <div className="">
                              <div className="flex mt-2 pb-0 items-center">
                                <p className="mb-0 pl-2 font-bold text-[13px]">
                                  {item.title}
                                </p>
                                <p className="mb-0 pl-2 flex">
                                  <img
                                    src={"/dashboardicons/clock.png"}
                                    className="opacity-70 px-2 hidden lg:block md:block"
                                    alt=""
                                  />
                                  {item.createdAt &&
                                    new Date(item.createdAt)
                                      .toISOString()
                                      .split("T")[0]}
                                </p>
                              </div>
                              <div>
                                <p className="pl-2 mt-1">
                                  {item.description.toString().length > 80 ? (
                                    <>
                                      {item.description.slice(0, 79)}
                                      {"....."}
                                    </>
                                  ) : (
                                    item.description
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* right */}
                          <div>
                            <Popover
                              isOpen={
                                popoverOpen === item._id &&
                                item._id !== undefined
                              }
                              onClose={() => setPopoverOpen(undefined)}
                            >
                              <PopoverTrigger>
                                <img
                                  className="cursor-pointer"
                                  src={"/dashboardicons/threedots.png"}
                                  alt=""
                                  onClick={() => setPopoverOpen(item._id)}
                                />
                              </PopoverTrigger>
                              <PopoverContent
                                mr={10}
                                maxWidth={"150px"}
                                padding={"3px"}
                                borderRadius={"20px"}
                                borderTopRightRadius={"0px"}
                              >
                                <PopoverBody
                                  borderRadius={"20px"}
                                  borderTopRightRadius={"0px"}
                                >
                                  <p
                                    onClick={() => {
                                      setSelectedVideo(item);
                                      onVidOpen();
                                    }}
                                    className="text-black cursor-pointer text-[15px]"
                                  >
                                    Edit
                                  </p>

                                  <p
                                    onClick={() => {
                                      setselectedId(item._id!);
                                      onDeleteAlertOpen();
                                    }}
                                    className="text-red-500 cursor-pointer text-[15px]"
                                  >
                                    Delete
                                  </p>
                                </PopoverBody>
                              </PopoverContent>
                            </Popover>
                          </div>
                        </div>
                        <DeleteAlert
                          isOpen={isDeleteAlertOpen}
                          onClose={onDeleteAlertClose}
                          onConfirmDelete={confirmDelete}
                          label="Delete Video"
                        />
                      </CardBody>
                    </Card>
                  </>
                ))
              ) : (
                <div className="w-60 h-[40vh]">No Videos found</div>
              )}
            </>
          )}
        </div>
        <div className="mt-3 px-2">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(totalVideos / itemsPerPage)}
            previousLabel="prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="page-num"
            previousClassName="page-num"
            nextLinkClassName="page-num"
            activeLinkClassName="active"
            marginPagesDisplayed={2}
            // className="mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
