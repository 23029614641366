import React, { useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import { Button, Card } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Communitydetail = () => {
  const router = useNavigate();
  const [data, setdata] = useState([1, 2, 3, 4, 5, 8, 7, 3, 5]);

  const sampledata = [
    {
      userIcon: "/dashboardicons/usericon.png",
      name: "Malar George",
      email: "malargeogre@gmail.com",
      phone: "8888999900",
      Status: "Active",
      CreatedAt: "05/06/2023",
      locked: true,
    },
  ];
  // pagination

  const [FilterData, setFilteredData] = useState([...data]);

  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = FilterData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(FilterData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % FilterData.length;
    setItemOffset(newOffset);
  };

  return (
    <div>
      <DashboardWrapper>
        <Titlebar title="Resources" />
        <div className="addinstructor w-[96%] h-[100%] bg-white  mx-auto mb-3 rounded-2xl shadow-md">
          <div className="flex justify-between py-4 px-5">
            <p className="font-bold">Community Detail</p>

            <Button
              onClick={() => {
                router(-1);
              }}
              bgColor={"#3F1B5B"}
              color={"white"}
              _hover={{ bgColor: "#3F1B5f" }}
              className="px-5"
            >
              Back
            </Button>
          </div>
          <div className="w-[80%] h-[100%] rounded-md m-auto shadow-md">
            <img
              src={"/dashboardicons/communityimage.png"}
              className="w-[100%] rounded-none"
              alt=""
            />
            <div className="px-4">
              <div className="flex justify-between items-center">
                {/* left side */}
                <div>
                  <p className="mb-0 text-lg font-bold">Community Title</p>
                  <div className="flex mt-2 justify-start items-center">
                    <p className="flex justify-center items-center">
                      <img
                        src={"/dashboardicons/communityMembers.png"}
                        alt=""
                      />
                      <p className="mb-0 text-xs ml-2">154 members</p>
                    </p>
                    <p className="flex justify-center ml-2 items-center">
                      <img src={"/dashboardicons/creatorDp.png"} alt="" />
                      <p className="mb-0 text-xs ml-1"> Creator name</p>
                    </p>
                  </div>
                </div>
                {/* right side icons */}
                <div className="flex justify-start items-center">
                  <img
                    src={"/dashboardicons/editicon.png"}
                    className="mr-4 h-[34px]"
                    alt=""
                  />
                  <img
                    src={"/dashboardicons/deleteicon.png"}
                    className="h-[34px]"
                    alt=""
                  />
                </div>
              </div>
              <p className="mb-0 font-bold">Description</p>
              <p className="text-xs">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure
                quia, sunt velit unde earum, eum dolore aliquam debitis facere
                blanditiis quasi quisquam, alias quos. Debitis soluta quibusdam
                quam adipisci cupiditate! Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Quod mollitia placeat in expedita. Harum illum
                iste esse! Et totam quo, atque tempore, voluptatum quidem nemo
                modi consequatur nihil molestiae repudiandae?
              </p>
              <p className="text-lg font-bold">Community Members</p>
              {currentItems.map((item) => (
                <>
                  <Card
                    bgColor={"#F5F5F5"}
                    className="mt-3"
                    borderRadius={"5px"}
                  >
                    <div className="flex items-center">
                      <img
                        src={"/dashboardicons/usericon.png"}
                        className="w-[54px] h-[54px]"
                        alt=""
                      />
                      <div>
                        <p className="mb-0 font-semibold text-sm ml-2">
                          Jonathan Williams
                        </p>
                        <p className="mb-0 bg-[yellow] ml-1 md:ml-5 lg:ml-5 p-1 rounded-full flex font-semibold text-[10px]">
                          <img
                            src={"/dashboardicons/communityMembers.png"}
                            className="mr-2"
                            alt=""
                          />
                          154 members
                        </p>
                      </div>
                    </div>
                  </Card>
                </>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="previous"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
              />
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </div>
  );
};

export default Communitydetail;
