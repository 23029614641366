import {
  Button,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
import { RootState } from "../redux/store";
import { ChangeAdminPassword, editAdminProfile, getProfileInfo } from "../Api";
import { AdminPassword, AdminProfType, AdminResType } from "../@types/user";
import { image_Base_Url } from "../config";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import toast from "react-hot-toast";

const AdminComponent = () => {
  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  const [data, setData] = useState<AdminProfType>({
    profile_pic: "",
    name: "",
    email: "",
    phone: "",
  });

  const [imageurl, setImageurl] = useState("");
  const [showOldPassword, setshowOldPassword] = useState(false);
  const [shownewPassword, setshownewPassword] = useState(false);
  const [showretypePassword, setshowretypePassword] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: AdminProfType) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | null
  ) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log(file);
      setImageurl(URL.createObjectURL(file));
      setData((prev: AdminProfType) => ({
        ...prev,
        [event.target.name]: file,
      }));
    }
  };

  const getAdminDetails = async () => {
    try {
      if (usr) {
        const res = (await getProfileInfo(usr.token)) as AdminResType;
        console.log(usr);
        if (res) {
          setData({
            email: res?.user?.email,
            name: res?.adminProfile?.name,
            phone: res?.adminProfile?.phone!,
            profile_pic: res?.adminProfile?.profile_pic,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdminDetails();
  }, []);

  const ProfileChangeSubmit = async () => {
    try {
      if (usr) {
        const res = await editAdminProfile(usr.token, data);
        if (res) {
          toast.success("Updated Profile", { position: "top-center" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // for change password
  const [passwordDetails, setpassWordDetails] = useState<AdminPassword>({
    currentPassword: "",
    newPassword: "",
    cPassword: "",
  });

  const hanldePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setpassWordDetails((prev) => ({ ...prev, [name]: value }));
  };

  const PasswordChangeSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordDetails.newPassword !== passwordDetails.cPassword) {
      return toast.error("New Password and Retype Password not Matched", {
        position: "top-center",
      });
    }
    try {
      if (usr) {
        const res = await ChangeAdminPassword(usr.token, passwordDetails);
        // console.log(res);
        if (res) {
          toast.success("Password Changed ", { position: "top-center" });

          setpassWordDetails({
            currentPassword: "",
            newPassword: "",
            cPassword: "",
          });
        }
      }
    } catch (error) {
      if (error) {
        toast.error("Current Password Not Matched", {
          position: "top-center",
        });
      }
    }
  };

  return (
    <div className="addinstructor w-[96%] bg-white shadow-md h-[100%] rounded-lg">
      <p className="text-xl font-bold px-5 pt-3">Profile Setting</p>
      <div className="w-[87%] lg:w-[47%] md:w-[57%] mx-auto h-[100%] ">
        <Tabs>
          <TabList>
            <Tab className="w-[50%] text-xs">My Profile</Tab>
            <Tab className="w-[50%] text-xs">Change Password</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <div className="w-[100%]  m-auto ">
                {/* <div className="bg-slate-400">nb</div> */}

                <div className="text-center w-[100%] mx-auto ">
                  <div className=" mx-auto  w-[100px]">
                    <div className="border-3 w-[100px] h-[100px] z-100 p-1 border-[#3F1B5B] rounded-full">
                      <div className="rounded-full relative bg-[#F0F0F0] ">
                        {/* for image if we get from backend we get string and need to add a baseurl before it */}
                        {data.profile_pic instanceof File ? (
                          <img
                            src={
                              data.profile_pic === null
                                ? "/dashboardicons/upload.png"
                                : imageurl
                            }
                            className="mx-auto rounded-full h-[87px] w-[100px]"
                            alt="rgthw"
                          />
                        ) : (
                          <img
                            src={
                              data?.profile_pic !== null
                                ? `${image_Base_Url}${data.profile_pic}`
                                : "/dashboardicons/upload.png"
                            }
                            className="mx-auto rounded-full h-[87px] w-[100px]"
                            alt=""
                          />
                        )}
                      </div>
                    </div>

                    <label htmlFor="fileInput" className="">
                      <img
                        src="/dashboardicons/uploadicon.png"
                        alt=""
                        className="absolute  mt-[-42px] cursor-pointer "
                        style={{ width: "38px", height: "38px" }}
                      />
                    </label>
                  </div>
                  <input
                    id="fileInput"
                    // multiple
                    name="profile_pic"
                    accept="image/*"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>

                <label className="mt-3">Name</label>
                <Input
                  onChange={handleChange}
                  value={data.name}
                  name="name"
                  placeholder="Enter here"
                />
                <label className="mt-3">Email</label>
                <Input
                  onChange={handleChange}
                  value={data.email}
                  name="email"
                  placeholder="Enter here"
                />
                <label className="mt-3">Mobile Number</label>
                <Input
                  onChange={handleChange}
                  value={data.phone}
                  name="phone"
                  placeholder="Enter here"
                />
              </div>
              <div className="w-[80%] mx-auto">
                <Button
                  onClick={ProfileChangeSubmit}
                  style={{ backgroundColor: "#686628" }}
                  className=" w-[100%] text-white mt-[12px] mb-3 cursor-pointer"
                >
                  Save
                </Button>
              </div>
            </TabPanel>

            <TabPanel>
              <form onSubmit={(e) => PasswordChangeSubmit(e)}>
                <div className="w-[100%] lg:w-[80%] m-auto ">
                  <div className="pt-2">
                    <label className="mt-3">Old Password</label>
                    <div className="flex">
                      <Input
                        onChange={hanldePasswordChange}
                        className="mt-2 lg:p-5 relative pl-10"
                        placeholder="Enter here"
                        value={passwordDetails.currentPassword}
                        name="currentPassword"
                        required
                        type={showOldPassword ? "text" : `password`}
                      />
                      <div className="z-40">
                        {showOldPassword ? (
                          <FaRegEye
                            onClick={() => {
                              setshowOldPassword(!showOldPassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        ) : (
                          <FaRegEyeSlash
                            onClick={() => {
                              setshowOldPassword(!showOldPassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <label className="mt-3">New Password</label>
                    <div className="flex">
                      <Input
                        onChange={hanldePasswordChange}
                        className="mt-2 lg:p-5 relative"
                        placeholder="Enter here"
                        value={passwordDetails.newPassword}
                        name="newPassword"
                        required
                        type={shownewPassword ? "text" : `password`}
                      />
                      <div className="z-40">
                        {shownewPassword ? (
                          <FaRegEye
                            onClick={() => {
                              setshownewPassword(!shownewPassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        ) : (
                          <FaRegEyeSlash
                            onClick={() => {
                              setshownewPassword(!shownewPassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <label className="mt-3">Retype Password</label>
                    <div className="flex">
                      <Input
                        onChange={hanldePasswordChange}
                        name="cPassword"
                        className="mt-2 lg:p-5 relative"
                        placeholder="Enter here"
                        value={passwordDetails.cPassword}
                        required
                        type={showretypePassword ? "text" : `password`}
                      />
                      <div className="z-40">
                        {showretypePassword ? (
                          <FaRegEye
                            onClick={() => {
                              setshowretypePassword(!showretypePassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        ) : (
                          <FaRegEyeSlash
                            onClick={() => {
                              setshowretypePassword(!showretypePassword);
                            }}
                            className="absolute cursor-pointer right-8 lg:right-[15%] mt-[20px]"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[80%] mx-auto">
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#686628" }}
                    className=" w-[100%] text-white mt-4 mb-3 cursor-pointer"
                  >
                    Update
                  </Button>
                </div>
              </form>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminComponent;
