import {
  Avatar,
  AvatarGroup,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AddpeopleinCommunity from "./AddpeopleinCommunity";

const CreateCommunity = ({ setdata, isOpen1, onClose1 }: any) => {
  const [newData, setnewData] = useState({
    communityName: "",
    CreatorName: "creator ",
    CommunityIcon: "/dashboardicons/videoicon.png",
    text: "",
    totalmembers: [],
  });
  // console.log(newData)

  const onchange = (e: any) => {
    const { name, value } = e.target;
    setnewData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setdata((prev: any) => [newData, ...prev]);
    onClose1();
  };
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Modal size={"xl"} isOpen={isOpen1} onClose={onClose1}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create New Community</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="addinstructor">
          <img
            src={"/dashboardicons/newcourseicon.png"}
            className="m-auto lg:h-[100px] cursor-pointer md:h-[100px] h-[70px]"
            alt=""
          />
          <div className="mt-3">
            <label className="text-sm font-semibold">Communtiy Title</label>
            <Input
              onChange={onchange}
              name="communityName"
              className="text-sm"
              placeholder="Enter here"
            />
          </div>
          <div className="mt-3">
            <label className="text-sm font-semibold">Description</label>
            <Textarea
              onChange={onchange}
              name="text"
              className="text-sm"
              placeholder="Enter here"
            />
          </div>

          <div className="mt-4">
            <label className="text-sm font-semibold">Add totalmembers</label>
            <div className="flex justify-between items-center">
              <AvatarGroup size="md" max={2}>
                <Avatar
                  name="Ryan Florence"
                  src="/dashboardicons/usericon.png"
                />
                <Avatar
                  name="Segun Adebayo"
                  src="/dashboardicons/usericon.png"
                />
                <Avatar name="Kent Dodds" src="/dashboardicons/usericon.png" />

                <Avatar
                  name="Christian Nwamba"
                  src="https://bit.ly/code-beast"
                />
              </AvatarGroup>
              <Button
                bgColor={"#3F1B5B"}
                color={"white"}
                _hover={{ bgColor: "#3F1B5m" }}
                className=" flex justify-center items-center text-[12px]"
                borderRadius={"20px"}
                onClick={onOpen}
              >
                Add
              </Button>
            </div>
            <AddpeopleinCommunity setnewData={setnewData} isOpen={isOpen} onClose={onClose} />
          </div>
          <div className="w-[80%] mx-auto mt-3">
            <Button
              onClick={handleSubmit}
              style={{ backgroundColor: "#686628" }}
              className="w-[100%] text-white mt-[12px] mb-3"
            >
              Next
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateCommunity;
