import {
  Button,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Notification from "./modal/Notification";
import { PushNotificationType, UserType } from "../@types/user";
import { deletePushNotification, searchPushNotification } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { image_Base_Url } from "../config";
import DeleteAlert from "./Alert/DeleteAlert";
import { ClipLoader } from "react-spinners";
import { CiSearch } from "react-icons/ci";

interface PushnotificationComponentProps {
  data: PushNotificationType[];
  searchkey: string;
  setsearchkey: React.Dispatch<React.SetStateAction<string>>;
  setdata: React.Dispatch<React.SetStateAction<PushNotificationType[]>>;
  loading: boolean;
  setCurrentpage: React.Dispatch<React.SetStateAction<number>>;
}

const PushnotificationComponent: React.FC<PushnotificationComponentProps> = ({
  data,
  searchkey,
  setsearchkey,
  setdata,
  loading,
  setCurrentpage,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    onOpen: onDeleteAlertOpen,
  } = useDisclosure();

  const selector = useSelector((state: RootState) => state.user);
  const [selectednotification, setSelectedNotification] =
    useState<PushNotificationType>();

  const bottomboundaryRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (firstEntry.isIntersecting) {
        setCurrentpage((prev) => prev + 1);
      }
    });
    const currentBottomBoundaryRef = bottomboundaryRef.current;
    if (currentBottomBoundaryRef) {
      observer.observe(currentBottomBoundaryRef);
    }
    return () => {
      if (currentBottomBoundaryRef) {
        observer.unobserve(currentBottomBoundaryRef);
      }
    };
  }, []);

  const usr = selector.user;
  const handleSearchSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (usr && searchkey.trim() !== "") {
        const usr2: UserType = usr;
        const notificationdata = (await searchPushNotification(
          usr2!.token,
          searchkey
        )) as PushNotificationType[];
        setdata(notificationdata);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (pushNotifId: string) => {
    try {
      if (usr && pushNotifId) {
        const res = await deletePushNotification(usr.token, pushNotifId);
        if (res === 3) {
          setdata((prev: PushNotificationType[]) =>
            prev.filter(
              (item: PushNotificationType) => item._id !== pushNotifId
            )
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedNotification(undefined);
    }
  };
  const confirmDelete = () => {
    if (selectednotification !== undefined) {
      handleDelete(selectednotification._id!);
      onDeleteAlertClose();
    }
  };

  return (
    <div className="w-[95%] m-auto my-3 max-h-[85vh] h-[80%] px-6 lg:px-10 md:px-10 rounded-2xl shadow-md bg-white">
      <div className="flex items-center justify-between">
        <p className="pt-[28px] text-left text-black">
          <p className="lg:text-[16px] md:text-[16px] text-[13px] font-bold">
            All Push Notification
          </p>
          <form onSubmit={handleSearchSubmit} className="relative">
            <Input
              value={searchkey}
              onChange={(e) => {
                setsearchkey(e.target.value);
              }}
              placeholder="Search here"
              minWidth={"170px"}
              width={"20vw"}
              maxWidth={"250px"}
              className="px-4"
            />
            <CiSearch
              className="absolute top-2.5  left-1 opacity-55"
              size={20}
            />
          </form>
        </p>
        <p color={"black"}>
          <Button
            onClick={onOpen}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center"
          >
            <img
              src={"/dashboardicons/addnew.png"}
              className="h-[16px] w-[16px] lg:mr-3 md:mr-3"
              alt=""
            />
            <p className="lg:text-[14px] lg:block md:block hidden m-0">
              Create New Notification
            </p>
          </Button>
        </p>
        <Notification
          setSelectedNotification={setSelectedNotification}
          selectednotification={selectednotification}
          setdata={setdata}
          isOpen1={isOpen}
          onClose1={onClose}
        />
      </div>
      <div
        className="overflow-y-auto hide-scrollbar"
        style={{ maxHeight: "calc(80vh - 160px)", scrollbarWidth: "none" }}
      >
        <TableContainer
          fontFamily={"Montserrat"}
          className="py-4 dashboard_usertable"
        >
          <Table
            size="sm"
            variant={"simple"}
            background={"white"}
            borderRadius={"25px"}
          >
            <Thead className="font-bold">
              <Tr>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Notification Title
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Catergory
                </Th>
                <Th
                  textTransform={"none"}
                  color={"black"}
                  fontSize={"15px"}
                  minWidth={"200px"}
                  paddingTop={"20px"}
                  paddingBottom={"17px"}
                >
                  Description
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Photo
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Push Date
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Notification Send to{" "}
                </Th>
                <Th textTransform={"none"} color={"black"} fontSize={"15px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody className="">
              {
                <>
                  {data.length > 0 &&
                    data.map((item: PushNotificationType, index: number) => (
                      <Tr key={index}>
                        <Td>
                          <p className=" max-w-[170px] mb-0 pl-3 pt-2 text-wrap">
                            {item.title}
                          </p>
                        </Td>
                        <Td>
                          <p className="max-w-[120px] mb-0 pl-3 pt-2 text-wrap">
                            {item.category}
                          </p>
                        </Td>
                        <Td>
                          <p className="max-w-[220px] mb-0 pl-3 pt-2 text-wrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description
                                  .replace(/<p\b[^>]*>/g, "<span>")
                                  .replace(/<\/?p\b[^>]*>/g, "</span>")
                                  .replace(/<\/?img\b[^>]*>/g, "")
                                  .slice(0, 19)
                                  .concat("..."),
                              }}
                            />{" "}
                          </p>
                        </Td>
                        <Td className="pl-3 pt-2">
                          {item.image ? (
                            <img
                              src={`${image_Base_Url}${item.image}`}
                              className="w-[52px] h-[48px] rounded-md"
                              alt=""
                            />
                          ) : (
                            "-"
                          )}
                        </Td>
                        <Td>
                          {item.createdAt &&
                            new Date(item.createdAt)
                              .toISOString()
                              .split("T")[0]}
                        </Td>
                        <Td>
                          {item.all_user
                            ? "All users"
                            : item?.users?.length !== 0
                            ? "Selected Users"
                            : "All Users"}
                        </Td>

                        <Td className="">
                          <div className="flex m-auto justify-center items-start gap-1 min-w-[100px]">
                            <Tooltip label="Edit" placement={"top"}>
                              <img
                                onClick={() => {
                                  setSelectedNotification(item);
                                  onOpen();
                                }}
                                src="/dashboardicons/editicon.png"
                                className="h-[28px] w-[28px] cursor-pointer "
                                alt=""
                              />
                            </Tooltip>
                            <Tooltip label="Delete" placement={"top"}>
                              <img
                                onClick={() => {
                                  setSelectedNotification(item);
                                  onDeleteAlertOpen();
                                }}
                                src="/dashboardicons/deleteicon.png"
                                alt=""
                                className="h-[28px] w-[28px]"
                              />
                            </Tooltip>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                </>
              }
            </Tbody>
          </Table>
          <div ref={bottomboundaryRef} className="w-100 h-[30px]">
            {/* if scrolled here then load and change set current page to +1  */}
          </div>
          {loading && (
            <div className="w-full flex justify-center">
              <ClipLoader />
            </div>
          )}
        </TableContainer>
        <DeleteAlert
          isOpen={isDeleteAlertOpen}
          onClose={onDeleteAlertClose}
          onConfirmDelete={confirmDelete}
          label="Delete Notification"
        />
      </div>
    </div>
  );
};

export default PushnotificationComponent;
