import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { messaging, requestForToken } from "../utils/firebase";
import { onMessage } from "firebase/messaging";
import toast, { Toaster } from "react-hot-toast";
import { FaMessage } from "react-icons/fa6";

const NotificationWrapper = () => {
  const selector = useSelector((state: RootState) => state.user);
  const [notification, setNotification] = useState<any>(null);
  const usr = selector.user;
  const notify = () =>
    toast(
      <>
        <div className="ml-3">
          <p className="text-sm mb-1 font-medium flex justify-center items-center text-gray-900">
            <FaMessage className="mr-3" />
            New Message
          </p>
          <p className="ml-6 mb-1 text-sm text-gray-500">{notification}</p>
        </div>
      </>
    );
  useEffect(() => {
    requestForToken(usr?.token!);
    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload.data);
      setNotification(payload.data?.message);
    });
  }, []);

  useEffect(() => {
    if (notification) {
      notify();
    }
  }, [notification]);

  return (
    <div>
      <Toaster />
    </div>
  );
};

export default NotificationWrapper;
