import { Button, Input, useDisclosure } from "@chakra-ui/react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CreateCourseSession } from "../../@types/user";
import ReactPlayer from "react-player";
import { addNewSessiontoCourse, deleteSession, editSessions } from "../../Api";
import { image_Base_Url } from "../../config";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AxiosProgressEvent } from "axios";
import { toast } from "react-hot-toast";
import DeleteAlert from "../Alert/DeleteAlert";

const ContentComponent = ({
  sessionCollections,
  setsessionCollections,
  index,
  selectedCourseId,
  settabindex,
  selectedSessionid,
  singleSession,
  setselectedSessionid,
}: // setselectedSessionindex,
{
  sessionCollections: CreateCourseSession[];
  setsessionCollections: React.Dispatch<
    React.SetStateAction<CreateCourseSession[]>
  >;
  index: number;
  selectedCourseId: string;
  settabindex: Dispatch<SetStateAction<number>>;
  singleSession: CreateCourseSession;
  selectedSessionid: string;
  setselectedSessionid: Dispatch<SetStateAction<string>>;
}) => {
  const modules = {
    toolbar: [
      [{ align: [] }],
      ["bold", "italic", "underline"],
      ["link", "image", "video"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  // const [uploading, setupoading] = useState(false);

  const [newSession, setNewSession] = useState<CreateCourseSession>(() => {
    if (singleSession._id) {
      return {
        cid: selectedCourseId,
        session_title: singleSession.session_title || "",
        session_description: singleSession.session_description || "",
      };
    } else {
      return {
        cid: selectedCourseId,
        session_title: "",
        session_description: "",
      };
    }
  });
  const [session_video, setSession_video] = useState<File | null>();
  const [inputKey, setInputKey] = useState<string>(Date.now().toString()); // Add unique key for the input reset

  useEffect(() => {
    if (singleSession._id) {
      setSession_video(singleSession.session_video || null);
    } else {
      setSession_video(null);
    }
  }, [singleSession]);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
    "align",
    "video",
  ];

  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSession((prevData: CreateCourseSession) => ({
      ...prevData,
      session_title: event.target.value,
    }));
  };

  const handleTextChange = (value: string) => {
    setNewSession((prevData: CreateCourseSession) => ({
      ...prevData,
      session_description: value,
    }));
  };
  const handlechange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files !== null && e?.target?.files[0];

    if (files) {
      setSession_video(files);
    }
  };
  const handleRemoveVideo = () => {
    setSession_video(null);
    setInputKey(Date.now().toString());
  };

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;

  const handleSubmit = async () => {
    if (newSession.cid === "") {
      return alert("Something went wrong, check course");
    }
    if (newSession.session_description === "") {
      return alert("description is required");
    }
    if (newSession.session_title === "") {
      return alert("session title is required");
    }
    if (session_video === null || session_video === undefined) {
      return alert("session video is required");
    }

    if (usr) {
      try {
        const toastId = toast("Upload started...");

        const res = (await addNewSessiontoCourse(
          usr.token,
          newSession,
          session_video,
          (progressEvent: AxiosProgressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total!
            );

            toast.loading(`${percentCompleted}% Uploading Completed`, {
              id: toastId,
            });
          }
        )) as CreateCourseSession;
        toast.remove(toastId);
        if (res) {
          setsessionCollections(
            (prev) => [...prev, res] as CreateCourseSession[]
          );
          setSession_video(res.session_video);
          const updatedCollections = [...sessionCollections];
          updatedCollections[index] = res;
          setsessionCollections(updatedCollections);
          toast.success("Session Created..");
          setTimeout(() => {
            setselectedSessionid(res._id! as string);
            settabindex(1);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpdateSession = async () => {
    if (newSession.cid === "") {
      return alert("Something went wrong, check course");
    }
    if (newSession.session_description === "") {
      return alert("description is required");
    }
    if (newSession.session_title === "") {
      return alert("session title is required");
    }
    if (session_video === null || session_video === undefined) {
      return alert("session video is required");
    }

    if (usr) {
      try {
        const res = (await toast.promise(
          editSessions(
            usr!.token,
            newSession,
            session_video,
            selectedSessionid,
            selectedCourseId,
            (progressEvent: AxiosProgressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total!
              );
            }
          ),
          {
            loading: `Upload in progress...$`,
            success: "Upload complete!",
            error: "Upload failed!",
          }
        )) as CreateCourseSession;

        if (res && res._id === selectedSessionid) {
          setsessionCollections((prev: CreateCourseSession[]) =>
            prev.map((item) => (item._id === selectedSessionid ? res : item))
          );
          setTimeout(() => {
            setselectedSessionid(res._id! as string);
            settabindex(1);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteSession = async () => {
    try {
      if (
        usr &&
        selectedCourseId?.length > 0 &&
        selectedSessionid?.length > 0
      ) {
        const res = await deleteSession(
          usr.token!,
          selectedCourseId,
          selectedSessionid
        );
        if (res === 3) {
          setsessionCollections((prev: CreateCourseSession[]) =>
            prev.filter((item) => item._id !== selectedSessionid)
          );
          setselectedSessionid("");
          toast.success("Deleted successfully", { position: "top-center" });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {
    handleDeleteSession();
    onClose();
  };

  const memoizedPlayer = useMemo(() => {
    return session_video ? (
      <div className="relative mt-3">
        {typeof session_video === "string" ? (
          <ReactPlayer
            url={`${image_Base_Url}${session_video}`}
            width={"200px"}
            height={"120px"}
            controls
          />
        ) : (
          <ReactPlayer
            url={URL.createObjectURL(session_video)}
            width={"200px"}
            height={"120px"}
            controls
          />
        )}
        <div
          className="absolute top-0 right-0 cursor-pointer bg-red-600 p-2 rounded-md"
          onClick={handleRemoveVideo}
        >
          X
        </div>
      </div>
    ) : null;
  }, [session_video]);

  return (
    <div>
      <div>
        <div className="lg:flex md:flex block justify-between items-center my-2">
          {memoizedPlayer}
          <Button
            borderRadius={"24px"}
            bgColor={"#3F1B5B"}
            color={"white"}
            _hover={{ bgColor: "#3F1B5m" }}
            className="flex justify-center items-center my-2 lg:m-0 md:m-0"
          >
            <label htmlFor={`fileInput-${index}`} className="flex">
              <img
                src={"/dashboardicons/plus.png"}
                className="h-[16px] w-[16px] mr-3"
                alt=""
              />
              <p className="lg:text-[14px] block m-0">
                {session_video === null ? "select video " : "change video"}
              </p>
            </label>
          </Button>
          <input
            key={inputKey} // This ensures the input is reset when key changes
            type="file"
            accept="video/*"
            name="session_video"
            id={`fileInput-${index}`}
            style={{ position: "absolute", opacity: 0, zIndex: -1 }} 
            onChange={handlechange} 
          />
        </div>
      </div>
      <p className="mb-1">Session Title</p>
      <Input
        size="sm"
        fontSize="small"
        borderRadius="8px"
        backgroundColor="#F4F4F4"
        placeholder="Enter Your"
        className="w-full mt-0 pt-0"
        value={newSession.session_title}
        onChange={handleTitleChange}
        required
      />

      <div style={{ height: "100%", scrollbarWidth: "none" }}>
        <p className="my-1">Description</p>
        <ReactQuill
          theme="snow"
          style={{ border: "none", marginTop: "10px", scrollbarWidth: "none" }}
          placeholder="Enter here"
          value={newSession.session_description}
          onChange={handleTextChange}
          modules={modules}
          formats={formats}
          className="w-full max-h-[250px]  overflow-scroll  mt-3 border-[white] rounded-[8px] bg-[#F4F4F4]"
        />
      </div>
      <div className="lg:flex md:flex block">
        <div className="w-[48%] mt-3 max-w-[30vw] m-auto">
          {singleSession._id ? (
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-full text-white mb-3"
              onClick={handleUpdateSession}
            >
              Update
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#686628" }}
              className="w-full text-white mb-3"
              onClick={handleSubmit}
            >
              Next
            </Button>
          )}
        </div>
        {selectedSessionid !== undefined && (
          <div className="w-[48%] lg:mt-3 md:mt-3 mt-0 max-w-[30vw] m-auto">
            <Button
              onClick={() => {
                onOpen();
              }}
              style={{ backgroundColor: "#DC143C" }}
              className="w-full bg-red-800  text-white my-3"
            >
              Delete
            </Button>
          </div>
        )}

        <DeleteAlert
          isOpen={isOpen}
          onClose={onClose}
          onConfirmDelete={confirmDelete}
          label="delete Session"
        />
      </div>
    </div>
  );
};

export default ContentComponent;
