import React, { useEffect, useState } from "react";
import DashboardWrapper from "../components/layouts/DashboardWrapper";
import Titlebar from "../components/titlebarComponent/Titlebar";
import AllfeedbackComponent from "../components/AllfeedbackComponent";
import { FeedbackType, UserType } from "../@types/user";
import { getListOfFeedbacks } from "../Api";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import HashLoader from "react-spinners/HashLoader";

const AllfeedBack = () => {
  const [loading, setloading] = useState(false);
  const [feedbackList, setfeedbackList] = useState<FeedbackType[]>();

  const selector = useSelector((state: RootState) => state.user);
  const usr = selector.user;
  useEffect(() => {
    try {
      if (usr) {
        setloading(true);
        const fetchData = async () => {
          const annoucementsList = await getListOfFeedbacks(usr.token, 1, 10);
          setfeedbackList(annoucementsList as FeedbackType[]);
        };
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  }, []);
  return (
    <DashboardWrapper>
      <Titlebar title={"Feedback"} />
      {loading ? (
        <div className="w-[100] h-[80vh] flex justify-center items-center">
          <HashLoader />
        </div>
      ) : (
        <AllfeedbackComponent
        setloading={setloading}
          feedbackList={feedbackList}
          setfeedbackList={setfeedbackList}
        />
      )}
    </DashboardWrapper>
  );
};

export default AllfeedBack;
